<template>
  <fieldset class="rg-select">
    <RgFormBase
      :label="label"
      :required="isRequired"
      :customDisabled="customDisabled"
      class="select-form"
    >
      <div slot="right-label">
        <RgValidationAlert
          v-if="haveError"
          :alert="error"
          class="rg-input--alert"
        />
      </div>

      <div class="input-base">
        <div class="input-container">
          <div v-show="!multiple" class="icon-container">
            <IconSearch class="icon-search" :class="{ focus: isFocus }" />
          </div>
          <VueSelect
            v-model.trim="mValue"
            :inputId="id"
            :style="{ 'min-width': minWidth }"
            :maxHeight="maxHeight"
            :data-id="dataId"
            :data-item="dataItem"
            :options="mutableOptions"
            :autoSelect="autoSelect"
            :loading="loading"
            :class="{ disable: disabled }"
            :disabled="disabled"
            :multiple="multiple"
            :placeholder="this.defaultText || this.placeholder"
            :on-search="onSearch"
            :clearable="clearable"
            :searchable="searchable"
            :close-on-select="closeOnSelect"
            :labelEmptyOptions="labelEmptyOptions"
            :label="labelSelect"
            :value="mValue"
            :no-drop="noDrop"
            :open-only-bottom="openOnlyBottom"
            :open-only-top="openOnlyTop"
            :title="hasPermission ? title : 'Usuário sem permissão'"
            class="rg-select-component"
            :hasErrors="haveError"
            @search:focus="focus"
            @search:blur="blurEffect"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { IconSearch } from "~tokio/primitive/icon";
import { VueSelect } from "~select";
import { isEqual, difference } from "lodash";
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
export default {
  name: "RgSelect",
  components: {
    VueSelect,
    RgFormBase,
    IconSearch,
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    dataItem: {
      type: String,
      default: "",
    },
    autoSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * An array of strings or objects to be used as dropdown choices.
     * If you are using an array of objects, vue-select will look for
     * a `label` key (ex. [{label: 'This is Foo', value: 'foo'}]). A
     * custom label key can be set with the `label` prop.
     * @type {Array}
     */
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Tell the smallest width of select dropdown-menu
     * @type {String}
     */
    minWidth: {
      type: String,
      default: "0",
    },
    /**
     * Tell the smallest width of select dropdown-menu
     * @type {String}
     */
    maxHeight: {
      type: String,
      default: "280px",
    },
    /**
     * Tell a label to RgFormBase
     * @type {String}
     */
    label: {
      type: String,
      default: "",
    },
    customDisabled: {
      type: Boolean,
      default: false,
    },

    labelEmptyOptions: {
      type: String,
    },
    /**
     * Disable the entire component.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true,
    },

    openOnlyBottom: {
      type: Boolean,
      default: false,
    },
    openOnlyTop: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true,
    },
    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Equivalent to the `placeholder` attribute on an `<input>`.
     * @type {String}
     */
    placeholder: {
      type: String,
      default: "Selecione",
    },
    /**
     * Equivalent to the `placeholder` attribute on an `<input>`.
     * @type {String}
     */
    defaultText: {
      type: String,
      default: "Selecione",
    },
    /**
     * Close a dropdown when an option is chosen. Set to false to keep the dropdown
     * open (useful when combined with multi-select, for example)
     * @type {Boolean}
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * Tells vue-select what key to use when generating option
     * labels when each `option` is an object.
     * @type {String}
     */
    labelSelect: {
      type: String,
      default: "label",
    },
    /**
     * Accept a callback function that will be
     * run when the search text changes.
     *
     * loading() accepts a boolean value, and can
     * be used to toggle a loading class from
     * the onSearch callback.
     *
     * @param {search}  String          Current search text
     * @param {loading} Function(bool)  Toggle loading class
     */
    onSearch: {
      type: Function,
      default: function (search, loading) {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    hasPermission: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    /**
     * Props padrões não passadas para o do vue-select
     * Obs.: Caso seja interessante, realocar em props.
     */
    return {
      mutableOptions: [],
      index: null,
      filterable: true,
      pushTags: false,
      noDrop: false,
      taggable: false,
      transition: "fade",
      error: [],
      mValue: null,
      isFocus: false,
    };
  },
  computed: {
    validValue() {
      return this.mValue;
    },
    hasOptions() {
      return this.getData().length > 0;
    },
    haveError() {
      return this.error && this.error.length > 0;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    options(pValues) {
      this.mutableOptions = pValues;
    },
    mutableOptions: function (val) {
      if (this.mutableOptions.length > 0 && this.value) {
        this.setMvalue(this.value);
      }
    },
    mValue: function (val) {
      this.error = [];
      if (!val && this.mutableOptions.length > 0) {
        this.$emit("input", null);
        this.$emit("change", null);
        return;
      }

      if (Array.isArray(val)) {
        // caso mValue seja multiple remove os campos que ja estao selecionados da busca
        const res = this.extractValues(val);
        const a = this.extractValues(this.getData());
        this.mutableOptions = this.convert(difference(a, res));
        this.$emit("input", res);
        this.$emit("change", this.mValue);
      }

      if (val && val.value) {
        this.$emit("input", val.value);
        this.$emit("change", val);
      }
    },
    value: function (val) {
      this.setMvalue(val);
    },
  },
  mounted() {
    this.updateOptions();
    // define o data mValue deacordo com o multiple, caso positivo ele é um [] caso negativo null
    this.mValue = this.multiple ? [] : null;
  },
  methods: {
    focus() {
      this.isFocus = true;
    },

    blurEffect() {
      this.isFocus = false;
      this.$nextTick(() => {
        this.validate();
      });
    },

    updateOptions() {
      this.mutableOptions = this.getData();
    },
    getData() {
      return this.options;
    },
    extractValues(arr) {
      return Array.from(new Set(arr.map((i) => i.value)));
    },
    convert(arr) {
      return this.getData().filter((i) => {
        if (arr.includes(i.value)) {
          return true;
        }
        return false;
      });
    },
    setMvalue(val) {
      // caso o valor que esteja sendo enviado seja um array (multiple ativo)
      if (Array.isArray(val) && this.multiple) {
        // obtem somente os values para comparação
        const extractmV = this.mValue.map((i) => i.value);
        // verifica se o que está em mValue é diferente do que veio , nesse caso atualiza mVAlue
        if (!isEqual(extractmV, val)) {
          this.mValue = this.convert(val);
        }
      } else {
        // caso seja um valor invalido ( usuario limpou o campo seta null)
        if (!val) {
          this.mValue = null;
        } else {
          // caso seja um valor valido e nao seja multiple
          this.mValue = this.getData().find((i) => i.value === val);
        }
      }
    },
  },
};
</script>
