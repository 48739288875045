<template>
  <div class="hospitalization-bed-search">
    <RgSearch
      ref="RgSearch"
      v-model="mutableBedList"
      :disabled="this.disabledByModal"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="70"
      :max-register="20"
      result-title="Lista de Leitos"
      @afterPerformSearch="afterSearchFilter"
    >
      <!-- START Filters -->
      <div slot="filters">
        <div class="search-filter">
          <div class="grid">
            <div class="selectinput">
              <RgSelectUnithealth
                id="unit-health"
                ref="unitHealth"
                v-model="form.selectUnity"
                permission="internacao.mapaLeitos"
                default-text="Todas"
                empty-default-text="Nenhuma unidade disponível"
                class="inputitem"
                disabled
                searchUnitById
                :unitHealthId="form.selectUnity"
              />
            </div>

            <div class="selectinput">
              <RgSelectSector
                id="sector"
                ref="sector"
                v-model="form.selectedSector"
                :disabled="disableSector"
                :class="{ 'disable unselect': disableSector }"
                :permission="SECTOR_PERMISSION"
                :unit-health="form.selectUnity"
                default-text="Todos"
                empty-default-text="Nenhum setor disponível"
                class="inputitem"
              />
            </div>

            <div class="selectinput">
              <RgSelectPlaces
                id="place"
                v-model="form.selectedLocale"
                :disabled="disableLocale"
                :class="{ 'disable unselect': disableLocale }"
                :sector="form.selectedSector"
                :default-text="'Todos'"
                :empty-default-text="'Nenhum local disponível'"
                class="inputitem"
              />
            </div>

            <div class="selectinput">
              <RgSuggestHospitalizationBed
                id="bed"
                ref="bed"
                v-model="form.bed"
                :disabled="disableBed"
                :class="{ 'disable unselect': disableBed }"
                :local-id="localId"
                :placeholder="validateBedOptions"
                class="inputitem"
                @selected="selectingHospitalizationBed"
              />
            </div>

            <div class="selectinput">
              <RgSuggestTypeBed
                id="type-bed"
                ref="typeBed"
                v-model="form.selectedTypeBed"
                class="inputitem type-bed"
                @selected="selectingBedInfo"
              />
            </div>

            <div class="selectinput customradio">
              <RgRadioCustom
                id="situation-bed"
                ref="situation"
                v-bind="propsRadioCustomSituation"
                multSelect
                reorganize
                class="inputitem"
                label="Situação do Leito"
                @input="onInputRadioCustomSituation"
              />
            </div>

            <div class="selectinput customradio">
              <RgRadioCustom
                id="bed"
                ref="customBed"
                v-bind="propsRadioCustomBed"
                multSelect
                class="inputitem"
                label="Listar por Leito"
                @input="onInputRadioCustomBed"
              />
            </div>

            <div class="selectinput customradio">
              <RgRadioCustom
                id="status"
                ref="status"
                v-bind="propsRadioCustomStatus"
                multSelect
                class="inputitem"
                label="Listar por Status"
                @input="onInputRadioCustomStatus"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- END Filters -->

      <!-- START Results -->
      <div
        ref="scrollArea"
        class="hospitalization-bed-result"
        :style="actDynamicHeight"
      >
        <div
          v-for="(item, index) in mutableBedList"
          :key="index"
          class="bed-list"
        >
          <div class="item bed">
            <RgBedStatus
              :class="{ inactive: item.inl_habilitado === 0 }"
              :generate-situation-status="hasBedStatus(item)"
              :is-active="item.inl_habilitado"
            />

            <RgDropdownMenuHospitalization
              v-if="item.inl_habilitado === 1"
              id="menu-options"
              :show-up="toShowUp(index)"
              :hospitalization="item"
              :generate-situation-status="hasBedStatus(item)"
              @bedFlow="managerFlow"
            />

            <div class="bed-name">
              <strong :title="item.inl_nome" class="ellipsis">
                {{ item.inl_nome }}
              </strong>
            </div>
          </div>

          <div class="item">
            <div class="ellipsis">
              Unidade de Saúde:
              <strong :title="item.uns_nome">
                {{ item.uns_nome ? item.uns_nome : "NÃO INFORMADO" }}
              </strong>
            </div>

            <div class="ellipsis">
              Setor:
              <strong :title="item.set_nome">
                {{ item.set_nome ? item.set_nome : "NÃO INFORMADO" }}
              </strong>
            </div>

            <div class="ellipsis">
              Local de Atendimento:
              <strong :title="item.lca_nome" class="subtitletext">
                {{ item.lca_nome ? item.lca_nome : "NÃO INFORMADO" }}
              </strong>
            </div>

            <div v-if="oculterData(item)" class="ellipsis">
              Ocupação:
              <strong :title="item.clinica_atual">
                {{ item.clinica_atual ? item.clinica_atual : "NÃO INFORMADO" }}
              </strong>
            </div>
          </div>

          <div class="item">
            <div v-if="oculterData(item)" class="ellipsis">
              Número da Internação:
              <strong :title="item.int_numero">
                {{ item.int_numero ? item.int_numero : "NÃO INFORMADO" }}
              </strong>
            </div>

            <div v-if="oculterData(item)" class="ellipsis">
              Paciente:
              <strong :title="formaterNamePatient(item)">
                {{ formaterNamePatient(item) }}
              </strong>
            </div>

            <div v-if="oculterData(item)" class="ellipsis">
              Responsável:
              <strong
                key=""
                :title="
                  item.int_responsavel ? item.int_responsavel : 'NÃO INFORMADO'
                "
              >
                {{
                  item.int_responsavel ? item.int_responsavel : "NÃO INFORMADO"
                }}
              </strong>
            </div>

            <div v-if="item.isl_id !== 2 && oculterData(item)" class="ellipsis">
              Tempo de Internação:
              <strong :title="item.tempo_internado">
                {{ item.tempo_internado }}
              </strong>
            </div>
          </div>

          <div class="item status">
            <RgHospitalizationStatus
              :generate-status="setStatusBed(item)"
              :active-status="setActiveStatus(item)"
            />
          </div>

          <div class="item actions">
            <div class="history">
              <RgHistoryButton
                id="history-btn"
                class="button"
                title="Histórico do Leito"
                @click="showModalBedHistory(item)"
              />
            </div>

            <div class="drodpown">
              <RgDropdown
                id="item-options"
                ref="dropDownHospitalizationOptions"
                :disabled="disableDropdown(item)"
                :class="{ disable: disableDropdown(item) }"
                :item="item"
                :item-id="item.int_id"
                :show-up="toShowUp(index)"
                :action-options="itemActionOptions(item)"
                small
                rotateIcon
              >
                <IconEllipsis slot="icon" />
              </RgDropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- END Results -->
    </RgSearch>

    <!-- MODAIS / COMPONENTES -->
    <ManagerMapBed
      id="manager-map-bed"
      ref="managerMapBed"
      @disabledByModalComponents="disabledByModalComponents"
      @reSearch="reSearch"
    />

    <ModalChangeBedType
      id="modal-change-bed-type"
      ref="modalChange"
      :operation="changeFlowOperation"
      :status="flowId"
      :hospitalization="itemHospitalization"
      :hospitalization-extra="itemExtraHospitalization"
      @close="closeOperation"
      @reSearch="reSearch"
    />
  </div>
</template>

<script>
import RgDropdown from "~tokio/primitive/dropdown/rg-dropdown/RgDropdown";
import moment from "moment";
import BED_STATUS from "../../../common/component/radio/rg-radio-bed-type/getBedType";
import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import RgBedStatus from "$hospitalization/common/component/status/rg-bed-status/RgBedStatus";
import {
  ManagerMapBed,
  RgDropdownMenuHospitalization,
  RgHospitalizationStatus,
  ModalChangeBedType,
} from "../../component";
import {
  RgSuggestTypeBed,
  RgSuggestHospitalizationBed,
} from "$hospitalization/common/component";
import {
  RgHistoryButton,
  RgSelectSector,
  RgSelectUnithealth,
  RgSelectPlaces,
  IconEllipsis,
  RgRadioCustom,
} from "~tokio/primitive";
import { ToShowUp } from "$hospitalization/common/component/util/ToShowUp";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { mapGetters } from "vuex";

export default {
  name: "HospitalizationMapBed",
  components: {
    RgSearch,
    RgSelectUnithealth,
    RgSelectSector,
    RgSelectPlaces,
    RgHospitalizationStatus,
    ModalChangeBedType,
    RgBedStatus,
    RgDropdownMenuHospitalization,
    RgHistoryButton,
    RgSuggestTypeBed,
    RgDropdown,
    ManagerMapBed,
    RgSuggestHospitalizationBed,
    IconEllipsis,
    RgRadioCustom,
  },
  data() {
    return {
      form: {
        selectUnity: null,
        selectedSector: null,
        selectedLocale: null,
        selectedTypeBed: null,
        itl_id: null,
        selectedSituation: [],
        bed: null,
        inl_id: null,
        bedStatus: [],
        statusActiveorInactive: [],
      },
      mutableBedList: [],
      changeFlowOperation: 0,
      flowId: null,
      itemHospitalization: null,
      itemExtraHospitalization: null,
      disabledByModal: false,
      tableHeight: [],
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
      clientId: "Login/GET_USER_ID_CLIENT",
    }),
    propsRadioCustomSituation() {
      const list = [
        { title: "Vazio", id: 1, color: "#66d48f" },
        { title: "Reserva", id: 2, color: "#5295e2" },
        { title: "Ocupado", id: 3, color: "#f96b70" },
        { title: "Desinfecção", id: 4, color: "#edc76a" },
        {
          title: "Manutenção",
          id: 5,
          gridStart: 1,
          gridEnd: 3,
          color: "#ababab",
        },
      ];

      const value = this.form.selectedSituation;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomStatus() {
      const list = [
        { title: "Ativo", id: 1, color: "#66d48f" },
        { title: "Inativo", id: 0, color: "#f96b70" },
      ];

      const value = this.form.statusActiveorInactive;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomBed() {
      const list = [
        { title: "Extra", id: 1, color: "#56b0c9" },
        { title: "Observação", id: 2, color: "#ffa56a" },
      ];

      const value = this.form.bedStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    actDynamicHeight() {
      return this.dynamicHeight ? `height: ${this.dynamicHeight}px` : "";
    },
    getSpaceTable() {
      return this.tableHeight;
    },

    disableSector() {
      return !this.form.selectUnity;
    },
    disableLocale() {
      return !this.form.selectedSector;
    },
    disableBed() {
      return !this.form.selectedLocale;
    },
    validateBedOptions() {
      return this.form.selectedLocale ? "Digite o leito" : "Todos";
    },
    localId() {
      const isValidLocalId = this.form.selectedLocale > 0;
      return isValidLocalId ? this.form.selectedLocale : 0;
    },
  },
  watch: {
    "form.selectUnity"(pValue, pPrev) {
      this.form.bed = null;

      if (!pValue) {
        this.form.selectedSector = null;
        this.form.selectedLocale = null;
      }
    },

    "form.selectedSector"(pValue, pPrev) {
      this.form.bed = null;
      if (!pValue) {
        this.form.selectedLocale = null;
      }

      if (this.form.selectUnity === false) {
        this.form.selectedSector = false;
      }
    },

    "form.selectedLocale"(pValue, pPrev) {
      this.form.bed = null;
      if (!pValue) {
        this.form.inl_id = null;
      }
    },
  },
  created() {
    this.SECTOR_PERMISSION = 351;
    this.BED_VAZIO = 1;
    this.BED_RESERVA = 2;
    this.BED_OCUPADO = 3;
    this.BED_DESINFECCAO = 4;
    this.BED_MANUTENCAO = 5;
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  destroyed() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/bed/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_FILTER_HOSPITALIZATION_BED",
      );
    }
  },
  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.fillFilter();
  },
  methods: {
    onInputRadioCustomSituation(item) {
      if (item) {
        this.form.selectedSituation = item;
      }
    },
    onInputRadioCustomStatus(item) {
      if (item) {
        this.form.statusActiveorInactive = item;
      }
    },
    onInputRadioCustomBed(item) {
      if (item) {
        this.form.bedStatus = item;
      }
    },
    afterSearchFilter() {
      this.getDynamicHeight();

      if (this.$refs.dropDownHospitalizationOptions?.length > 0) {
        this.$refs.dropDownHospitalizationOptions.forEach((element) => {
          element.clickOutside();
        });
      }
    },

    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.RgSearch?.$refs.listContent)
          this.tableHeight = this.$refs.RgSearch.$refs.listContent.offsetHeight;
      });
    },

    disabledByModalComponents(disabledByModal) {
      this.disabledByModal = disabledByModal;
      return this.disabledByModal;
    },

    async searchFilter(pData) {
      try {
        return this.$store.dispatch(
          "Hospitalization/Bed/SEARCH_CUSTOM_HOSPITALIZATION_BED",
          { ...pData },
        );
      } catch (Err) {
        this.$toaster.error(Err);
      }
    },

    reSearch() {
      this.$refs.RgSearch.submitForm(false, true);
    },

    generateFilter() {
      const variables = {
        uns_id: this.form.selectUnity ? this.form.selectUnity : null,
        set_id: this.form.selectedSector ? [this.form.selectedSector] : null,
        lca_id: this.form.selectedLocale ? this.form.selectedLocale : null,
        inl_habilitado: this.form.statusActiveorInactive,
        inl_extra:
          this.form.bedStatus && this.form.bedStatus.includes(BED_STATUS.EXTRAS)
            ? 1
            : 0,
        inl_observacao:
          this.form.bedStatus &&
          this.form.bedStatus.includes(BED_STATUS.OBSERVACAO)
            ? 1
            : 0,
        itl_id: this.form.itl_id ? this.form.itl_id : null,
        inl_id_internacoes_situacoes_leito:
          this.form.selectedSituation && this.form.selectedSituation.length > 0
            ? this.form.selectedSituation
            : null,
        inl_id: this.form.inl_id ? this.form.inl_id : null,
        inl_nome: this.form.bed ? this.form.bed : null,
        usu_id: this.userId,
        client_id: this.clientId,
      };

      this.$store.commit(
        "Hospitalization/Hospitalizations/SET_FILTER_HOSPITALIZATION_BED",
        this.form,
      );

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Hospitalization/Hospitalizations/GET_FILTER_HOSPITALIZATION_BED"
        ];

        await this.$refs.unitHealth.doSearch();

        if (existFilterData) {
          this.$loader.start("Carregando dados da busca");

          this.form.selectedSector = existFilterData.selectedSector;
          await this.$refs.sector.doSearch();

          this.form.selectedLocale = existFilterData.selectedLocale;

          this.form.bed = existFilterData.bed;
          await this.$refs.bed.forceSelection(existFilterData.bed);

          this.form.inl_id = existFilterData.inl_id;
          this.form.selectedTypeBed = existFilterData.selectedTypeBed;
          this.$refs.typeBed.forceSelection({
            itl_nome: existFilterData.selectedTypeBed,
            itl_id: existFilterData.itl_id,
          });
          this.form.selectedSituation = existFilterData.selectedSituation;
          this.form.bedStatus = existFilterData.bedStatus;
          this.form.statusActiveorInactive =
            existFilterData.statusActiveorInactive;

          await this.$refs.RgSearch.performSearch();
        } else {
          this.form.statusActiveorInactive = [1];
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da busca", Err);
        this.$loader.finish();
      }
    },

    setStatusBed(pValue) {
      const status = [];

      if (pValue.inl_extra) {
        status.push(1);
      }
      if (pValue.inl_observacao) {
        status.push(2);
      }

      return status;
    },

    setActiveStatus(pValue) {
      return pValue && pValue.inl_habilitado;
    },

    async managerFlow(pFlow) {
      if (pFlow) {
        const isBusy =
          pFlow.operation && pFlow.operation.value === this.BED_OCUPADO;
        const isReservation =
          pFlow.operation && pFlow.operation.value === this.BED_RESERVA;
        const hasHospitalization =
          pFlow.hospitalization && pFlow.hospitalization.int_id;

        let hospitalization = null;

        if (isBusy || isReservation) {
          if (hasHospitalization) {
            const result = await this.$store.dispatch(
              "Hospitalization/Hospitalizations/SEARCH_HOSPITALIZATION",
              {
                int_id: pFlow.hospitalization.int_id,
              },
            );
            result.rows[0].int_data = moment().format("DD/MM/YYYY");
            result.rows[0].int_hora = moment().format("HH:mm");
            hospitalization = result.rows[0];
          } else {
            hospitalization = pFlow.hospitalization;
            hospitalization.inl_id = pFlow.hospitalization.id;
          }
          this.$router.push({
            name: "hospitalization.bed.search.new-hospitalization",
            params: {
              form: hospitalization,
              fromMapBed: true,
              operation: pFlow.operation.value,
            },
          });
          return;
        }
        this.flowId = pFlow.status;
        this.changeFlowOperation = pFlow.operation.value;
        this.itemHospitalization = pFlow.hospitalization;
        if (this.itemHospitalization.int_id && this.itemHospitalization.id) {
          this.itemExtraHospitalization = await this.$store.dispatch(
            "Hospitalization/Bed/SEARCH_CUSTOM_HOSPITALIZATION_DETAILS",
            {
              inl_id: this.itemHospitalization.id,
              int_id: this.itemHospitalization.int_id,
            },
          );
        }
      }
    },

    oculterData(pValue) {
      return pValue.isl_id === 2 || pValue.isl_id === 3;
    },

    formaterNamePatient(pValue) {
      if (!pValue.nome_paciente) {
        return "NÃO INFORMADO";
      } else {
        return pValue.nome_paciente === "INDIGENTE"
          ? "PACIENTE NÃO IDENTIFICADO"
          : pValue.nome_paciente;
      }
    },

    hasBedStatus(pItem) {
      return pItem && pItem.inl_id_internacoes_situacoes_leito
        ? pItem.inl_id_internacoes_situacoes_leito
        : null;
    },

    disableDropdown(pItem) {
      const verify =
        pItem.isl_id === this.BED_VAZIO ||
        pItem.isl_id === this.BED_DESINFECCAO ||
        pItem.isl_id === this.BED_MANUTENCAO;
      return verify;
    },

    selectingBedInfo(pValue) {
      const source = pValue && pValue.source;
      this.form.itl_id = source && source.itl_id ? source.itl_id : null;
    },

    selectingHospitalizationBed(pBed) {
      const source = pBed && pBed.source;
      if (source && source.inl_id) {
        this.form.inl_id = source && source.inl_id ? source.inl_id : null;
      } else {
        this.$refs.bed.doSearch();
        this.form.bed = null;
        this.form.inl_id = null;
      }
    },

    showModalBedHistory(pItem) {
      if (pItem && pItem.id) {
        this.$refs.managerMapBed.openModalBedHistory(pItem);
      }
    },

    itemActionOptions(pItem) {
      const openDetails = !(
        pItem.isl_id === this.BED_OCUPADO || pItem.isl_id === this.BED_RESERVA
      );

      const hideDetails = !(pItem.isl_id === this.BED_RESERVA);

      return [
        {
          label: "Detalhes",
          action: this.$refs.managerMapBed.openModalBedDetails,
          disable: openDetails,
        },
        {
          label: "Transferência e Permuta",
          action: this.$refs.managerMapBed.openModalPatientTransferExchange,
          disable: openDetails,
          visible: hideDetails,
        },
      ];
    },

    toShowUp(pItemIndex) {
      return ToShowUp(pItemIndex, this.mutableBedList);
    },

    closeOperation() {
      this.changeFlowOperation = 0;
      this.flowId = 0;
      this.itemHospitalization = null;
      this.itemExtraHospitalization = null;
      this.$refs.modalChange.cleanStatus();
    },

    cleanForm() {
      this.form = {
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        selectedLocale: null,
        selectedTypeBed: null,
        itl_id: null,
        selectedSituation: [],
        bed: null,
        inl_id: null,
        bedStatus: [],
        statusActiveorInactive: [],
      };
      this.mutableBedList = [];
      this.changeFlowOperation = 0;
      this.flowId = null;
      this.itemHospitalization = null;
      this.itemExtraHospitalization = null;

      if (this.$refs.situation) {
        this.$refs.situation.clearRadio();
      }
      if (this.$refs.customBed) {
        this.$refs.customBed.clearRadio();
      }
      if (this.$refs.status) {
        this.$refs.status.clearRadio();
      }
    },
  },
};
</script>
