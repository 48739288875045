import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchEmployeeByName(
    $pesName: String
    $ocp_id: Int
    $sector: Int
    $uns_id: Int
    $filterByActiveOrInactive: Boolean
    $filterWithoutBond: Boolean
    $compatibleWithCodProcedure: String
    $vusResponsavel: Boolean
    $groupByEmployee: Boolean
    $returnAllBonds: Boolean
    $limit: Int
  ) {
    SearchEmployeeByName(
      pes_nome: $pesName
      ocp_id: $ocp_id
      sector: $sector
      uns_id: $uns_id
      filterByActiveOrInactive: $filterByActiveOrInactive
      filterWithoutBond: $filterWithoutBond
      compatibleWithCodProcedure: $compatibleWithCodProcedure
      vus_responsavel: $vusResponsavel
      groupByEmployee: $groupByEmployee
      returnAllBonds: $returnAllBonds
      limit: $limit
    ) {
      count
      rows {
        fun_id
        vin_id
        pes_nome
        pes_id
        ocp_id
        ocp_codigo
        ocp_nome
        vus_id
        fun_conselho_classe
        person {
          address {
            neighborhood {
              city {
                state {
                  est_sigla
                }
              }
            }
          }
          cpf {
            cpf_numero
          }
        }
        employeeRelationship {
          vin_id_funcionarios
          vin_id_tipos_vinculo
          employeeRelationshipType {
            tpv_nome
          }
          employee {
            fun_id
          }
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    }).catch((err) => {
      console.error(err);
    });
    return data.SearchEmployeeByName;
  } catch (err) {
    return err.graphQLErrors;
  }
};
