<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchProductPharmacy from "./action/SearchProductPharmacy";
import SearchProductClient from "./action/SearchProductClient";
import SearchProductWithMovement from "./action/SearchProductWithMovement";

export default {
  name: "RgSelectProduct",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Produto/Medicamento",
    },
    pharmacyId: {
      type: Number,
      default: 0,
    },
    typeMovimentation: {
      type: Number,
      default: null,
    },
    perClient: {
      type: Boolean,
      default: false,
    },
    // Nome do medicamento + tipo de apresentação
    withPresentation: {
      type: Boolean,
      default: true,
    },
    // Somente produtos não bloqueados
    blnMpdBloqueado: {
      type: Boolean,
      default: false,
    },
    // Produtos com movimentação
    withMovement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      product: [],
    };
  },
  watch: {
    pharmacyId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    product: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.product;
    },
    async doSearch() {
      let data = [];
      this.product = [];

      if (this.perClient) {
        data = await SearchProductClient({
          blnMpdBloqueado: this.blnMpdBloqueado,
        });
      } else if (this.withMovement) {
        data = await SearchProductWithMovement({
          intTipoMovimentacao: this.typeMovimentation,
          intIdFarmacia: this.pharmacyId,
          blnDisponivel: true,
        });
      } else {
        data = await SearchProductPharmacy({
          intIdFarmacia: this.pharmacyId,
          intTipoMovimentacao: this.typeMovimentation,
        });
      }

      this.product = data.map((item) => {
        return {
          value: item.data,
          label: this.withPresentation
            ? item.mpd_codigo + " - " + item.label + " - " + item.mtu_novo_nome
            : item.mpd_codigo + " - " + item.label,
          mpd_novo_principio_ativo: item.mpd_novo_principio_ativo,
          mpd_codigo: item.mpd_codigo,
          mtu_id: item.mtu_id,
          mtu_novo_nome: item.mtu_novo_nome,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
