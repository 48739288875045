<template>
  <div class="quick-hospitalization-details row">
    <form class="modules" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase
          :title="isPatientIndigent ? 'Paciente Não Identificado' : 'Paciente'"
          class="form-base"
        >
          <input
            id="patient-indigent"
            v-model="isPatientIndigent"
            :class="{ disable: blockChangeTypePatient }"
            :disabled="
              blockChangeTypePatient ||
              disabledByModal ||
              disabledByModalComponent
            "
            class="checkboxTypePatient"
            type="checkbox"
            value="1"
            @click="changePatientType"
          />
          <label
            :class="{ disable: blockChangeTypePatient }"
            :disabled="
              blockChangeTypePatient ||
              disabledByModal ||
              disabledByModalComponent
            "
            for="label"
          >
            Paciente não identificado
          </label>
          <br />

          <div v-if="!isPatientIndigent" class="module-patient">
            <div class="content">
              <RgSuggestPatientInformation
                id="patient"
                ref="patient"
                v-model="suggestPatient"
                :tabindex="notPatient"
                :disabled="disabledPatient || disabledByModal"
                :rules="{
                  required: true,
                  forceSelection: true,
                  fn: validateHospitalizationExists,
                }"
                :extra-data="['withoutAddress']"
                :withPatientHospitalization="true"
                :withPatientModule="['hospitalization']"
                class="inputpatient"
                @disabledByModalComponents="disabledByModalComponents"
                @patientInfo="selectingPatient"
                @blockedPatient="blockedPatient($event)"
              />
              <Tooltip
                v-if="fromRegulation"
                class="tooltip"
                startOpen
                :message="messageTooltip"
              >
                <IconExclamationHelper slot="icon" />
              </Tooltip>

              <div v-if="fromRegulation" class="documents">
                <RgInputCns
                  id="input-cns"
                  v-model="document.cns"
                  :required-value="requiredCns"
                />
                <RgInputCpf
                  id="input-cpf"
                  v-model="document.cpf"
                  :rules="{ required: requiredCpf }"
                />
              </div>
            </div>
          </div>
          <div v-else class="module-indigent">
            <div class="content">
              <RgRadioCustom
                id="gender"
                ref="gender"
                v-bind="propsRadioCustom"
                :canUnselect="false"
                label="Sexo"
                class="radiogender"
                :rules="{ required: isPatientIndigent }"
                :disabled="disabledByModal || disabledByModalComponent"
                @input="onInputRadioCustom"
              />

              <RgInputMasked
                id="age"
                ref="age"
                v-model="indigentAge"
                mask="###"
                :rules="{ required: isPatientIndigent }"
                label="Idade Aproximada"
                placeholder="Digite os anos"
                class="indigentinput"
                :disabled="disabledByModal || disabledByModalComponent"
              />

              <RgInput
                id="description"
                ref="description"
                v-model="form.indigent.ini_descricao"
                :rules="{ required: isPatientIndigent }"
                :maxlength="45"
                label="Descrição"
                placeholder="Digite a descrição"
                type="text"
                class="indigentdesc"
                :disabled="disabledByModal || disabledByModalComponent"
              />
            </div>
          </div>
        </FormBase>

        <div
          v-show="!isPatientIndigent"
          :disabled="
            disableOrEnableCompanionModule ||
            disabledByModal ||
            disabledByModalComponent
          "
          class="module-companion"
        >
          <FormBase class="form-base" title="Acompanhante">
            <div class="content">
              <div class="halfmodule">
                <RgComboboxCompanionType
                  id="companion-type"
                  v-model="
                    form.hospitalization.int_id_internacoes_tipos_responsavel
                  "
                  :class="{
                    'disable unselect': disableOrEnableCompanionModule,
                  }"
                  :disabled="
                    disableOrEnableCompanionModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  label="Tipo de Acompanhante"
                  class="typecompanion"
                />

                <RgInput
                  id="companion-name"
                  ref="companionName"
                  v-model="form.companion.via_nome"
                  :rules="{
                    required: !existTypeCompanion,
                    validateEspecialCharacter: 'name',
                    cannotContainNumbers: true,
                  }"
                  :class="{
                    'disable unselect': auxInfo.patient.disabledCompanionName,
                  }"
                  :disabled="
                    auxInfo.patient.disabledCompanionName ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :maxlength="100"
                  label="Nome do Acompanhante"
                  placeholder="Digite o nome do acompanhante"
                  class="companioninput"
                />
              </div>

              <div class="module-document">
                <div class="line">
                  <h3 class="titleinfo unselect">Documentos</h3>
                </div>

                <div class="documentation">
                  <div class="half-width">
                    <RgComboboxDocumentType
                      id="document-type"
                      v-model="documentType"
                      :class="{
                        'disable unselect':
                          existTypeCompanion || disableOrEnableCompanionModule,
                      }"
                      :disabled="
                        existTypeCompanion ||
                        disableOrEnableCompanionModule ||
                        disabledByModal ||
                        disabledByModalComponent
                      "
                      label="Tipo de Documento"
                      class="-margin-right-large documentlist"
                      @change="setDocumentType"
                    />

                    <div class="group">
                      <RgDocumentInput
                        id="document-number"
                        ref="documentInput"
                        v-model="dva_numero_documentos"
                        :value-edit="dva_numero_documentos"
                        :class="{ 'disable unselect': disableOrEnableDocument }"
                        :disabled="
                          disableOrEnableDocument ||
                          disabledByModal ||
                          disabledByModalComponent
                        "
                        :document-type-id="documentType"
                        class="-margin-right-large document-input"
                        @enable="disableOrEnableDocumentAdd"
                      />

                      <RgAddButton
                        id="new-document"
                        :disabled="
                          !validateAddBtn ||
                          disabledByModal ||
                          disabledByModalComponent
                        "
                        data-id="novo"
                        class="btnbody add"
                        type="button"
                        @click="documentListAddItem"
                      />
                    </div>
                  </div>
                  <div
                    class="areatable"
                    :class="{ widthArea: form.companion.documents.length > 0 }"
                  >
                    <SmartTable
                      v-if="form.companion.documents.length > 0"
                      ref="smartTable"
                      name="QuickHospitalizationDetails"
                      :columns="COLUMNS"
                      :body="form.companion.documents"
                      :total="form.companion.documents.length"
                      :show-pagination="false"
                      :initial-columns="2"
                      toggleSelected
                      index-column="dva_id_documentos"
                      remove-btn-columns
                      :disabled="disabledByModal || disabledByModalComponent"
                      @getLine="selectLine"
                    >
                      <div slot="top-buttons" class="top-buttons">
                        <RgEditButton
                          id="edit-document"
                          class="mg-right"
                          :disabled="
                            disabledByModal ||
                            disabledByModalComponent ||
                            disabledEditButton
                          "
                          @click="documentListUpdateItem"
                        />

                        <RgLessButton
                          id="remove-document"
                          :disabled="
                            disabledByModal ||
                            disabledByModalComponent ||
                            disabledEditButton
                          "
                          @click="documentListRemoveItem"
                        />
                      </div>
                    </SmartTable>
                    <div v-else class="empty">
                      <IconEmpty class="svg" />
                      <spam>Não há documentos cadastrados</spam>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormBase>
        </div>

        <div
          :disabled="
            disableOrEnableModule || disabledByModal || disabledByModalComponent
          "
          class="module-hospitalization"
        >
          <FormBase class="form-base" title="Internação">
            <div class="content">
              <div class="setflex">
                <RgInputDate
                  id="entry-date"
                  ref="inputDateEntry"
                  v-model="form.hospitalization.int_data"
                  :rules="{ required: true, fn: verifyDateEntry }"
                  :class="{
                    'disable unselect': disableOrEnableModule || blockUpdate,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    blockUpdate ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :max-date="new Date()"
                  label="Data de Entrada"
                  class="dates -margin-right-small"
                />

                <RgInputHour
                  id="entry-hour"
                  v-model="form.hospitalization.int_hora"
                  :rules="{ required: true, fn: verifyHourEntry }"
                  :class="{
                    'disable unselect': disableOrEnableModule || blockUpdate,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    blockUpdate ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  label="Hora de Entrada"
                  placeholder="HH:MM"
                  class="hours -margin-right-small"
                />

                <RgInputDate
                  id="discharge-date"
                  ref="inputDateDischarge"
                  v-model="form.hospitalization.int_previsao_alta"
                  :rules="{ fn: verifyDateDischarge }"
                  :class="{
                    disable:
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :min-date="new Date()"
                  label="Previsão de alta"
                  class="dates -margin-right-small"
                />

                <RgComboboxSituationBed
                  id="situation-bed"
                  ref="situationBed"
                  v-model="form.hospitalization.int_ultima_situacao_internacao"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || fromMapBed || isNotReservation,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    fromMapBed ||
                    isNotReservation ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :rules="{ required: true }"
                  :fromHospitalization="!fromMapBed"
                  class="situation -margin-right-small"
                />

                <RgSuggestTypeBed
                  id="type-bed"
                  ref="typeBed"
                  v-model="selectedTypeBed"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      blockUpdate ||
                      disabledByModal ||
                      disabledByModalComponent ||
                      blockTypeBed,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    blockUpdate ||
                    disabledByModal ||
                    disabledByModalComponent ||
                    blockTypeBed
                  "
                  :inlId="form.hospitalization.inl_id"
                  :typeBedIds="form.hospitalization.ids_tipos_do_leito"
                  class="type-bed"
                  @selected="selectingBedType"
                />
              </div>

              <div class="setflex">
                <RgComboboxHospitalizationUnithealth
                  id="unit-health"
                  ref="unitHealth"
                  v-model="uns_id"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableUnitySectorLocale ||
                      blockUpdate ||
                      fromMapBed ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableUnitySectorLocale ||
                    blockUpdate ||
                    fromMapBed ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :current-situation="getSituationBed"
                  :permission="SECTOR_PERMISSION"
                  :uns-id="unitHealthId"
                  class="unity -margin-right-small"
                />

                <RgComboboxHospitalizationSector
                  id="sector"
                  ref="sector"
                  v-model="set_id"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableSector ||
                    blockUpdate ||
                    fromMapBed ||
                    disableUnitySectorLocale ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnableSector ||
                      blockUpdate ||
                      fromMapBed ||
                      disableUnitySectorLocale,
                  }"
                  :unit-health="uns_id"
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :inl-id="form.hospitalization.inl_id"
                  :current-situation="getSituationBed"
                  :permission="SECTOR_PERMISSION"
                  class="sector -margin-right-small"
                />

                <RgComboboxHospitalizationPlace
                  id="locale"
                  ref="locale"
                  v-model="lca_id"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnablePlace ||
                    blockUpdate ||
                    fromMapBed ||
                    disableUnitySectorLocale ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnablePlace ||
                      blockUpdate ||
                      fromMapBed ||
                      disableUnitySectorLocale,
                  }"
                  :sector="set_id"
                  :rules="{ required: true }"
                  :from-map-bed="fromMapBed"
                  :bed-type-id="form.hospitalization.itl_id"
                  :current-situation="getSituationBed"
                  :onlyActive="this.onlyActive"
                  class="place -margin-right-small"
                />

                <RgSuggestHospitalizationBed
                  id="bed"
                  ref="bed"
                  v-model="bed"
                  :local-id="localId"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disableOrEnableBed ||
                      blockUpdate ||
                      fromMapBed ||
                      disabledBed,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableBed ||
                    blockUpdate ||
                    fromMapBed ||
                    disabledBed ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :empty-bed="!blockUpdate"
                  :bedType="bedType"
                  class="bed -margin-right-small"
                  @selected="selectingHospitalizationBed"
                />

                <RgSuggestEmployee
                  id="employee"
                  ref="responsible"
                  v-model="responsibleEmployee"
                  :rules="{ required: true, forceSelection: true }"
                  :sector-required="true"
                  :sector="set_id"
                  :class="{
                    'disable unselect':
                      disableOrEnableEmployeesResponsible || blockUpdate,
                  }"
                  :disabled="
                    disableOrEnableEmployeesResponsible ||
                    blockUpdate ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  data-id="profissional-responasvel"
                  data-item="profissional-responasvel"
                  placeholder="Digite o nome do profissional responsável"
                  label="Profissional Responsável pelo Setor"
                  class="responsible-employee"
                  @selected="selectingResponsibleEmployee"
                />
              </div>

              <div class="setflex">
                <RgComboboxHospitalizationModality
                  id="modality"
                  ref="modality"
                  v-model="form.hospitalization.int_id_internacoes_modalidade"
                  :rules="{ required: true }"
                  :class="{
                    disable:
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  class="modality -margin-right-small"
                  label="Modalidade:"
                />

                <RgComboboxHospitalizationServiceCharacter
                  id="character"
                  ref="character"
                  v-model="form.hospitalization.int_id_internacoes_caracteres"
                  :class="{
                    disable:
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :rules="{ required: true }"
                  class="character -margin-right-small"
                />

                <RgComboboxHealthPlan
                  id="health-plan"
                  ref="plan"
                  v-model="form.hospitalization.int_id_planos_saude"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent ||
                      disableOrEnableHealthPlan,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent ||
                    disableOrEnableHealthPlan
                  "
                  :rules="{ required: true }"
                  :modId="getHealthPlanModule"
                  :unitHealth="uns_id"
                  class="health-plan -margin-right-small"
                  label="Convênio"
                  @change="cleanProcedure"
                />
                <RgComboboxHospitalizationType
                  id="hospitalization-type"
                  v-model="
                    form.hospitalization.int_id_internacoes_tipos_internacao
                  "
                  :class="{
                    disable:
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  class="hospitalization-type"
                />
              </div>

              <div v-if="isHospitalizationSolicitation" class="tooltip-area">
                <Tooltip class="tooltip" clickable startOpen>
                  <IconExclamationHelper slot="icon" hollow />
                  <div slot="content" class="content">
                    <div class="title">
                      <span>Dados da solicitação de internação: </span>
                      <ul class="list">
                        <li class="item">
                          Procedimento:
                          {{
                            hospitalizationSolicitationData.inp_novo_nome_procedimento ||
                            hospitalizationSolicitationData.ins_nome_procedimento_pep ||
                            "Não informado"
                          }}
                        </li>
                        <li class="item">
                          CID:
                          {{
                            hospitalizationSolicitationData.cid_nome ||
                            "Não informado"
                          }}
                        </li>
                        <li class="item">
                          Ocupação:
                          {{
                            hospitalizationSolicitationData.ocp_nome ||
                            "Não informado"
                          }}
                        </li>
                        <li class="item">
                          Profissional:
                          {{
                            hospitalizationSolicitationData.profissional ||
                            "Não informado"
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Tooltip>
              </div>

              <div v-if="hasHospitalizationRegulation" class="tooltip-area">
                <span>Procedimento </span>
                <hr />
                <Tooltip class="tooltip" clickable startOpen>
                  <IconExclamationHelper slot="icon" hollow />
                  <div slot="content" class="content">
                    <div class="title">
                      <span>Dados da regulação: </span>
                      <ul class="list">
                        <li class="item">
                          Procedimento:
                          {{ hasProcedureFromQueue }}
                        </li>
                        <li class="item">
                          CID-10:
                          {{ hasCidFromQueue }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Tooltip>
              </div>

              <div class="setflex">
                <RgSuggestHospitalizationProcedureCid
                  id="procedures"
                  ref="procedures"
                  v-model="suggestHospitalizationProcedures"
                  :unit-id="uns_id"
                  :patient-gender="gender"
                  :health-plan="healthPlan"
                  :cid-code="cidCode"
                  :patientBirthDate="patientBirthDate"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || disableOrEnableProcedureCid,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableProcedureCid ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :autoSelectIfHasOne="!isHospitalizationSolicitation"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Digite o procedimento"
                  label="Procedimento Solicitado"
                  class="procedure-requester -margin-right-small"
                  @selected="selectingProcedure"
                />

                <RgSuggestCid
                  id="main-cid"
                  ref="mainCid"
                  v-model="mainCid"
                  :rules="{ required: true, forceSelection: true }"
                  :disabled="
                    ((disableOrEnableModule || disableOrEnableProcedureCid) &&
                      validateFieldsPreference) ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    'disable unselect':
                      (disableOrEnableModule || disableOrEnableProcedureCid) &&
                      validateFieldsPreference,
                  }"
                  :patient-gender="gender"
                  :procedureCode="procedureCode"
                  data-id="CID"
                  label="CID Principal"
                  class="cid"
                  @selected="selectingCidCode"
                />
              </div>

              <div class="setflex">
                <RgSuggestCboProcedure
                  id="cbo"
                  ref="cbo"
                  v-model="cboName"
                  :class="{
                    'disable unselect':
                      (disableOrEnableModule || !hasProcedure) &&
                      validateFieldsPreference,
                  }"
                  :disabled="
                    ((disableOrEnableModule || !hasProcedure) &&
                      validateFieldsPreference) ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :rules="{ required: true, forceSelection: true }"
                  :procedureCode="procedureCode"
                  label="Ocupação"
                  class="occupation -margin-right-small"
                  placeholder="Digite a ocupação"
                  @selected="selectingCbo"
                />
                <RgSuggestEmployee
                  id="requester-employee"
                  ref="requester"
                  v-model="requesterEmployee"
                  :rules="{ required: true, forceSelection: true }"
                  :class="{
                    'disable unselect':
                      disableOrEnableModule || disableOrEnableEmployeesRequest,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disableOrEnableEmployeesRequest ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :ocuppation-id="form.hospitalization.int_id_ocupacoes_cbo"
                  data-id="profissional-solicitante"
                  data-item="profissional-solicitante"
                  placeholder="Digite o nome do profissional solicitante"
                  label="Profissional Solicitante"
                  class="requester-employee"
                  @selected="selectingRequestEmployee"
                />
              </div>

              <div class="setflex">
                <RgTextArea
                  id="observation"
                  ref="textArea"
                  v-model="form.hospitalization.int_obs"
                  :class="{
                    disable:
                      disableOrEnableModule ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :disabled="
                    disableOrEnableModule ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :maxlength="500"
                  label="Observação"
                  class="textarea"
                />
              </div>
            </div>
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <ModalConfirmFieldClearing
      id="modal-confirm-field-clearing"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
      @close="closeModalConfirmFieldClearing"
    />
  </div>
</template>

<script>
import moment from "moment";
import { isNumber } from "lodash";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  GetGQLEnum,
  BedSituations,
} from "$hospitalization/common/component/util/GetTypeBedValues";
import HealthPlanModule from "$hospitalization/common/component/util/HealthPlanModuleValues";
import { SmartTable } from "~tokio/foundation";

import { AlertError } from "~tokio/primitive/notification";
import RgTextArea from "~tokio/primitive/input/rg-text-area/RgTextArea";
import {
  RgSuggestPatientInformation,
  RgInput,
  RgInputMasked,
  RgValidatorForm,
  RgSuggestEmployee,
  RgInputDate,
  RgInputHour,
  IconEmpty,
  ModalConfirmFieldClearing,
  RgSuggestCid,
  RgAddButton,
  IconExclamationHelper,
  Tooltip,
  RgLessButton,
  RgEditButton,
  RgRadioCustom,
} from "~tokio/primitive";
import { RgComboboxHealthPlan } from "$patient/common/components";
import RgComboboxHospitalizationModality from "$billing/submodules/aih/hospitalization-billing/common/component/combobox/rg-combobox-hospitalization-modality/RgComboboxHospitalizationModality";
import {
  RgSuggestHospitalizationProcedureCid,
  RgComboboxHospitalizationServiceCharacter,
  RgComboboxCompanionType,
  RgComboboxSituationBed,
  RgComboboxDocumentType,
  RgSuggestTypeBed,
  RgDocumentInput,
  RgComboboxHospitalizationType,
  RgSuggestHospitalizationBed,
  RgComboboxHospitalizationUnithealth,
  RgComboboxHospitalizationSector,
  RgComboboxHospitalizationPlace,
} from "../../../common/component";

import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";
import {
  mountPatientInfo,
  mountProcedureInfo,
  mountEmployeeInfo,
  mountHospitalization,
  mountCompanionInfo,
  mountIndigent,
} from "./helpers/PrepareData";
import TypeBedOperationEnum from "$hospitalization/common/component/util/TypeBedOperationEnum";
import SearchPatientHospitalized from "../../store/actions/SearchPatientHospitalized";
import { mapGetters } from "vuex";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import ScheduleHospitalizationHTML from "$hospitalization/hospitalizations/queue/html/ScheduleHospitalizationHTML";
import SearchHospitalizationProcedure from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-procedure-cid/action/SearchHospitalizationProcedure";

export default {
  name: "HospitalizationDetails",
  components: {
    RgSuggestPatientInformation,
    RgInput,
    RgInputMasked,
    FormBase,
    RgComboboxCompanionType,
    RgComboboxSituationBed,
    RgComboboxDocumentType,
    RgComboboxHospitalizationPlace,
    RgComboboxHospitalizationUnithealth,
    RgComboboxHospitalizationSector,
    RgComboboxHospitalizationServiceCharacter,
    RgComboboxHospitalizationType,
    RgInputHour,
    RgInputDate,
    RgSuggestHospitalizationBed,
    RgComboboxHospitalizationModality,
    RgComboboxHealthPlan,
    RgSuggestCboProcedure,
    RgSuggestEmployee,
    Tooltip,
    IconExclamationHelper,
    RgSuggestCid,
    RgTextArea,
    IconEmpty,
    RgDocumentInput,
    RgSuggestHospitalizationProcedureCid,
    RgValidatorForm,
    RgSuggestTypeBed,
    SmartTable,
    ModalConfirmFieldClearing,
    RgAddButton,
    RgLessButton,
    RgEditButton,
    RgRadioCustom,
    RgInputCns,
    RgInputCpf,
  },
  props: {
    hospitalizationId: {
      type: Number,
      default: 0,
    },
    hospitalization: {
      type: Object,
      default: () => {},
    },
    operation: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    fromMapBed: {
      type: Boolean,
      default: false,
    },
    fromSolicitation: {
      type: Boolean,
      default: false,
    },
    fromRegulation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        patient: {
          pac_id: null,
        },
        hospitalization: {
          int_id: null,
          int_id_planos_saude: null,
          int_id_pacientes_internacao: null,
          int_id_filas_internacao: null,
          int_id_internacoes_tipos_responsavel: null,
          int_id_internacoes_tipos_internacao: null,
          int_id_ocupacoes_cbo: null,
          int_id_funcionarios_responsavel: null,
          int_id_funcionarios: null,
          int_id_internacoes_modalidade: null,
          int_id_cid10: null,
          int_id_internacoes_procedimentos: null,
          int_id_internacoes_caracteres: null,
          int_previsao_alta: null,
          int_data: moment().format("DD/MM/YYYY"),
          int_hora: moment().format("HH:mm"),
          int_ultima_situacao_internacao: null,
          int_obs: null,
          inl_id: null,
          itl_id: 0,
        },
        companion: {
          via_id: null,
          via_nome: null,
          documents: [],
        },
        indigent: {
          ini_id: null,
          ini_descricao: null,
          ini_id_sexos: null,
          ini_data_aproximada: null,
        },
        is_indigent: false,
        ihs_id: null,
      },
      auxInfo: {
        patient: {
          pes_mae: null,
          pes_pai: null,
          disabledCompanionName: false,
        },
      },
      patientInfo: {},
      patientCurrentGender: null,
      indigentAge: null,
      mainCid: null,
      cid_codigo: null,
      selectedTypeBed: null,
      requesterEmployee: null,
      responsibleEmployee: null,
      suggestPatient: null,
      suggestHospitalizationProcedures: null,
      cboCode: null,
      cboName: null,
      documentTypeSelected: {
        dva_id_documentos: null,
        dva_nome: null,
      },
      isl_id: 0,
      bed: null,
      lastStatusBed: null,
      documentType: null,
      dva_numero_documentos: null,
      editMode: false,
      editIndex: null,
      disableModule: false,
      disableLocale: false,
      validateAddBtn: false,
      showModalHistoryPatient: false,
      disabledPatient: false,
      cns_required: false,
      procedure: {
        vl_idade_minima: "",
        vl_idade_maxima: "",
      },
      isPatientIndigent: false,
      blockChangeTypePatient: false,
      modalConfirmFieldClearing: false,
      patientIsHospitalized: false,
      uns_id: 0,
      set_id: null,
      lca_id: null,
      inp_novo_codigo_procedimento: null,
      isNotReservation: false,
      procedureHasCid: 0,
      hospitalizationSolicitationData: {},
      hospitalizationRegulationData: {},
      selectedLineDocument: null,
      disabledByModalComponent: false,
      fromMapBedToRegister: false,
      fromMapBedOperation: 0,
      fromMapBedStatus: 0,
      blockTypeBed: false,
      document: {
        cpf: null,
        cpf_id: null,
        cns: null,
        crs_id: null,
      },
      hasCns: false,
      hasCpf: false,
      onlyActive: true,
    };
  },
  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      hospitalizationUnitHealthRecordNumberPreference:
        "Login/GET_HOSPITALIZATION_UNIT_HEALTH_RECORD_NUMBER_PREFERENCES",
    }),
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.indigent.ini_id_sexos;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    disabledByModal() {
      return this.showModalHistoryPatient || this.modalConfirmFieldClearing;
    },
    isHospitalizationSolicitation() {
      return (
        this.$route.name ===
        "hospitalization.hospitalization-solicitation.register"
      );
    },
    unitHealthId() {
      return !this.fromRegulation
        ? this.$store.getters["Login/GET_UNIT_HEALTH_ID"]
        : null;
    },
    existTypeCompanion() {
      return !(
        this.form.hospitalization.int_id_internacoes_tipos_responsavel &&
        this.form.hospitalization.int_id_internacoes_tipos_responsavel > 0
      );
    },
    localId() {
      const isValidLocalId = this.lca_id > 0;
      return isValidLocalId ? this.lca_id : 0;
    },
    bedType() {
      return this.form.hospitalization.itl_id;
    },
    disableOrEnableModule() {
      const isPatient =
        this.patientInfo.pes_id &&
        this.suggestPatient &&
        !this.isPatientIndigent;
      const isIndigent = this.isPatientIndigent;
      return !(isPatient || isIndigent);
    },
    disableOrEnableCompanionModule() {
      const isPatient =
        this.patientInfo.pes_id &&
        this.suggestPatient &&
        !this.isPatientIndigent;
      return !isPatient;
    },
    hasHospitalizationSolicitation() {
      return (
        this.hospitalizationSolicitationData &&
        this.hospitalizationSolicitationData.ins_id
      );
    },
    hasHospitalizationRegulation() {
      return (
        this.hospitalizationRegulationData &&
        this.hospitalizationRegulationData.fil_id
      );
    },
    hasProcedureFromQueue() {
      return this.hospitalizationRegulationData?.inp_novo_codigo_procedimento
        ? this.hospitalizationRegulationData.inp_novo_codigo_procedimento +
            " - " +
            this.hospitalizationRegulationData.inp_novo_nome_procedimento
        : "Não informado";
    },
    hasCidFromQueue() {
      return this.hospitalizationRegulationData?.cid_codigo
        ? this.hospitalizationRegulationData.cid_codigo +
            " - " +
            this.hospitalizationRegulationData.cid_nome
        : "Não informado";
    },
    hasUnitHealth() {
      return this.uns_id;
    },
    hasHealthPlan() {
      const healthPlan = this.form.hospitalization.int_id_planos_saude;
      return healthPlan && isNumber(healthPlan);
    },
    hasProcedure() {
      return this.suggestHospitalizationProcedures;
    },
    gender() {
      return this.form.indigent.ini_id_sexos || this.patientInfo.sex_sigla;
    },
    healthPlan() {
      return this.form.hospitalization.int_id_planos_saude
        ? Number(this.form.hospitalization.int_id_planos_saude)
        : null;
    },
    hasGender() {
      return this.gender ? this.gender : false;
    },
    disableOrEnableSector() {
      return !this.uns_id;
    },
    disableOrEnablePlace() {
      return !this.set_id;
    },
    disableOrEnableBed() {
      return !this.lca_id;
    },
    disableOrEnableDocument() {
      return !this.documentType;
    },
    disableOrEnableHealthPlan() {
      return !this.uns_id;
    },
    blockUpdate() {
      return (
        this.form.hospitalization.int_id && this.form.hospitalization.int_id > 0
      );
    },
    notPatient() {
      if (!this.form.indigent.ini_id) {
        return (
          this.form.hospitalization &&
          (!this.form.hospitalization.int_id || !this.form.patient.pac_id)
        );
      }
      return false;
    },
    disableOrEnableDocuments() {
      return this.form.indigent.ini_id;
    },
    formatDate() {
      return moment(this.patientInfo.pes_nascimento).format("DD/MM/YYYY");
    },
    disableOrEnableProcedureCid() {
      return !this.hasUnitHealth || !this.hasGender || !this.hasHealthPlan;
    },
    disableOrEnableWithCbo() {
      return !(this.form.hospitalization.int_id_ocupacoes_cbo && this.cboCode);
    },
    disableOrEnableEmployeesRequest() {
      return !(this.form.hospitalization.int_id_ocupacoes_cbo && this.cboCode);
    },
    disableOrEnableEmployeesResponsible() {
      return !this.set_id;
    },
    getSituationBed() {
      const situation = this.status ? this.status : this.isl_id;
      return GetGQLEnum(situation);
    },
    getHealthPlanModule() {
      return HealthPlanModule.INTERNACAO;
    },
    disabledModalPatientHistory() {
      return !this.form.patient.pac_id;
    },
    disabledBed() {
      return !(this.uns_id && this.set_id && this.lca_id);
    },
    disableUnitySectorLocale() {
      if (this.fromRegulation) return false;

      if (!this.fromMapBed) {
        return !this.form.hospitalization.itl_id;
      }
      return false;
    },
    disabledEditButton() {
      return !this.selectedLineDocument;
    },
    hasPersonSelected() {
      return !!this.patientInfo.pes_id;
    },
    procedureCode() {
      return this.procedureHasCid > 0 && this.validateFieldsPreference
        ? this.inp_novo_codigo_procedimento
        : null;
    },
    cidCode() {
      return this.cid_codigo && this.validateFieldsPreference
        ? this.cid_codigo
        : null;
    },
    validateFieldsPreference() {
      const validatePreference = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewCadastroInternacoes.rbt_validar_dados_internacao"
      ];
      return Boolean(Number(validatePreference)) || false;
    },
    requiredCpf() {
      return this.preferenceCpfMandatoryScheduling === "1";
    },
    requiredCns() {
      return this.preferenceCnsMandatoryScheduling === "1";
    },
    messageTooltip() {
      return "Sempre mantenha as informações do paciente atualizadas. Recomenda-se o registro da documentação, principalmente CNS e CPF.";
    },
    preferenceCpfMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewInternacao.rbt_cpf_agendamento_internacao"
      ];
    },
    preferenceCnsMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewInternacao.rbt_cns_agendamento_internacao"
      ];
    },
    patientBirthDate() {
      return this.patientInfo?.pes_nascimento;
    },
  },

  watch: {
    set_id(pValue, pPrev) {
      if (
        ((pPrev !== null && pValue !== pPrev) || !pValue) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.lca_id = null;
        this.requesterEmployee = "";
        this.lastStatusBed = null;
        this.bed = null;
        this.form.hospitalization.inl_id = null;
        this.form.hospitalization.int_id_funcionarios = null;
        this.responsibleEmployee = "";
        this.form.hospitalization.int_id_funcionarios_responsavel = null;
      }
    },

    "form.patient.pac_id"(pValue) {
      if (!pValue) {
        this.form.companion.via_id = null;
        this.form.companion.via_nome = null;
        this.form.companion.documents = [];
        this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
      }
    },

    isPatientIndigent(pValue) {
      if (pValue) {
        this.cleanPerson();
      } else {
        this.cleanIndigent();
      }

      this.form.is_indigent = pValue;
    },

    uns_id(pValue, pPrev) {
      if (
        (!pValue || (pPrev && pValue !== pPrev)) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.set_id = null;
        this.lca_id = null;
        this.lastStatusBed = null;
        this.bed = null;
        this.form.hospitalization.inl_id = null;
        this.form.hospitalization.int_id_planos_saude = null;
        this.form.hospitalization.int_id_internacoes_procedimentos = null;
        this.suggestHospitalizationProcedures = null;
        this.$refs.procedures.cleanValidate();
      }
    },

    lca_id(pValue, pPrev) {
      if (
        (!pValue || (pPrev && pValue !== pPrev)) &&
        !this.form.hospitalization.int_id &&
        !this.fromMapBed
      ) {
        this.bed = null;
        this.lastStatusBed = null;
        this.form.hospitalization.inl_id = null;
      }
    },

    suggestHospitalizationProcedures(pValue) {
      if (!pValue) {
        this.cleanProcedureRelatedInfo();
      }
    },

    "form.hospitalization.int_id_internacoes_tipos_responsavel"(pValue, pPrev) {
      if (!pValue) {
        this.$nextTick(async () => {
          this.auxInfo.patient.disabledCompanionName = true;
          this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
          this.form.companion.via_nome = null;
          this.$refs.companionName.cleanValidate();
        });
      }
      if (!pValue || (pPrev && pValue !== pPrev)) {
        this.form.companion.documents = [];
        this.form.companion.via_id = null;
        this.form.companion.via_nome = null;
        this.cleanDocumentInfo();
      }

      const patientHasMother = this.auxInfo.patient.pes_mae;
      const patientHasFather = this.auxInfo.patient.pes_pai;
      if (pValue === 1 && patientHasMother) {
        this.form.companion.via_nome = this.auxInfo.patient.pes_mae;
        this.auxInfo.patient.disabledCompanionName = true;
      } else if (pValue === 2 && patientHasFather) {
        this.form.companion.via_nome = this.auxInfo.patient.pes_pai;
        this.auxInfo.patient.disabledCompanionName = true;
      } else {
        this.auxInfo.patient.disabledCompanionName = false;
      }
    },

    "form.indigent.ini_id_sexos"(pValue) {
      if (this.isPatientIndigent && this.patientCurrentGender !== pValue) {
        this.cleanProcedure();
      }
      if (pValue) {
        this.patientCurrentGender = pValue;
      }
    },

    "form.hospitalization.int_id_ocupacoes_cbo"(pValue) {
      if (!pValue) {
        this.requesterEmployee = "";
        this.form.hospitalization.int_id_funcionarios = null;
        this.$refs.requester.cleanValidate();
      }
    },

    "form.hospitalization.int_previsao_alta"(pValue) {
      if (!pValue) {
        this.form.hospitalization.int_previsao_alta = null;
      }
    },

    indigentAge(pValue) {
      const isValidAge = pValue > 0;
      let date = null;

      if (isValidAge) {
        const actualYear = moment().format("YYYY");
        const bornYear = parseInt(actualYear) - parseInt(pValue);
        date = `01/01/${bornYear}`;
      }

      this.form.indigent.ini_data_aproximada = date;
    },

    async hospitalizationId(pValue) {
      if (pValue && this.status !== 1) {
        const pData = await this.$store.dispatch(
          "Hospitalization/Hospitalizations/SEARCH_HOSPITALIZATION",
          { int_id: pValue },
        );

        this.setData(pData.rows[0]);
      }
    },
  },

  created() {
    this.SECTOR_PERMISSION = this.fromMapBed ? 351 : 302;
    this.COLUMNS = [
      { name: "Tipo", key: "dva_nome" },
      { name: "Número", key: "dva_numero_documentos" },
    ];
  },

  mounted() {
    this.hospitalizationSolicitationData = this.$store.getters[
      "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_SOLICITATION"
    ];

    this.hospitalizationRegulationData = this.$store.getters[
      "Hospitalization/Queue/GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT"
    ];

    if (
      !this.hasHospitalizationSolicitation &&
      !this.hasHospitalizationRegulation
    ) {
      let pData = this.$route.params.form;

      if (!pData) {
        pData = this.$store.getters[
          "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_INFO"
        ];
      }

      const hasHospitalization = pData.int_id && pData.int_id > 0;
      this.onlyActive = !hasHospitalization;

      this.setData(pData);
    }

    if (this.hasHospitalizationSolicitation) {
      this.setDataSolicitation(this.hospitalizationSolicitationData);
    }

    if (this.hasHospitalizationRegulation) {
      this.setDataRegulation(this.hospitalizationRegulationData);
    }
  },

  beforeDestroy() {
    this.resetHospitalizationInfo();
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.indigent.ini_id_sexos = item.id;
      }
    },

    disabledByModalComponents(disabledByModal) {
      this.disabledByModalComponent = disabledByModal;
      this.$emit("disabledByModalComponents", this.disabledByModalComponent);
      return this.disabledByModalComponent;
    },

    selectLine(pValue) {
      this.selectedLineDocument = pValue || null;

      this.dva_numero_documentos = "";
      this.documentType = "";
      this.validateAddBtn = false;
      this.$refs.documentInput.clearInput();
    },

    async setDataSolicitation(pData) {
      try {
        this.$loader.start();

        if (pData.pacienteId && pData.pac_id) {
          this.$refs.patient.fillPatientById(Number(pData.pacienteId));
          this.disabledPatient = true;
          this.blockChangeTypePatient = true;

          const isAlreadyHospitalized =
            pData.pac_id > 0
              ? await this.searchPatientHospitalized({
                  patientId: parseInt(pData.pac_id),
                })
              : false;

          const isPatientSetted =
            pData.pac_id &&
            !this.isPatientIndigent &&
            !this.form.hospitalization.int_id;

          if (isPatientSetted && isAlreadyHospitalized) {
            this.$toaster.warning("Esse paciente encontra-se internado");
            this.blockSavePatient = true;
          }
        } else {
          this.disabledPatient = false;
          this.isPatientIndigent = true;
        }
        const cid = {
          cid_codigo: pData.cid_codigo,
          cid_nome: pData.cid_nome,
        };

        await this.$refs.mainCid.forceSelection(cid);

        this.form.hospitalization.int_id_cid10 = pData.cid_id;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar o paciente");
      } finally {
        this.$loader.finish();
      }
    },

    async setDataRegulation(pData) {
      try {
        this.$loader.start();

        this.disabledPatient = true;
        this.blockChangeTypePatient = true;

        this.form.hospitalization.ids_tipos_do_leito =
          pData.flt_id_internacoes_tipos_leito;

        this.form.hospitalization.itl_id = Number(
          pData.flt_id_internacoes_tipos_leito,
        );

        await this.$refs.patient.fillPatientById(Number(pData.pes_id));
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar o paciente");
      } finally {
        this.$loader.finish();
      }
    },

    async setData(pData) {
      this.resetHospitalizationInfo();

      try {
        this.$loader.start();
        const canMountData = (pData && pData.int_id) || false;

        if (canMountData || this.fromMapBed) {
          this.$store.commit(
            "Hospitalization/Hospitalizations/SET_HOSPITALIZATION_INFO",
            {
              ...pData,
              fromMapBed: this.fromMapBed,
              fromMapBedOperation: this.operation,
              fromMapBedStatus: this.status,
            },
          );

          this.isl_id = pData.isl_id;
          this.form.hospitalization.inl_id = pData.inl_id;
          this.form.hospitalization.itl_id = pData.itl_id;
          this.selectedTypeBed = pData.itl_nome;
          this.$refs.typeBed.forceSelection(pData);
          this.uns_id = pData.uns_id;
          this.set_id = pData.set_id;
          this.lca_id = pData.lca_id;

          this.isPatientIndigent = false;
          this.form.hospitalization = await mountHospitalization(pData);

          this.isNotReservation =
            this.form.hospitalization.int_id &&
            this.form.hospitalization.int_ultima_situacao_internacao !==
              BedSituations.RESERVA;

          const indigent = mountIndigent(pData);
          if (canMountData || !this.fromMapBed) {
            const patient = mountPatientInfo(pData);
            if (indigent.ini_id || !patient) {
              this.isPatientIndigent = true;
              this.form.indigent = indigent.info;
              this.form.indigent.ini_id_sexos = indigent.info.ini_id_sexos;
              this.patientCurrentGender = indigent.info.ini_id_sexos;
            } else if (patient.pes_id) {
              await this.$refs.patient.fillPatientById(patient.pes_id);
              this.disabledPatient = true;
              this.isPatientIndigent = false;
              this.blockChangeTypePatient = true;
            }
          }

          this.form.companion = mountCompanionInfo(pData);

          this.form.hospitalization.int_id_internacoes_tipos_responsavel =
            pData.int_id_internacoes_tipos_responsavel;

          const procedure = mountProcedureInfo(pData);

          this.suggestHospitalizationProcedures =
            pData.inp_novo_nome_procedimento;

          await this.$refs.procedures.forceSelection(procedure);

          this.mainCid = pData.cid_nome;

          const cid = {
            cid_codigo: pData.cid_codigo,
            cid_nome: pData.cid_nome,
          };

          await this.$refs.mainCid.forceSelection(cid);

          this.form.hospitalization.int_id_cid10 = pData.int_id_cid10;

          const employeeRequesterInfo = await this.getEmployeeInfo(
            pData.int_id_funcionarios,
          );
          const employeeRequester = mountEmployeeInfo(
            pData,
            employeeRequesterInfo,
            1,
          );
          if (this.$refs.requester) {
            this.$refs.requester.forceSelection(employeeRequester);
          }

          const employeeResponsibleInfo = await this.getEmployeeInfo(
            pData.int_id_funcionarios_responsavel,
          );
          const employeeResponsible = mountEmployeeInfo(
            pData,
            employeeResponsibleInfo,
            2,
          );
          if (this.$refs.responsible) {
            this.$refs.responsible.forceSelection(employeeResponsible);
          }
          if (pData.ocp_codigo && pData.ocp_nome) {
            this.cboCode = pData.ocp_codigo;
            this.cboName = pData.ocp_codigo + " - " + pData.ocp_nome;
          }

          const cbo = {
            ocp_codigo: pData.ocp_codigo,
            ocp_nome: pData.ocp_nome,
          };
          if (this.$refs.cbo) {
            this.$refs.cbo.forceSelection(cbo);
          }
          this.form.hospitalization.int_id_ocupacoes_cbo =
            pData.int_id_ocupacoes_cbo;

          this.bed = pData.inl_nome;
          if (this.$refs.bed) {
            this.$refs.bed.forceSelection(pData.inl_nome);
          }
          if (this.$refs.gender) {
            this.$refs.gender.selectedGender = this.form.indigent.ini_id_sexos;
          }

          if (this.operation) {
            this.form.hospitalization.int_ultima_situacao_internacao = this.operation;
          }

          this.indigentAge = indigent && indigent.age;
        }

        if (this.lastPersonIdSaved) {
          this.setPatientFromRegister();
        }
      } catch (Err) {
        console.log(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async setPatientFromRegister() {
      await this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
      this.isPatientIndigent = false;
    },

    async getEmployeeInfo(pFunId) {
      const hasEmployeeId = pFunId > 0;
      if (hasEmployeeId) {
        const extraInfoEmployee = await this.$store.dispatch(
          "Employee/GET_EMPLOYEE_RELATIONSHIP_LIST_BY_EMPLOYEE_ID",
          {
            criterias: {
              employeeId: Number(pFunId),
            },
          },
        );

        return extraInfoEmployee[0];
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      const data = this.$utils.obj.DeepCopy(this.$data);

      data.form.uns_id = data.uns_id;
      data.form.set_id = data.set_id;
      data.form.lca_id = data.lca_id;

      if (data.form.hospitalization.int_previsao_alta) {
        data.form.hospitalization.int_previsao_alta = this.$utils.date.BrazilianDateToDatabase(
          data.form.hospitalization.int_previsao_alta,
        );
      }
      data.form.hospitalization.int_data = this.$utils.date.BrazilianDateToDatabase(
        data.form.hospitalization.int_data,
      );

      data.form.indigent.ini_data_aproximada = this.$utils.date.BrazilianDateToDatabase(
        data.form.indigent.ini_data_aproximada,
      );

      data.form.indigent.ini_descricao = this.$utils.sanitize.removeExtraSpaces(
        data.form.indigent.ini_descricao,
      );

      if (data.form.companion.via_nome) {
        data.form.companion.via_nome = this.$utils.sanitize.removeExtraSpaces(
          data.form.companion.via_nome,
        );
      } else {
        data.form.companion.via_nome = null;
      }

      data.form.hospitalization.int_obs = this.$utils.sanitize.removeExtraSpaces(
        data.form.hospitalization.int_obs,
      );

      data.form.indigent.ini_id_sexos =
        data.form.indigent.ini_id_sexos === "M" ? 1 : 2;

      if (
        this.form.hospitalization.int_ultima_situacao_internacao ===
        BedSituations.RESERVA
      ) {
        data.form.hospitalization.ihs_tipo_operacao =
          TypeBedOperationEnum.RESERVA;
      } else {
        data.form.hospitalization.ihs_tipo_operacao =
          TypeBedOperationEnum.INTERNACAO;
      }

      data.form.pes_id = this.patientInfo.pes_id;

      delete data.form.hospitalization.ids_tipos_do_leito;
      delete data.form.companion.via_id;

      return data;
    },

    async saveForm(pError) {
      const isValidForm = await this.isFormValid();

      if (!isValidForm) {
        return AlertError("Verifique os campos");
      }

      const isBedOccupied =
        !this.form.hospitalization.int_id && this.lastStatusBed === 3;

      if (isBedOccupied) {
        const message =
          "Por favor selecione outro leito para internar o paciente. Situação do leito: OCUPADO";

        return AlertError(message);
      }

      const patientId = this.form.patient.pac_id;
      try {
        this.$loader.start();
        const isAlreadyHospitalized =
          patientId > 0
            ? await this.searchPatientHospitalized({
                patientId: this.form.patient.pac_id,
              })
            : false;

        const isPatientSetted =
          !this.isPatientIndigent &&
          patientId &&
          !this.form.hospitalization.int_id;

        if (isPatientSetted && isAlreadyHospitalized) {
          return AlertError("Esse paciente encontra-se internado!");
        }

        const dataForm = this.formatData();
        const isFromSolicitation = this.hasHospitalizationSolicitation;

        const insId = isFromSolicitation
          ? this.hospitalizationSolicitationData.ins_id
          : null;

        let payload = dataForm.form;
        let action = "Hospitalization/Hospitalizations/SAVE_HOSPITALIZATION";

        if (isFromSolicitation) {
          action =
            "Hospitalization/Hospitalizations/SAVE_HOSPITALIZATION_AND_SOLICITATION";
          payload = { hospitalization: dataForm.form, ins_id: insId };
        }

        if (this.fromRegulation) {
          if (!(await this.manageDocuments())) return false;

          action =
            "Hospitalization/Hospitalizations/SAVE_HOSPITALIZATION_AND_REGULATION";
          payload = await this.formatRegulationData();

          const queueId = [Number(this.hospitalizationRegulationData.fil_id)];

          const reason = this.hospitalizationRegulationData
            .fil_motivo_agendamento_fora_topo;

          const reasonVariables = {
            queueId,
            reason,
          };

          if (reason) {
            await this.$store.dispatch(
              "Hospitalization/Hospitalizations/SAVE_REGISTER_SCHEDULE_QUEUE_LOT_REASON",
              reasonVariables,
            );
          }
        }

        const hospitalization = await this.$store.dispatch(action, payload);

        this.$toaster.success("Operação realizada com sucesso!");

        if (this.fromRegulation) {
          try {
            const scheduleRegistrationData = await this.$store.dispatch(
              "Hospitalization/Queue/GET_PRINT_DATA_SCHEDULE_REGISTRATION",
              hospitalization.dados,
            );

            const html = ScheduleHospitalizationHTML(scheduleRegistrationData);
            this.$utils.print.printHtml(html);
          } catch (pErr) {
            this.$toaster.warning(
              "Não foi possível imprimir a ficha de internação",
            );
          }
        }

        this.$emit("close");
      } catch (pErr) {
        const notHasUnitHealthRecordNumberMessage =
          "O paciente selecionado não possui prontuário da unidade.";

        if (pErr.message.includes(notHasUnitHealthRecordNumberMessage)) {
          this.$toaster.warning(notHasUnitHealthRecordNumberMessage);
        } else {
          this.$toaster.error("Erro ao salvar a internação");
        }
      } finally {
        this.$loader.finish();
      }
    },

    async formatRegulationData() {
      const arrCompanionDocuments = [];
      this.form.companion.documents.forEach((document) => {
        arrCompanionDocuments.push({
          Documento: document.dva_nome,
          documento_id: document.dva_id_documentos,
          Número: document.dva_numero_documentos.replaceAll(" ", ""),
        });
      });

      const variables = {
        arrFormData: {
          arrDocumento: arrCompanionDocuments,
          int_data: this.$utils.date.BrazilianDateToDatabase(
            this.form.hospitalization.int_data,
          ),
          int_hora: this.form.hospitalization.int_hora,
          int_id: 0,
          int_id_cid10: Number(this.form.hospitalization.int_id_cid10),
          int_id_filas_internacao: Number(
            this.hospitalizationRegulationData.flt_id,
          ),
          int_id_funcionarios: this.form.hospitalization.int_id_funcionarios,
          int_id_funcionarios_responsavel: Number(
            this.form.hospitalization.int_id_funcionarios_responsavel,
          ),
          int_id_internacoes_caracteres: Number(
            this.form.hospitalization.int_id_internacoes_caracteres,
          ),
          int_id_internacoes_leito: Number(this.form.hospitalization.inl_id),
          int_id_internacoes_leito_tipos_leito_log: 0,
          int_id_internacoes_modalidade: Number(
            this.form.hospitalization.int_id_internacoes_modalidade,
          ),
          int_id_internacoes_procedimentos: Number(
            this.form.hospitalization.int_id_internacoes_procedimentos,
          ),
          int_id_internacoes_tipos_internacao: Number(
            this.form.hospitalization.int_id_internacoes_tipos_internacao,
          ),
          int_id_internacoes_tipos_leito: Number(
            this.form.hospitalization.itl_id,
          ),
          int_id_internacoes_tipos_responsavel: this.form.hospitalization
            .int_id_internacoes_tipos_responsavel,
          int_id_ocupacoes_cbo: this.form.hospitalization.int_id_ocupacoes_cbo,
          int_id_pacientes_internacao: this.patientInfo.pci_id,
          int_id_planos_saude: Number(
            this.form.hospitalization.int_id_planos_saude,
          ),
          int_numero: 0,
          int_previsao_alta: this.form.hospitalization.int_previsao_alta,
          int_responsavel: this.$utils.sanitize.removeExtraSpaces(
            this.form.companion.via_nome,
          ),
          int_ultima_situacao_internacao: Number(
            this.form.hospitalization.int_ultima_situacao_internacao,
          ),
          int_obs: this.form.hospitalization.int_obs,
          set_id: this.set_id,
          uns_id: this.uns_id,
        },

        arrFormDataHistoricoSituacaoLeito: [
          {
            ihs_observacao: this.form.hospitalization.int_obs,
          },
        ],

        idIternacoesSituacoesLeito: Number(
          this.form.hospitalization.int_ultima_situacao_internacao,
        ),
      };
      return variables;
    },

    async manageDocuments() {
      const currentCpf = this.patientInfo?.cpf;
      const newCpf = this.removeDashesAndDots(this.document.cpf);

      const currentCns = this.patientInfo?.crs_numero;
      const newCns = this.document.cns;

      if (currentCpf === newCpf && currentCns === newCns) return true;

      if (currentCpf !== newCpf) {
        const cpf = await this.manageCpf(this.patientInfo.pes_id);
        if (!cpf || cpf.ManageCpfSchedulingPatient.success === false) {
          return false;
        }
      }

      if (currentCns !== newCns) {
        const cns = await this.manageCns(this.patientInfo.pes_id);
        if (!cns || cns.ManageCnsSchedulingPatient.success === false) {
          return false;
        }
      }

      if (this.$refs.patient) {
        this.$refs.patient.fillPatientById(this.patientInfo.pes_id);
      }

      return true;
    },

    async manageCpf(pPesId) {
      let variables = {};
      const removeCpf = this.hasCpf && !this.document.cpf;

      try {
        if (removeCpf) {
          variables = {
            pes_id: pPesId,
            cpf: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cpf: {
              cpf_id: this.document.cpf_id || null,
              cpf_numero: this.document.cpf
                ? this.removeDashesAndDots(this.document.cpf)
                : "",
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CPF_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    async manageCns(pPesId) {
      let variables = {};
      const removeCns = this.hasCns && !this.document.cns;

      try {
        if (removeCns) {
          variables = {
            pes_id: pPesId,
            cns: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cns: {
              crs_id: this.document.crs_id || null,
              crs_numero: this.document.cns,
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CNS_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    selectingBedType(pBedType) {
      if (pBedType?.source?.itl_id) {
        this.form.hospitalization.itl_id = pBedType?.source?.itl_id || 0;
        this.blockTypeBed = this.fromRegulation;
      } else {
        if (!this.fromMapBed) {
          this.uns_id = null;
          this.set_id = null;
          this.lca_id = null;
        }
        this.form.hospitalization.itl_id = null;
      }
    },

    selectingHospitalizationBed(pBed) {
      if (pBed && pBed.source && pBed.source.inl_id) {
        this.form.hospitalization.inl_id =
          pBed.source && pBed.source.inl_id ? pBed.source.inl_id : null;

        this.lastStatusBed =
          pBed.source && pBed.source.inl_id_internacoes_situacoes_leito
            ? pBed.source.inl_id_internacoes_situacoes_leito
            : null;
      } else {
        this.$refs.bed.doSearch();
        this.bed = "";
        this.form.hospitalization.inl_id = null;
        this.lastStatusBed = null;
      }
    },

    async selectingPatient(pPatient) {
      const hasPerson = pPatient && pPatient.pes_id > 0;
      const patient = pPatient && pPatient.patient;
      const hasPatient = patient && patient.pac_id > 0;
      const patientId = hasPatient && patient.pac_id;

      const patientUnitHealthNumber = patient?.record_numbers?.rows.find(
        (recordNumber) =>
          recordNumber.ppr_id_unidades_saude === this.unitHealthId,
      );

      if (hasPerson) {
        this.auxInfo.patient.pes_mae = pPatient?.pes_mae || null;
        this.auxInfo.patient.pes_pai = pPatient.pes_pai || null;
        this.form.patient.pac_id = patient?.pac_id || null;

        const gender =
          pPatient && pPatient.gender
            ? pPatient.gender.sex_sigla
            : pPatient.sex_sigla
            ? pPatient.sex_sigla
            : null;

        this.patientInfo = {
          pac_id: this.form.patient.pac_id,
          pes_id: pPatient.pes_id,
          pci_id: patient.patient_hospitalization?.pci_id,
          pac_nome: pPatient.pes_nome,
          sex_sigla: gender,
          pes_nascimento: pPatient?.pes_nascimento || null,
          pes_mae: pPatient?.pes_mae || null,
          crs_numero: pPatient?.cns?.crs_numero || null,
          cpf: pPatient?.cpf?.cpf_numero || null,
          prontuario_unico: patient?.pac_prontuario_unico || null,
        };

        this.hasCpf = this.patientInfo.cpf || false;
        this.document.cpf = this.patientInfo.cpf;
        this.document.cpf_id = pPatient?.cpf?.cpf_id || null;

        this.hasCns = this.patientInfo.crs_numero || false;
        this.document.cns = this.patientInfo.crs_numero;
        this.document.crs_id = pPatient?.cns?.crs_id || null;

        if (
          this.patientCurrentGender &&
          this.patientCurrentGender !== this.patientInfo.sex_sigla
        ) {
          this.cleanProcedure();
        }
        this.patientCurrentGender = this.patientInfo.sex_sigla;

        if (
          hasPatient &&
          !this.form.hospitalization.int_id &&
          !this.disabledPatient &&
          (await this.searchPatientHospitalized({ patientId }))
        ) {
          return AlertError("Esse paciente encontra-se internado!");
        }

        const unitHealthRecordNumberRequired = this
          .hospitalizationUnitHealthRecordNumberPreference;

        if (
          !this.fromRegulation &&
          unitHealthRecordNumberRequired &&
          !patientUnitHealthNumber
        ) {
          this.$emit("blockedPatient", true);
          return this.$toaster.warning(
            "O paciente selecionado não possui prontuário da unidade.",
          );
        }
      } else {
        this.patientInfo = {};
        this.suggestPatient = null;
        this.form.patient.pac_id = null;
        this.auxInfo.patient.pes_pai = null;
        this.auxInfo.patient.pes_mae = null;
        this.cleanDocumentInfo();
        this.form.companion.documents = [];
        this.form.companion.via_nome = null;
        this.form.hospitalization.int_id_internacoes_tipos_responsavel = null;
        this.patientIsHospitalized = false;
        this.document = {
          cpf: null,
          cns: null,
        };
        this.hasCpf = false;
        this.hasCns = false;
      }
    },

    blockedPatient(event) {
      this.$emit("blockedPatient", event);
    },

    async searchPatientHospitalized(pValue) {
      if (pValue) {
        const inPatient = await SearchPatientHospitalized(pValue);
        this.patientIsHospitalized = inPatient;
        return inPatient;
      }
      return false;
    },

    selectingResponsibleEmployee(pEmployee) {
      const hasSuggestion =
        pEmployee &&
        pEmployee.source &&
        Object.keys(pEmployee.source).length > 0;
      if (hasSuggestion) {
        const data = pEmployee.source.employeeRelationship;

        this.form.hospitalization.int_id_funcionarios_responsavel =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      }
    },

    selectingRequestEmployee(pEmployee) {
      const hasSuggestion =
        pEmployee &&
        pEmployee.source &&
        Object.keys(pEmployee.source).length > 0;
      if (hasSuggestion) {
        const data = pEmployee.source.employeeRelationship;

        this.form.hospitalization.int_id_funcionarios =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      }
    },

    selectingCidCode(pCid) {
      const source = pCid && pCid.source;
      this.form.hospitalization.int_id_cid10 = source.cid_id
        ? source.cid_id
        : null;
      this.mainCid = source.cid_nome ? source.cid_nome : null;
      this.cid_codigo = source.cid_codigo ? source.cid_codigo : null;
    },

    selectingCbo(pValue) {
      if (pValue.source) {
        this.form.hospitalization.int_id_ocupacoes_cbo = pValue.source.ocp_id;
        this.cboCode = pValue.source.ocp_codigo;
        this.cboName =
          pValue.source.ocp_codigo + " - " + pValue.source.ocp_nome;
      } else {
        this.form.hospitalization.int_id_ocupacoes_cbo = null;
        this.cboCode = "";
        this.cboName = "";
      }
    },

    selectingProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.hospitalization.int_id_internacoes_procedimentos = source
        ? source.inp_id
        : null;

      this.inp_novo_codigo_procedimento = source
        ? source.inp_novo_codigo_procedimento
        : null;

      this.procedureHasCid = source ? source.total_procedimento_cid : 0;

      if (source && source.procedure) {
        this.procedure = {
          vl_idade_minima: source.procedure.VL_IDADE_MINIMA,
          vl_idade_maxima: source.procedure.VL_IDADE_MAXIMA,
        };
      }

      if (source && source.cns_required) {
        this.cns_required = source.cns_required;
      }
    },

    setDocumentType(pValue) {
      if (pValue && pValue.length > 0) {
        this.documentTypeSelected = {
          dva_id_documentos: pValue[0].value,
          dva_nome: pValue[0].text,
        };
      }
      this.$refs.documentInput.clearInput();
    },

    disableOrEnableDocumentAdd(validation) {
      if (!validation) {
        this.validateAddBtn = false;
        return;
      }

      if (this.dva_numero_documentos !== "") {
        const isValidRg =
          this.documentType === 28 && this.dva_numero_documentos.length === 12;
        const isValidCnh =
          this.documentType === 42 && this.dva_numero_documentos.length === 12;
        const isValidCpf =
          this.documentType === 31 && this.dva_numero_documentos.length === 14;
        const isValidSus =
          this.documentType === 41 && this.dva_numero_documentos.length === 15;

        if (isValidRg || isValidCnh || isValidCpf || isValidSus) {
          this.validateAddBtn = true;
        } else {
          this.validateAddBtn = false;
        }
      } else {
        this.validateAddBtn = false;
      }
    },

    documentListRemoveItem() {
      if (!this.selectedLineDocument) {
        return;
      }

      const index = this.form.companion.documents.findIndex((item) => {
        return (
          item.dva_id_documentos === this.selectedLineDocument.dva_id_documentos
        );
      });

      if (index > -1) {
        this.form.companion.documents.splice(index, 1);
      }

      this.cleanDocumentInfo();

      this.selectedLineDocument = null;
    },

    documentListAddItem() {
      const isValid = this.$refs.documentInput.validate();

      if (!isValid) {
        return;
      }

      const item = {
        dva_nome: this.documentTypeSelected.dva_nome,
        dva_id_documentos: this.documentTypeSelected.dva_id_documentos,
        dva_numero_documentos: this.dva_numero_documentos,
      };

      const arr_id = [];
      this.form.companion.documents.forEach((i) => {
        arr_id.push(i.dva_id_documentos);
      });

      if (!arr_id.includes(item.dva_id_documentos)) {
        // Create
        this.form.companion.documents.push(item);
      } else {
        // Update
        this.form.companion.documents.forEach((i, index) => {
          if (this.documentType === i.dva_id_documentos) {
            this.form.companion.documents[index].dva_numero_documentos =
              item.dva_numero_documentos;
          }
        });
      }
      this.cleanDocumentInfo();

      this.selectedLineDocument = null;
    },

    documentListUpdateItem() {
      if (!this.selectedLineDocument) {
        return;
      }

      const index = this.form.companion.documents.findIndex((item) => {
        return (
          item.dva_id_documentos === this.selectedLineDocument.dva_id_documentos
        );
      });

      if (index > -1) {
        this.documentTypeSelected = {
          dva_id_documentos: this.form.companion.documents[index]
            .dva_id_documentos,
          dva_nome: this.form.companion.documents[index].dva_nome,
        };
        this.documentType = this.form.companion.documents[
          index
        ].dva_id_documentos;
        this.dva_numero_documentos = this.form.companion.documents[
          index
        ].dva_numero_documentos;
      }
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;

      this.$emit("disabledByModalComponents", this.modalConfirmFieldClearing);
    },

    getCleanFormMethod() {
      this.cleanFormInput();
      this.modalConfirmFieldClearing = false;
      this.$emit("disabledByModalComponents", this.modalConfirmFieldClearing);
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
      this.$emit("disabledByModalComponents", this.modalConfirmFieldClearing);
    },

    cleanForm() {
      if (!this.fromRegulation) {
        this.form.patient.pac_id = null;
        this.isPatientIndigent = false;
        this.$emit("blockedPatient", false);
        this.$refs.patient.cleanPatientInfo();
      }

      const ignoreKeysMapBed = [
        "int_ultima_situacao_internacao",
        "ids_tipos_do_leito",
        "inl_id",
      ];

      const ignoreKeyRegulation = ["ids_tipos_do_leito"];

      for (var key in this.form.hospitalization) {
        if (
          (this.fromMapBed && ignoreKeysMapBed.includes(key)) ||
          (this.fromRegulation && ignoreKeyRegulation.includes(key))
        ) {
          continue;
        }
        this.form.hospitalization[key] = null;
      }
    },

    cleanPerson() {
      this.patientInfo = {};
      this.form.patient.pac_id = null;
      this.suggestPatient = null;
      this.patientIsHospitalized = false;
      this.disabledPatient = false;
    },

    cleanIndigent() {
      this.form.indigent.ini_descricao = null;
      this.form.indigent.ini_id_sexos = "";
      this.indigentAge = null;
      this.form.indigent.ini_data_aproximada = null;
    },

    cleanInput() {
      if (!this.fromSolicitation) {
        this.cleanIndigent();
      }

      if (!this.fromMapBed) {
        this.cleanBedInput();
      }

      this.cleanInputCommon();
    },

    cleanInputCommon() {
      this.form.hospitalization.int_data = moment().format("DD/MM/YYYY");
      this.form.hospitalization.int_hora = moment().format("HH:mm");
      this.mainCid = null;

      if (!this.fromRegulation) {
        this.selectedTypeBed = null;
      }
      this.requesterEmployee = null;
      this.responsibleEmployee = null;
      this.suggestHospitalizationProcedures = null;
      this.documentTypeSelected = null;
      this.cboCode = null;
      this.cboName = null;
      this.documentType = null;

      if (!this.fromRegulation) {
        this.document = {
          cpf: null,
          cns: null,
        };
        this.auxInfo.patient.pes_mae = null;
        this.auxInfo.patient.pes_pai = null;
      }

      this.auxInfo.patient.disabledCompanionName = true;
      this.showModalHistoryPatient = false;

      this.$refs.textArea.resetCharacter();

      this.$refs.bed.cleanValidate();

      if (this.isPatientIndigent) {
        this.$refs.age.cleanValidate();
        this.$refs.description.cleanValidate();
      } else {
        if (this.$refs.patient) {
          this.$refs.patient.cleanValidate();
        }
      }

      this.$refs.typeBed.cleanValidate();
      this.$refs.responsible.cleanValidate();
      this.$refs.procedures.cleanValidate();
      this.$refs.requester.cleanValidate();
      this.$refs.mainCid.cleanValidate();
      this.$refs.inputDateDischarge.cleanValidate();
      this.$refs.inputDateEntry.cleanValidate();
      this.$refs.unitHealth.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.locale.cleanValidate();
      this.$refs.plan.cleanValidate();
      this.$refs.situationBed.cleanValidate();
      this.$refs.cbo.cleanValidate();
      this.$refs.character.cleanValidate();
      this.$refs.modality.cleanValidate();

      this.$refs.validator.cleanValidate();
    },

    cleanFormAllInput() {
      this.cleanPerson();
      this.cleanIndigent();
      this.cleanInputCommon();
      this.cleanBedInput();
    },

    cleanBedInput() {
      this.uns_id = null;
      this.set_id = null;
      this.lca_id = null;
      this.bed = null;
    },

    cleanFormInput() {
      this.cleanForm();
      this.cleanInput();
    },

    cleanDocumentInfo() {
      this.dva_numero_documentos = "";
      this.documentType = "";
      this.validateAddBtn = false;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      this.$refs.documentInput.clearInput();
    },

    cleanProcedureRelatedInfo() {
      this.form.hospitalization.int_id_cid10 = null;
      this.mainCid = "";
      this.$refs.mainCid.cleanValidate();

      this.form.hospitalization.int_id_ocupacoes_cbo = null;
      this.cboCode = "";
      this.cboName = "";
      this.$refs.cbo.cleanValidate();

      this.requesterEmployee = "";
      this.form.hospitalization.int_id_funcionarios = null;
      this.$refs.requester.cleanValidate();
    },

    cleanProcedure(pValue) {
      this.form.hospitalization.int_id_internacoes_procedimentos = null;
      this.suggestHospitalizationProcedures = null;
      this.$refs.procedures.cleanValidate();
      this.fillProcedureCidQueueRegulationData(pValue);
    },

    async fillProcedureCidQueueRegulationData(pValue) {
      const hasProcedureQueue = this.hospitalizationRegulationData
        ?.inp_novo_nome_procedimento;

      if (pValue && hasProcedureQueue) {
        try {
          this.$loader.start();

          const variables = {
            inp_novo_nome_procedimento: this.hospitalizationRegulationData
              .inp_novo_nome_procedimento,
            inp_novo_codigo_procedimento: null,
            inp_sexo: this.gender,
            inp_id_planos_saude: pValue[0].value,
            uns_id: this.uns_id,
            cid_codigo: null,
            pes_nascimento: this.patientBirthDate,
          };

          const procedureQueue = await SearchHospitalizationProcedure({
            variables,
          });

          if (procedureQueue?.count === 0) {
            this.$toaster.info(
              `Selecione outro convênio ou procedimento que sejam compatíveis.`,
              `O convênio não é compatível com o Procedimento e CID da regulação`,
            );
            return;
          }

          const procedure = mountProcedureInfo(procedureQueue.rows[0]);

          this.suggestHospitalizationProcedures = this.hospitalizationRegulationData.inp_novo_nome_procedimento;

          await this.$refs.procedures.forceSelection(procedure);

          this.mainCid = this.hospitalizationRegulationData.cid_nome;

          const cid = {
            cid_codigo: this.hospitalizationRegulationData.cid_codigo,
            cid_nome: this.hospitalizationRegulationData.cid_nome,
          };

          await this.$refs.mainCid.forceSelection(cid);

          this.form.hospitalization.int_id_cid10 = this.hospitalizationRegulationData.fnp_id_cid10_1;
        } finally {
          this.$loader.finish();
        }
      }
    },

    patientRecord() {
      const patientId = this.patientInfo.pes_id;

      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const validateIfExistFromCreateHospitalization =
        this.$route.name === "hospitalization.hospitalizations.search.details";

      const validateIfExistFromEditHospitalization =
        this.$route.name === "hospitalization.hospitalizations.edit-details";

      const validateIfExistFromHospitalizationMenu =
        this.$route.name === "hospitalization.hospitalizations.details";

      const validateIfExistFromMapBed =
        this.$route.name === "hospitalization.bed.search.new-hospitalization";

      if (validateIfExistFromCreateHospitalization) {
        this.$router.push({
          name: "hospitalization.hospitalizations.search.details.edit-patient",
        });
      } else if (validateIfExistFromEditHospitalization) {
        this.$router.push({
          name: "hospitalization.hospitalizations.edit-details.edit-patient",
        });
      } else if (validateIfExistFromHospitalizationMenu) {
        this.$router.push({
          name: "hospitalization.hospitalizations.details.edit-patient",
        });
      } else if (validateIfExistFromMapBed) {
        this.$router.push({
          name: "hospitalization.bed.search.hospitalization.edit-patient",
          params: {
            form: this.$route.params.form,
            fromMapBed: this.$route.params.fromMapBed,
            operation: this.$route.params.operation,
          },
        });
      }
    },

    openPersonDetails() {
      const isHospitalizationSearchDetails =
        this.$route.name === "hospitalization.hospitalizations.search.details";

      const isHospitalizationSearchBed =
        this.$route.name === "hospitalization.bed.search.new-hospitalization";

      if (isHospitalizationSearchDetails) {
        this.$router.push({
          name: "hospitalization.hospitalizations.search.details.new-patient",
        });
      } else if (isHospitalizationSearchBed) {
        this.$router.push({
          name: "hospitalization.bed.search.hospitalization.new-patient",
          params: {
            form: this.$route.params.form,
            fromMapBed: this.$route.params.fromMapBed,
            operation: this.$route.params.operation,
          },
        });
      } else {
        this.$router.push({
          name: "hospitalization.hospitalizations.details.new-patient",
        });
      }
    },

    showModalHistory() {
      this.showModalHistoryPatient = true;
      this.$emit("disabledByModalComponents", this.showModalHistoryPatient);
    },

    closeModalHistoryPatient() {
      this.showModalHistoryPatient = false;
      this.$emit("disabledByModalComponents", this.showModalHistoryPatient);
    },

    validateHospitalizationExists(pValue, pErrors) {
      if (this.patientIsHospitalized) {
        pErrors.push("Esse paciente encontra-se internado!");
        return false;
      }
    },

    verifyDateEntry(pValue, pErrors) {
      const dateEntry = this.form.hospitalization.int_data;
      const hourEntry = this.form.hospitalization.int_hora;
      const dateConverted = moment(
        dateEntry + " " + hourEntry,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Data de Entrada não pode ser maior que a data atual");
        return false;
      }
      return true;
    },

    verifyHourEntry(pValue, pErrors) {
      const dateEntry = this.form.hospitalization.int_data;
      const hourEntry = this.form.hospitalization.int_hora;
      const dateConverted = moment(
        dateEntry + " " + hourEntry,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Hora de Entrada não pode ser maior que a hora atual");
        return false;
      }
      return true;
    },

    verifyDateDischarge(pValue, pErrors) {
      const dateEntry = moment(
        this.form.hospitalization.int_data,
        "DD-MM-YYYY",
      );
      const dateDischarge = moment(
        this.form.hospitalization.int_previsao_alta,
        "DD-MM-YYYY",
      );

      const dateDischargeIsNotValid = dateDischarge.isBefore(dateEntry);

      if (dateDischargeIsNotValid) {
        pErrors.push(
          "Data Previsão de alta não pode ser menor que a Data de Entrada",
        );
        return false;
      }

      return true;
    },

    resetHospitalizationInfo() {
      const validateIfExistHospitalization = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/hospitalizations/details",
        this.$route.meta.breadcrumb,
      );

      const validateIfExistEditHospitalizations = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/hospitalizations/edit-details",
        this.$route.meta.breadcrumb,
      );

      const validateIfExistHospitalizationBed = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/bed/search",
        this.$route.meta.breadcrumb,
      );

      if (
        !validateIfExistHospitalization &&
        !validateIfExistHospitalizationBed &&
        !validateIfExistEditHospitalizations
      ) {
        this.$store.commit(
          "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_INFO",
        );
      }

      const validateIfExistSolicitation = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/hospitalization-solicitation/register",
        this.$route.meta.breadcrumb,
      );

      if (!validateIfExistSolicitation) {
        this.$store.commit(
          "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_SOLICITATION_INFO",
        );
      }

      const validateIfExistRegulation = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/queue/schedule",
        this.$route.meta.breadcrumb,
      );

      if (!validateIfExistRegulation) {
        this.$store.commit(
          "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        );
      }

      this.cleanFormAllInput();
    },

    changePatientType() {
      this.isPatientIndigent = !this.isPatientIndigent;
      this.$emit("blockedPatient", false);
    },

    validateNumberCharacters(pValue, pErrors) {
      if (pValue && pValue.length > 3) {
        pErrors.push("Idade Aproximada deve ter no máximo 3 dígitos");
        return false;
      }
      return true;
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
  },
};
</script>
