import PharmacyCentralBatchTraceability from "$pharmacy/submodules/report/view/central/batch-traceability/PharmacyCentralBatchTraceabilityReport";
import PharmacyCentralAverageConsumptionReport from "$pharmacy/submodules/report/view/central/average-consumption/PharmacyCentralAverageConsumptionReport";
import PharmacyCentralTotalConsumptionReport from "$pharmacy/submodules/report/view/central/total-consumption/PharmacyCentralTotalConsumptionReport";
import PharmacyCentralDiscardReport from "$pharmacy/submodules/report/view/central/discard/PharmacyCentralDiscardReport";
import PharmacyCentralEndForecastReport from "$pharmacy/submodules/report/view/central/end-forecast/PharmacyCentralEndForecastReport";
import PharmacyCentralEntryReport from "$pharmacy/submodules/report/view/central/entry/PharmacyCentralEntryReport";
import PharmacyCentralEntryVsOutputReport from "$pharmacy/submodules/report/view/central/entry-vs-output/PharmacyCentralEntryVsOutputReport";
import PharmacyCentralOutputReport from "$pharmacy/submodules/report/view/central/output/PharmacyCentralOutputReport";
import PharmacyCentralReversalReport from "$pharmacy/submodules/report/view/central/reversal/PharmacyCentralReversalReport";
import PharmacyCentralRequestsReport from "$pharmacy/submodules/report/view/central/requests/PharmacyCentralRequestsReport";
import PharmacyCentralRequestsTransferReport from "$pharmacy/submodules/report/view/central/requests-transfer/PharmacyCentralRequestsTransferReport";
// import PharmacyCentralStockProductReport from "$pharmacy/submodules/report/view/central/stock-product/PharmacyCentralStockProductReport";
import PharmacyCentralNewStockProductReport from "$pharmacy/submodules/report/view/central/new-stock-product/PharmacyCentralNewStockProductReport";
import PharmacyCentralTransferReport from "$pharmacy/submodules/report/view/central/transfer/PharmacyCentralTransferReport";
import PharmacyCentralValidityReport from "$pharmacy/submodules/report/view/central/validity/PharmacyCentralValidityReport";
import PharmacySatelliteConsumptionReport from "$pharmacy/submodules/report/view/satellite/consumption/PharmacySatelliteConsumptionReport";
import PharmacySatelliteDemandProduct from "$pharmacy/submodules/report/view/satellite/demand-product/PharmacySatelliteDemandProductReport";
import PharmacySatelliteEntryReport from "$pharmacy/submodules/report/view/satellite/entry/PharmacySatelliteEntryReport";
import PharmacySatelliteEntryVsOutputReport from "$pharmacy/submodules/report/view/satellite/entry-vs-output/PharmacySatelliteEntryVsOutputReport";
import PharmacySatelliteOutputReport from "$pharmacy/submodules/report/view/satellite/output/PharmacySatelliteOutputReport";
import PharmacySatelliteExpectedReturnReport from "$pharmacy/submodules/report/view/satellite/expected-return/PharmacySatelliteExpectedReturnReport";
import PharmacySatelliteReversalReport from "$pharmacy/submodules/report/view/satellite/reversal/PharmacySatelliteReversalReport";
import PharmacySatelliteProductForPatientsReport from "$pharmacy/submodules/report/view/satellite/product-for-patients/PharmacySatelliteProductForPatientsReport";
import PharmacySatelliteRequestsReport from "$pharmacy/submodules/report/view/satellite/requests/PharmacySatelliteRequestsReport";
import PharmacySatelliteStockProductReport from "$pharmacy/submodules/report/view/satellite/stock-product/PharmacySatelliteStockProductReport";
import PharmacySatelliteNewStockProductReport from "$pharmacy/submodules/report/view/satellite/new-stock-product/PharmacySatelliteNewStockProductReport";
import PharmacySatelliteServiceHistoryReport from "$pharmacy/submodules/report/view/satellite/service-history/PharmacySatelliteServiceHistoryReport.vue";
import PharmacySatelliteProductValidityReport from "$pharmacy/submodules/report/view/satellite/product-validity/PharmacySatelliteProductValidityReport";

export const LIST_REPORT_PHARMACY_CENTRAL = [
  {
    title: "Entrada de Produtos",
    blue: true,
    id: 1,
    value: PharmacyCentralEntryReport,
  },
  {
    title: "Saída de Produtos",
    blue: true,
    id: 2,
    value: PharmacyCentralOutputReport,
  },
  // {
  //   title: "Estoques de Produtos",
  //   blue: true,
  //   id: 3,
  //   value: PharmacyCentralStockProductReport,
  // },
  {
    title: "Estoques de Produtos",
    blue: true,
    id: 27,
    value: PharmacyCentralNewStockProductReport,
  },
  {
    title: "Entradas X Saídas",
    blue: true,
    id: 4,
    value: PharmacyCentralEntryVsOutputReport,
  },
  {
    title: "Descartes de Produtos",
    blue: true,
    id: 5,
    value: PharmacyCentralDiscardReport,
  },
  {
    title: "Estornos de Produtos",
    blue: true,
    id: 6,
    value: PharmacyCentralReversalReport,
  },
  {
    title: "Consumo Médio",
    blue: true,
    id: 7,
    value: PharmacyCentralAverageConsumptionReport,
  },
  {
    title: "Consumo Total",
    blue: true,
    id: 8,
    value: PharmacyCentralTotalConsumptionReport,
  },
  {
    title: "Rastreabilidade de Lote",
    blue: true,
    id: 9,
    value: PharmacyCentralBatchTraceability,
  },
  {
    title: "Validade de Produtos",
    blue: true,
    id: 10,
    value: PharmacyCentralValidityReport,
  },
  {
    title: "Previsão de Término",
    blue: true,
    id: 11,
    value: PharmacyCentralEndForecastReport,
  },
  {
    title: "Transferências",
    blue: true,
    id: 12,
    value: PharmacyCentralTransferReport,
  },
  {
    title: "Solicitações de Transferências",
    blue: true,
    id: 13,
    value: PharmacyCentralRequestsTransferReport,
  },
  {
    title: "Solicitações",
    blue: true,
    id: 14,
    value: PharmacyCentralRequestsReport,
  },
];

export const LIST_REPORT_PHARMACY_SATELLITE = [
  {
    title: "Entrada de Produtos",
    blue: true,
    id: 15,
    value: PharmacySatelliteEntryReport,
  },
  {
    title: "Saída de Produtos",
    blue: true,
    id: 16,
    value: PharmacySatelliteOutputReport,
  },
  {
    title: "Estoques de Produtos",
    blue: true,
    id: 17,
    value: PharmacySatelliteStockProductReport,
  },
  {
    title: "Novo Estoques de Produtos",
    blue: true,
    id: 28,
    value: PharmacySatelliteNewStockProductReport,
  },
  {
    title: "Entradas X Saídas",
    blue: true,
    id: 18,
    value: PharmacySatelliteEntryVsOutputReport,
  },
  {
    title: "Produtos por Paciente",
    blue: true,
    id: 19,
    value: PharmacySatelliteProductForPatientsReport,
  },
  {
    title: "Demanda de Produtos",
    blue: true,
    id: 20,
    value: PharmacySatelliteDemandProduct,
  },
  {
    title: "Retorno Previsto",
    blue: true,
    id: 21,
    value: PharmacySatelliteExpectedReturnReport,
  },
  {
    title: "Histórico de Atendimento",
    blue: true,
    id: 22,
    value: PharmacySatelliteServiceHistoryReport,
  },
  {
    title: "Estorno de Produtos",
    blue: true,
    id: 23,
    value: PharmacySatelliteReversalReport,
  },
  {
    title: "Validade de Produtos",
    blue: true,
    id: 24,
    value: PharmacySatelliteProductValidityReport,
  },
  {
    title: "Solicitações",
    blue: true,
    id: 25,

    value: PharmacySatelliteRequestsReport,
  },
  {
    title: "Consumo",
    blue: true,
    id: 26,
    value: PharmacySatelliteConsumptionReport,
  },
];
