<template>
  <div class="billing-bpa-attendances-search">
    <RgSearch
      ref="rgSearch"
      v-model="mutableAppointmentsList"
      :result-title="resultTitle"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="clearForm"
      :searchAreaHeight="10"
      :item-height="34.5"
      :maxRegister="40"
      :showFooter="false"
      :disabled="disabledByModal"
      :class="{ disable: disabledByModal }"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="billing-bpa-attendances-search-filter">
        <div class="grid">
          <RgSelectUnithealth
            v-model="form.unitHealthId"
            :rules="{ required: true }"
            disabled
            searchUnitById
            :unitHealthId="form.unitHealthId"
          />

          <RgSelectSectorLegacy
            v-model="form.sectorId"
            :rules="{ required: true }"
            :unitHealthId="form.unitHealthId"
            :submoduleId="subModuleId"
            @change="selectedSector"
          />

          <RgSelectOccupationsAttendantsBySector
            v-model="form.occupationId"
            :rules="{ required: true }"
            :sectorId="Number(form.sectorId)"
            :disabled="!hasSector"
            @change="selectedOccupation"
          />

          <RgSelectEmployeeByUnitSectorOccupation
            v-model="form.employeeId"
            :rules="{ required: true }"
            :unitHealthId="Number(form.unitHealthId)"
            :sectorId="Number(form.sectorId)"
            :occupationId="Number(form.occupationId)"
            :disabled="!hasSector || !hasOccupation"
            @change="selectedEmployee"
          />

          <RgInputDate
            v-model="form.serviceDate"
            :rules="{ required: true }"
            :disabled="!hasSector || !hasOccupation || !hasEmployee"
            :class="{ disable: !hasSector || !hasOccupation || !hasEmployee }"
            :datesAvailable="listDays"
            :disableNoAvailable="!hasListDays"
            label="Data do Atendimento"
          />
        </div>
      </div>

      <section class="billing-bpa-attendances-search-list">
        <div class="content-grid-appointment">
          <div class="table-one">
            <SmartTable
              ref="smartTableAppointment"
              name="AppointmentRegister"
              :columns="APPOINTMENT_COLUMNS"
              :body="mutableAppointmentsList"
              :total="Number(totalAppointments)"
              :initial-columns="5"
              :showPagination="false"
              toggle-selected
              removeBtnColumns
              :disabled="disabledByModal"
              class="smart-table-attendances-list smart-one"
              @getLine="selectAppointment"
            >
              <div slot="legends" class="caption">
                <legend class="legend">Pacientes Atendidos</legend>
              </div>
              <div slot="top-buttons" class="top-buttons">
                <RgAddButton
                  title="Importar Procedimento"
                  :disabled="!hasAppointmentSelected || disabledByModal"
                  @click="addProcedure"
                />
                <RgPrinterButton
                  title="Imprimir Ficha de Controle Ambulatorial"
                  :disabled="!hasAppointment || disabledByModal"
                  @click="printOutpatientControlSheet"
                />
              </div>
            </SmartTable>
          </div>
          <span
            v-if="Number(totalAppointments) > 0"
            class="total-bpa-Attendances"
          >
            Registros encontrados: {{ totalAppointments }}
          </span>

          <div class="separator" />

          <div class="table-two">
            <SmartTable
              ref="smartTableProcedure"
              name="ProcedureRegister"
              :columns="PROCEDURE_COLUMNS"
              :body="mutableProceduresList"
              :total="Number(totalProcedures)"
              :initial-columns="4"
              :item-per-page="pagination.limit"
              :max-register="15"
              toggle-selected
              removeBtnColumns
              :withoutResultText="messagewithoutResult"
              :disabled="disabledByModal"
              class="smart-table-procedures-list smart-two"
              @getLine="selectProcedure"
              @pagination="getPagination"
            >
              <div slot="legends" class="caption">
                <legend class="legend">Procedimentos Realizados</legend>
              </div>
              <div slot="top-buttons" class="top-buttons">
                <RgEditButton
                  title="Editar Procedimento"
                  :disabled="
                    !hasProcedureSelected ||
                    isStandardProcedure ||
                    disabledByModal
                  "
                  @click="openModalClassificationProcedure"
                />
                <RgLessButton
                  title="Excluir Procedimento"
                  :disabled="!hasProcedureSelected || disabledByModal"
                  @click="openModalConfirmDeletion"
                />
              </div>
            </SmartTable>
          </div>
        </div>
      </section>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmDeletion"
      @reSearch="searchProcedures"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalClassificationProcedure
      id="modal-confirm-deletion"
      :show="modalClassificationProcedure"
      :serviceInfo="appointmentSelected"
      :procedureInfo="procedureSelected"
      isEditing
      @reSearch="searchProcedures"
      @close="closeModalClassificationProcedure"
    />
  </div>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import {
  RgInputDate,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  RgPrinterButton,
  ModalConfirmDeletion,
  IconDanger,
} from "~tokio/primitive";

import RgSelectSectorLegacy from "~tokio/primitive/select/rg-select-sector-legacy/RgSelectSectorLegacy";
import RgSelectUnithealth from "~tokio/primitive/select/rg-select-unithealth/RgSelectUnithealth";
import RgSelectOccupationsAttendantsBySector from "$billing/submodules/bpa/attendances/components/select/rg-select-occupations-attendants-by-sector/RgSelectOccupationsAttendantsBySector";
import RgSelectEmployeeByUnitSectorOccupation from "$billing/submodules/bpa/attendances/components/select/rg-select-employee-by-unit-sector-occupation/RgSelectEmployeeByUnitSectorOccupation";
import ModalClassificationProcedure from "$billing/submodules/bpa/attendances/components/modal/modal-classification-procedure/ModalClassificationProcedure";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import OutpatientControlSheetHTML from "$billing/submodules/bpa/attendances/html/OutpatientControlSheetHTML";
export default {
  name: "BillingBpaAttendancesSearch",
  components: {
    RgSearch,
    SmartTable,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    RgPrinterButton,
    RgInputDate,
    RgSelectSectorLegacy,
    RgSelectUnithealth,
    RgSelectOccupationsAttendantsBySector,
    RgSelectEmployeeByUnitSectorOccupation,
    ModalConfirmDeletion,
    ModalClassificationProcedure,
    IconDanger,
  },
  data() {
    return {
      form: {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sectorId: null,
        occupationId: null,
        employeeId: null,
        serviceDate: null,
      },
      print: {
        unitHealthName: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        sectorName: null,
        occupationName: null,
        employeeName: null,
        serviceDate: null,
      },
      mutableAppointmentsList: [],
      mutableProceduresList: [],
      appointmentSelected: {},
      procedureSelected: {},
      bondId: null,
      sectorBondId: null,
      subModuleId: 51,
      listDays: null,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
      totalAppointments: 0,
      totalProcedures: 0,
      modalConfirmDeletion: false,
      modalClassificationProcedure: false,
      messagewithoutResult: "Selecione um dos pacientes atendidos",
      // Array de procedimentos automáticos (vindos do PEP)
      arrProcedimentosAutomaticos: [],
    };
  },
  computed: {
    ...mapGetters({
      clientName: "Login/GET_CLIENT_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
      userName: "Login/GET_USER_NAME",
    }),
    disabledByModal() {
      return this.modalConfirmDeletion || this.modalClassificationProcedure;
    },
    resultTitle() {
      return "Procedimentos de Atendimento";
    },
    hasSector() {
      return this.form.sectorId && this.form.sectorId > 0;
    },
    hasOccupation() {
      return this.form.occupationId && this.form.occupationId > 0;
    },
    hasEmployee() {
      return this.form.employeeId && this.form.employeeId > 0;
    },
    hasListDays() {
      return this.listDays && this.listDays.length > 0;
    },
    hasAppointment() {
      return (
        this.mutableAppointmentsList && this.mutableAppointmentsList.length > 0
      );
    },
    hasAppointmentSelected() {
      return (
        this.appointmentSelected &&
        Object.keys(this.appointmentSelected).length > 0
      );
    },
    hasProcedureSelected() {
      return (
        this.procedureSelected && Object.keys(this.procedureSelected).length > 0
      );
    },
    isStandardProcedure() {
      return this.arrProcedimentosAutomaticos.includes(
        this.procedureSelected.sus_pa_id_dv,
      );
    },

    propsModalConfirmDeletion() {
      const show = this.modalConfirmDeletion;
      const noReason = true;
      const title = "Excluir Procedimento de Consulta";
      const message =
        "O registro selecionado será removido da relação de procedimentos importados";
      const btnRemoveTitle = "Excluir";
      const bodyText = `${this.procedureSelected.sus_pa_id_dv} - ${this.procedureSelected.sus_pa_dc}`;
      const confirm = () => this.removeProcedure(this.procedureSelected.ppd_id);

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
  },
  watch: {
    "form.sectorId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.occupationId = null;
        this.form.employeeId = null;
        this.form.serviceDate = null;
        this.bondId = null;
      }
    },
    "form.occupationId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.employeeId = null;
        this.form.serviceDate = null;
        this.bondId = null;
      }
    },
    "form.employeeId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.serviceDate = null;
        this.bondId = null;
      }
    },
    bondId(pValue) {
      if (pValue && pValue > 0) {
        this.getListDaysServiceByProfessional();
      }
    },
  },
  mounted() {
    this.loadFilterAppointments();
    this.getStandardProcedures();
  },
  created() {
    this.APPOINTMENT_COLUMNS = [
      {
        name: "Profissional",
        key: "nomeFuncionario",
        active: true,
        align: "left",
      },
      { name: "Ocupação", key: "ocp_nome", active: true, align: "left" },
      { name: "Paciente", key: "pes_nome", active: true, align: "left" },
      {
        name: "CNS do Paciente",
        key: "crs_numero",
        active: true,
        align: "left",
      },
      {
        name: "Atendimento",
        key: "dataHoraAtendimento",
        active: true,
        align: "left",
      },
    ];

    this.PROCEDURE_COLUMNS = [
      {
        name: "Profissional",
        key: "nomeFuncionario",
        active: true,
        align: "left",
      },
      { name: "Ocupação", key: "ocp_nome", active: true, align: "left" },
      { name: "Código", key: "sus_pa_id_dv", active: true, align: "left" },
      { name: "Procedimento", key: "sus_pa_dc", active: true, align: "left" },
      {
        name: "Quantidade",
        key: "ppd_quantidade",
        active: true,
        align: "left",
      },
      { name: "CID-10", key: "ppd_cid10", active: true, align: "left" },
    ];
  },
  beforeDestroy() {
    const validateSearchRoute = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/attendance/search",
      this.$route.meta.breadcrumb,
    );
    const validateRegisterRoute = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/attendance/register",
      this.$route.meta.breadcrumb,
    );

    if (!validateSearchRoute && !validateRegisterRoute) {
      this.$store.commit("Billing/Attendance/RESET_FILTER_APPOINTMENT_LIST");
      this.$store.commit("Billing/Attendance/RESET_APPOINTMENT_SELECT_LIST");
    }
  },
  methods: {
    async getStandardProcedures() {
      try {
        this.$loader.start();

        const proceduresAux = await this.$store.dispatch(
          "Billing/Attendance/GET_STANDARD_PROCEDURES",
        );

        this.arrProcedimentosAutomaticos = proceduresAux.map((item) => {
          return item.pcp_codigo_procedimento_padrao;
        });
      } catch (pErr) {
        this.$toaster.error("Erro ao exibir procedimentos padrões");
      } finally {
        this.$loader.finish();
      }
    },
    async loadFilterAppointments() {
      const data = this.$store.getters[
        "Billing/Attendance/GET_FILTER_APPOINTMENT_LIST"
      ];

      if (data && Object.keys(data).length > 0) {
        this.form.unitHealthId = data.uns_id;
        this.form.sectorId = data.set_id;
        this.form.occupationId = data.ocp_id;
        this.form.employeeId = data.pes_id;
        this.sectorBondId = data.vus_id;
        this.form.serviceDate = this.$utils.date.BrazilianDateFormat(
          data.con_data,
        );
        await this.$refs.rgSearch.performSearch();
      }
    },
    addProcedure() {
      this.$router.push({
        name: "billing.bpa.attendance.register",
        params: { appointmentSelected: this.appointmentSelected },
      });
    },
    editProcedure() {},
    async removeProcedure(pId) {
      try {
        this.$loader.start();
        const variables = {
          ppd_id: pId,
        };

        await this.$store.dispatch(
          "Billing/Attendance/REMOVE_PROCEDURE",
          variables,
        );

        this.$toaster.success("Procedimento excluído com sucesso");
      } catch (pErr) {
        this.$toaster.error("Erro ao excluir procedimento");
      } finally {
        this.$loader.finish();
      }
    },
    async printOutpatientControlSheet() {
      try {
        this.$loader.start();

        const variables = {
          bondId: this.bondId,
          serviceDate: this.$utils.date.BrazilianDateToDatabase(
            this.form.serviceDate,
          ),
        };

        const result = await this.$store.dispatch(
          "Billing/Attendance/GET_OUTPATIENT_CONTROL_SHEET",
          variables,
        );

        this.print.serviceDate = this.form.serviceDate;

        const html = OutpatientControlSheetHTML(
          this.print,
          result,
          this.unitHealth,
          this.clientName,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        this.$toaster.error("Não foi possível gerar a impressão", pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async getListDaysServiceByProfessional() {
      try {
        const variables = {
          sectorId: this.form.sectorId,
          occupationId: this.form.occupationId,
          bondId: this.bondId,
        };

        this.$loader.start();

        const result = await this.$store.dispatch(
          "Billing/Attendance/GET_LIST_DAYS_SERVICE_BY_PROFESSIONAL",
          variables,
        );

        this.listDays = result.map((item) => {
          return moment(item.hag_data_consulta, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );
        });

        this.$loader.finish();

        if (this.listDays.length === 0) {
          this.serviceDate = null;
          this.$toaster.warning(
            "Não há nenhum agendamento para este profissional.",
          );
        }
      } catch (pErr) {
        this.$toaster.warning("Erro ao realizar a busca");
      } finally {
        this.$loader.finish();
      }
    },
    async searchFilter(pData) {
      const data = await this.$store.dispatch(
        "Billing/Attendance/GET_APPOINTMENTS",
        pData,
      );
      return data;
    },
    generateFilter() {
      const variables = {
        uns_id: this.form.unitHealthId,
        set_id: this.form.sectorId,
        ocp_id: this.form.occupationId,
        pes_id: this.form.employeeId,
        con_data: this.$utils.date.BrazilianDateToDatabase(
          this.form.serviceDate,
        ),
        vus_id: this.sectorBondId,
      };
      this.$store.commit(
        "Billing/Attendance/SET_FILTER_APPOINTMENT_LIST",
        variables,
      );
      return variables;
    },
    async searchProcedures() {
      try {
        this.$loader.start();

        this.appointmentSelected.uns_id = this.form.unitHealthId;
        this.appointmentSelected.limiteInicio = this.pagination.offset;
        this.appointmentSelected.limiteFim = this.pagination.limit;

        const data = await this.$store.dispatch(
          "Billing/Attendance/GET_CONSULTATION_PROCEDURES",
          this.appointmentSelected,
        );

        this.mutableProceduresList = data.rows;
        this.totalProcedures = data.count;

        this.messagewithoutResult =
          this.mutableProceduresList.length > 0
            ? "Selecione um dos pacientes atendidos"
            : "Não Há Resultado(s)";
      } catch (pErr) {
        this.$toaster.warning("Houve um erro ao carregar os procedimentos");
      } finally {
        this.clearSelectedProcedureRow();
        this.$loader.finish();
      }
    },
    async getPagination(pPagination) {
      this.pagination = pPagination;
      await this.searchProcedures();
    },
    getCountValue(pValue) {
      this.totalAppointments = Number(pValue) || 0;
    },
    afterSearchFilter() {
      this.clearProcedureList();
      this.clearSelectedServiceRow();
    },
    openModalConfirmDeletion() {
      this.modalConfirmDeletion = true;
    },
    closeModalConfirmDeletion() {
      this.modalConfirmDeletion = false;
    },
    openModalClassificationProcedure() {
      this.modalClassificationProcedure = true;
    },
    closeModalClassificationProcedure() {
      this.clearSelectedProcedureRow();
      this.modalClassificationProcedure = false;
    },
    selectAppointment(pAppointment) {
      if (pAppointment && Object.keys(pAppointment).length > 0) {
        this.appointmentSelected = pAppointment;
        this.clearProcedureList();
        this.searchProcedures(pAppointment);
        this.$store.commit(
          "Billing/Attendance/SET_APPOINTMENT_SELECT_LIST",
          pAppointment,
        );
      } else {
        this.clearProcedureList();
        this.procedureSelected = {};
        this.appointmentSelected = {};
        this.$store.commit("Billing/Attendance/RESET_APPOINTMENT_SELECT_LIST");
        this.messagewithoutResult = "Selecione um dos pacientes atendidos";
      }
    },
    selectProcedure(pProcedure) {
      if (pProcedure && Object.keys(pProcedure).length > 0) {
        this.procedureSelected = pProcedure;
      } else {
        this.procedureSelected = {};
      }
    },
    selectedSector(pValue) {
      if (pValue && Object.keys(pValue).length > 0) {
        this.print.sectorName = pValue.label;
      } else {
        this.print.sectorName = null;
      }
    },
    selectedOccupation(pValue) {
      if (pValue && Object.keys(pValue).length > 0) {
        this.print.occupationName = pValue.label;
      } else {
        this.print.occupationName = null;
      }
    },
    selectedEmployee(pValue) {
      if (pValue && Object.keys(pValue).length > 0) {
        this.bondId = pValue.vin_id;
        this.sectorBondId = pValue.vus_id;
        this.print.employeeName = pValue.label;
      } else {
        this.bondId = null;
        this.sectorBondId = null;
        this.print.employeeName = null;
      }
    },
    clearSelectedServiceRow() {
      this.appointmentSelected = {};
      if (this.$refs.smartTableAppointment)
        this.$refs.smartTableAppointment.cleanSelectRow();
    },
    clearSelectedProcedureRow() {
      this.procedureSelected = {};
      if (this.$refs.smartTableProcedure) {
        this.$refs.smartTableProcedure.cleanSelectRow();
      }
    },
    clearProcedureList() {
      this.clearSelectedProcedureRow();
      this.mutableProceduresList = [];
      this.totalProcedures = 0;
      this.clearPagination();
    },
    clearPagination() {
      this.pagination.limit = 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },
    clearForm() {
      this.form = {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sectorId: null,
        occupationId: null,
        employeeId: null,
        serviceDate: null,
      };
      this.print = {
        unitHealthName: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        sectorName: null,
        occupationName: null,
        employeeName: null,
        serviceDate: null,
      };
      this.mutableAppointmentsList = [];
      this.mutableProceduresList = [];
      this.bondId = null;
      this.sectorBondId = null;
      this.subModuleId = 51;
      this.listDays = null;
      this.totalAppointments = 0;
      this.totalProcedures = 0;
      this.modalConfirmDeletion = false;
      this.modalClassificationProcedure = false;

      this.clearPagination();
      this.clearSelectedServiceRow();
      this.clearSelectedProcedureRow();

      this.$store.commit("Billing/Attendance/RESET_FILTER_APPOINTMENT_LIST");
      this.$store.commit("Billing/Attendance/RESET_APPOINTMENT_SELECT_LIST");
    },
  },
};
</script>
