<template>
  <Modulebox
    :title="
      isEdition
        ? 'Editar Procedimentos para o Profissional da Saúde'
        : 'Adicionar Novos Procedimentos para o Profissional da Saúde'
    "
    :disabled="disabledByModal"
    class="register-procedures"
  >
    <main class="procedures-body">
      <FormBase
        title="Dados do Profissional"
        class="form-base"
        :class="{ disable: disabledByModal }"
      >
        <div class="first-collumn">
          <RgInput
            id="cnes"
            v-model="form.cnes"
            type="text"
            placeholder="000000"
            label="CNES"
            :disabled="true"
            :class="{ disable: true }"
            :rules="{ required: true }"
            class="inputitem"
          />
          <RgSelectUnithealth
            id="unit-health"
            ref="unitHealth"
            v-model="form.unitHealth"
            :rules="{ required: true }"
            disabled
            :class="{ disable: true }"
            searchUnitById
            :unitHealthId="form.unitHealth"
            class="inputitem"
            label="Unidade de Saúde"
            permission="faturamento.faturar"
          />
        </div>
        <div class="second-collumn">
          <RgSelectOccupation
            id="cbo"
            ref="cbo"
            v-model="form.cbo"
            :class="{
              disable: !form.unitHealth || isEdition,
            }"
            :disabled="!form.unitHealth || isEdition"
            :rules="{ required: true }"
            :unitHealthId="form.unitHealth"
            :subModuleId="54"
            label="Ocupação"
            class="inputitem"
            @change="selectedOccupation"
          />
          <RgSuggestEmployee
            ref="employee"
            v-model="form.employee.nameAndCns"
            label="CNS e Nome do Profissional"
            placeholder="Digite o número de CNS ou nome do Profissional (Todos)"
            :disabled="!form.unitHealth || !form.cbo || isEdition"
            :class="{
              disable: !form.unitHealth || !form.cbo || isEdition,
            }"
            :ocuppationId="Number(form.cbo)"
            :unitHealthId="form.unitHealth"
            returnAllBonds
            class="inputitem"
            @selected="selectedEmployee"
          />
          <RgSelectSectorEmployee
            id="cbo"
            ref="cbo"
            v-model="form.set"
            :class="{
              disable: !form.unitHealth || !form.employee.vin_id || isEdition,
            }"
            :disabled="!form.unitHealth || !form.employee.vin_id || isEdition"
            defaultText="Selecione (Todos)"
            :unitHealthId="form.unitHealth"
            :vinId="Number(form.employee.vin_id)"
            class="inputitem"
            @change="selectedSector"
          />
        </div>
      </FormBase>

      <FormBase
        title="Buscar e Importar Procedimentos"
        class="form-base"
        :class="{ disable: disabledByModal }"
      >
        <RgValidatorForm ref="validator">
          <div class="tooltip-procedure">
            <Tooltip
              class="tooltip"
              message="O campo de busca realiza a pesquisa de procedimentos DATASUS e
                dos procedimentos adicionados para o profissional."
              startOpen
            >
              <IconLightBulbHelper slot="icon" />
            </Tooltip>
          </div>
          <section class="products-data">
            <RgInput
              id="procedure"
              v-model="search"
              :class="{ disable: !form.cbo }"
              :disabled="!form.cbo"
              label="Busca"
              class="search-procedure"
              placeholder="Digite o código ou nome do procedimento"
            />
            <div class="actions">
              <RgSearchButton
                id="search"
                ref="searchBtn"
                :disabled="disabledByModal || !form.cbo"
                @submit="searchSpaProcedures(true)"
              />
              <RgCleanButton
                id="clean-btn"
                :disabled="disabledByModal || !form.cbo"
                @click="cleanForm"
              />
              <RgAddButton
                id="add-btn"
                title="Adicionar Procedimento"
                :disabled="disabledByModal || listProcedures.length === 0"
                @click="saveProcedures"
              />
            </div>
          </section>

          <hr class="separator" />
        </RgValidatorForm>

        <section class="product-tables">
          <div>
            <span class="table-title space">
              Lista de Procedimentos DataSUS
            </span>
            <div v-show="mutableSpa.length > 0">
              <SmartTable
                ref="smartTable"
                name="Spa"
                :columns="COLUMNS"
                :body="mutableSpa"
                :total="total"
                :removeBtnColumns="true"
                :initial-columns="5"
                toggle-selected
                hasCheck
                multSelect
                :max-register="20"
                :item-per-page="pagination.limit"
                class="smart-table-spa"
                :disabled="disabledByModal"
                :class="{
                  disable: disabledByModal,
                }"
                @getMultLines="selectMultProcedures"
                @pagination="getPagination"
              />
            </div>
            <div v-show="mutableSpa.length === 0" class="no-results-empty">
              <IconEmpty />
              <span class="message">
                Para exibir resultados, preencha o campo “busca” e clique no
                botão de “lupa”
              </span>
            </div>
          </div>

          <div>
            <span class="table-title">
              Procedimentos Adicionados para o Profissional
            </span>
            <div v-show="mutableProcedure.length > 0">
              <SmartTable
                ref="headerProcedure"
                name="spaTable"
                :columns="COLUMNS"
                :body="mutableProcedure"
                :total="totalProcedures"
                :removeBtnColumns="true"
                :initial-columns="5"
                toggle-selected
                hasCheck
                multSelect
                :max-register="20"
                :item-per-page="paginationProcedure.limit"
                class="smart-table-procedure"
                :disabled="disabledByModal"
                :class="{
                  disable: disabledByModal,
                }"
                @getMultLines="selectProcedure"
                @pagination="getPaginationProcedures"
              >
                <div slot="top-buttons" class="top-buttons">
                  <RgLessButton
                    id="delete"
                    title="Remover Procedimento"
                    :disabled="disabledByModal || selectedProcedure.length < 1"
                    @click="openModalDeleteProcedure"
                  />
                </div>
              </SmartTable>
            </div>
            <div
              v-show="mutableProcedure.length === 0"
              class="no-results-helper"
            >
              <IconInfoHelper class="icon" :background="'#6f8894'" />
              <span class="message">
                PARA EXIBIR OS PROCEDIMENTOS ADICIONADOS, SELECIONE UMA
                "OCUPAÇÃO" OU PROFISSIONAL
              </span>
            </div>
          </div>
        </section>
      </FormBase>
    </main>

    <footer class="procedures-footer">
      <div class="actions">
        <MediumButton
          label="Voltar"
          :disabled="disabledByModal"
          @click="goBack"
        />
      </div>
    </footer>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalProcedure"
      @reSearch="searchProcedures"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectOccupation from "$billing/submodules/bpa/procedures/components/select/rg-select-occupation/RgSelectOccupation";
import RgSelectSectorEmployee from "$billing/submodules/bpa/procedures/components/select/rg-select-sector-employee/RgSelectSectorEmployee.vue";

import {
  RgValidatorForm,
  RgCleanButton,
  RgSelectUnithealth,
  RgInput,
  RgSuggestEmployee,
  RgSearchButton,
  RgAddButton,
  MediumButton,
  ModalConfirmDeletion,
  IconEmpty,
  IconInfoHelper,
  RgLessButton,
  Tooltip,
  IconLightBulbHelper,
} from "~tokio/primitive";

const FORM = {
  cnes: null,
  unitHealth: null,
  employee: {
    professionalId: null,
    nameAndCns: null,
    professionalName: null,
    vin_id: null,
  },
  cbo: null,
  cbo_nome: null,
  ocp_codigo: null,
  set: null,
  set_nome: null,
};

export default {
  name: "RegisterProcedures",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    SmartTable,
    RgCleanButton,
    RgSearchButton,
    RgSelectUnithealth,
    RgInput,
    RgSuggestEmployee,
    RgSelectOccupation,
    RgAddButton,
    MediumButton,
    RgSelectSectorEmployee,
    ModalConfirmDeletion,
    IconEmpty,
    IconInfoHelper,
    RgLessButton,
    Tooltip,
    IconLightBulbHelper,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableSpa: [],
      mutableProcedure: [],
      headerProcedure: [],
      search: null,
      listProcedures: [],
      selectedProcedure: [],
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      paginationProcedure: {
        limitProcedure: 20,
        offsetProcedure: 0,
        currentProcedure: 1,
      },
      total: 0,
      totalProcedures: 0,
      isEdition: false,
      showModalDeleteProcedure: false,
      existFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
      clientId: "Login/GET_USER_ID_CLIENT",
    }),

    disabledByModal() {
      return this.showModalDeleteProcedure;
    },
    propsModalConfirmDeletion() {
      const title = `Excluir Procedimento(s) Importado(s)`;
      const message = `O(s) procedimento(s) será(ão) removido(s) da lista de importações realizadas para o profissional.`;
      const confirm = () => this.deleteProcedure();
      const msgSuccess = "Procedimento(s) excluído(s) com sucesso";
      const show = this.showModalDeleteProcedure;
      const noReason = true;
      const bodyText = `${
        this.form.employee.nameAndCns || this.form.cbo_nome
      } - ${this.selectedProcedure.length} Procedimento(s)`;

      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
  },
  watch: {
    async "form.cbo"(pValue, pPrev) {
      if (!pValue && (pValue === null || pPrev === null)) {
        this.mutableSpa = [];
        this.mutableProcedure = [];
      } else {
        try {
          this.$loader.start();
          this.cleanPagination();
          await this.searchProcedures();
          if (this.existFilter) this.existFilter = false;
        } catch (e) {
          this.$toaster.error(e);
        } finally {
          this.$loader.finish();
        }
      }
    },
    "form.employee.vin_id"() {
      this.changeProcedure();
    },
    "form.set"() {
      this.changeProcedure();
    },
  },
  created() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.COLUMN_PROCEDURE = [
      { name: "Código", key: "sus_pa_id_dv", align: "left", active: true },
      {
        name: "Procedimento",
        key: "sus_pa_dc",
        align: "left",
        active: true,
      },
      { name: "Custo Real", key: "sus_pa_total", align: "left", active: true },
      { name: "", active: true },
    ];

    this.COLUMNS = [
      { name: "Código", key: "sus_pa_id_dv", active: true, align: "left" },
      {
        name: "Procedimento",
        key: "sus_pa_dc",
        active: true,
        align: "left",
      },
      {
        name: "Custo Real",
        key: "sus_pa_total",
        active: true,
        align: "left",
      },
    ];
  },
  mounted() {
    this.form.cnes = this.currentUnitHealth.uns_cnes;
    this.headerProcedure = this.COLUMN_PROCEDURE;
    const isEdition = this.$route.name === "billing.bpa.procedures.edit";
    if (isEdition) {
      this.existFilter = true;
      this.isEdition = true;
      this.mountData();
    } else {
      const existFilterData = this.$store.getters[
        "Billing/Procedure/GET_FILTER_BILLING_PROCEDURE"
      ];
      if (existFilterData) {
        this.existFilter = true;
        this.fillData(existFilterData);
      }
    }
  },
  destroyed() {
    this.$store.commit("Billing/Procedure/CLEAN_REGISTER_BILLING_PROCEDURE");
  },
  methods: {
    async changeProcedure() {
      if (this.form.cbo !== null && !this.existFilter) {
        try {
          this.$loader.start();
          this.paginationProcedure.limit = 20;
          this.paginationProcedure.offset = 0;
          this.paginationProcedure.current = 1;
          await this.searchProcedures();
        } catch (e) {
          this.$toaster.error(e);
        } finally {
          this.$loader.finish();
        }
      }
    },
    async mountData() {
      try {
        this.$loader.start();
        this.procedure = this.$store.getters[
          "Billing/Procedure/GET_REGISTER_BILLING_PROCEDURE"
        ];
        this.form.cbo = this.procedure?.ocp_id;
        if (this.procedure?.tum_id_vinculos !== "") {
          this.$refs.employee.forceSelection({
            pes_nome: this.procedure?.pes_nome,
            vin_id: this.procedure?.tum_id_vinculos,
            fun_id: this.procedure?.fun_id,
          });
          this.form.set = this.procedure?.set_id;
        } else {
          this.form.set = null;
        }
        this.cleanPagination();
        await this.searchProcedures(true);
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        this.$loader.finish();
      }
    },
    async getPaginationProcedures(pPagination) {
      try {
        this.$loader.start();
        this.paginationProcedure = pPagination;
        await this.searchProcedures();
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        this.$loader.finish();
      }
    },
    async getPagination(pPagination) {
      try {
        this.$loader.start();
        this.pagination = pPagination;
        await this.searchSpa();
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        this.$loader.finish();
      }
    },

    async searchSpaProcedures(buttonSearch = true) {
      try {
        this.$loader.start();
        this.cleanPagination();
        await this.searchProcedures(false, buttonSearch);
        await this.searchSpa(buttonSearch);
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        this.$loader.finish();
      }
    },

    async searchProcedures(hasLoading = false, buttonSearch = false) {
      try {
        if (hasLoading) this.$loader.start();
        const variables = {
          arrFormData: {
            uns_id: this.form.unitHealth,
            vin_id: this.form.employee?.vin_id,
            ocp_id: this.form.cbo,
            strCodigo: this.search,
            set_id: this.form.set,
            isByRegister: true,
            limiteInicio: this.paginationProcedure.offset,
            limiteFim: this.paginationProcedure.limit,
          },
        };

        if (this.form.employee?.vin_id !== null) {
          variables.arrFormData.blnOnlyEmployee = true;
        } else {
          variables.arrFormData.blnOnlyOccupation = true;
        }
        const data = await this.$store.dispatch(
          "Billing/Procedure/SEARCH_PROCEDURE",
          variables,
        );
        this.mutableProcedure = [];

        this.mutableProcedure = data.data;
        this.totalProcedures = Number(data.total);
        if (this.$refs.headerProcedure)
          this.$refs.headerProcedure.cleanMultSelectedRow();
      } catch (e) {
        this.$toaster.error(
          "Erro ao carregar as informações dos procedimentos",
        );
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
        if (hasLoading) this.$loader.finish();
      }
    },

    async searchSpa(buttonSearch = false) {
      try {
        const variables = {
          arrFormData: {
            uns_id: this.form.unitHealth,
            vin_id: this.form.employee?.vin_id,
            ocp_id: this.form.cbo,
            limiteInicio: this.pagination.offset,
            limiteFim: this.pagination.limit,
            strCodigo: this.search,
          },
        };

        if (this.form.employee?.vin_id !== null) {
          variables.arrFormData.blnOnlyEmployee = true;
        } else {
          variables.arrFormData.blnOnlyOccupation = true;
        }

        const data = await this.$store.dispatch(
          "Billing/Procedure/SEARCH_SPA",
          variables,
        );

        this.mutableSpa = [];

        this.mutableSpa = data.data;
        this.total = Number(data.total);

        if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
      } catch (e) {
        this.$toaster.error(
          "Erro ao carregar as informações dos procedimentos",
        );
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
      }
    },
    fillData(existFilterData) {
      this.form.vin_id = existFilterData.vin_id;
      this.form.cbo = existFilterData.cbo;

      this.$refs.employee.forceSelection({
        pes_nome: existFilterData.employee.professionalName,
        vin_id: existFilterData.employee.vin_id,
        fun_id: existFilterData.employee.professionalId,
      });
    },
    selectedEmployee(pValue) {
      if (pValue.source) {
        const aux = { ...pValue.source };
        this.form.employee.professionalName = aux.pes_nome;
        this.form.employee.professionalId = aux.fun_id;
        this.form.employee.vin_id = aux.vin_id;
        if (!this.isEdition) {
          this.form.set_nome = null;
        }
      } else {
        this.form.employee.professionalName = null;
        this.form.employee.professionalId = null;
        this.form.employee.vin_id = null;
        this.form.set_nome = null;
      }
    },

    selectedSector(pValue) {
      if (pValue) {
        this.form.set_nome = pValue.label;
      } else {
        this.form.set_nome = null;
      }
    },
    selectedOccupation(pValue) {
      if (pValue) {
        this.form.cbo_nome = pValue.label;
        this.form.ocp_codigo = pValue.codigo;
        if (!this.isEdition) {
          this.cleanEmployee();
        }
      } else {
        this.form.cbo_nome = null;
        this.form.ocp_codigo = null;
        this.cleanEmployee();
      }
    },
    cleanEmployee() {
      this.form.employee.professionalName = null;
      this.form.employee.professionalId = null;
      this.form.employee.vin_id = null;
      this.form.employee.nameAndCns = null;
    },
    goBack() {
      this.$router.go(-1);
    },

    selectMultProcedures(pItem) {
      this.listProcedures = pItem;
    },

    openModalDeleteProcedure() {
      this.showModalDeleteProcedure = true;
    },

    closeModalProcedure() {
      this.showModalDeleteProcedure = false;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveProcedures() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();

        const procedureCode = [];
        this.listProcedures.forEach((item) => {
          procedureCode.push(`${item.sus_pa_id_dv}`);
        });
        const variables = {
          arrProcedimentos: procedureCode,
          intIdUnidadeSaude: this.form.unitHealth,
          intIdCliente: this.clientId,
          intIdOcupacao: this.form.cbo,
          intIdVinculos:
            this.form.employee?.vin_id === null
              ? "0"
              : this.form.employee?.vin_id,
          intIdSetor: this.form.set ? this.form.set : null,
        };
        await this.$store.dispatch(
          "Billing/Procedure/EXPORT_PROCEDURE",
          variables,
        );
        this.listProcedures = [];

        this.selectedProcedure = [];

        this.isEdition
          ? this.$toaster.success(
              "Edição de Procedimentos importados realizada com sucesso",
            )
          : this.$toaster.success(
              "Procedimento importado para o Profissional com sucesso",
            );
        await this.searchSpa();
        await this.searchProcedures(false);

        if (this.$refs.headerProcedure)
          this.$refs.headerProcedure.cleanMultSelectedRow();
        if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
      } catch (e) {
        this.$toaster.error("Não foi possível importar o procedimento");
      } finally {
        this.$loader.finish();
      }
    },

    selectProcedure(pValue) {
      this.selectedProcedure = pValue;
    },
    async deleteProcedure() {
      try {
        this.$loader.start();
        const variables = [];

        await this.selectedProcedure.forEach(async (item) => {
          variables.push({ intIdTabelaUnificadaMunicipio: item.tum_id });
        });
        await this.$store.dispatch(
          "Billing/Procedure/DELETE_PHARMACY_PROCEDURE_LOT",
          { arrFormData: variables },
        );
        if (this.$refs.headerProcedure)
          this.$refs.headerProcedure.cleanMultSelectedRow();
        if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();

        this.selectedProcedure = [];
        this.closeModalProcedure();
        await this.searchProcedures();
        this.$toaster.success("Procedimento excluído com sucesso");
      } catch (error) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Procedimento",
        );
      } finally {
        this.$loader.finish();
      }
    },

    cleanForm() {
      this.search = null;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
      if (this.$refs.headerProcedure)
        this.$refs.headerProcedure.cleanMultSelectedRow();
      this.cleanPagination();
      this.mutableSpa = [];
      this.searchProcedures(true);
    },
    cleanPagination() {
      this.pagination.limit = 20;
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.paginationProcedure.limit = 20;
      this.paginationProcedure.offset = 0;
      this.paginationProcedure.current = 1;
    },
  },
};
</script>
