import AuthRequest from "~common/request/AuthRequest";

export default (params) => {
  return AuthRequest.get(
    "access-control/get-unit-health",
    params
      ? {
          uns_id: params.uns_id,
        }
      : null,
  ).then((pResult) => {
    return pResult.data;
  });
};
