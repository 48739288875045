<template>
  <Modulebox
    :title="`Adicionar Novos Procedimentos para o Atendimento`"
    class="billing-bpa-attendances-register"
    :disabled="disabledByModal"
  >
    <div class="billing-bpa-attendances-register-body">
      <form class="form-container">
        <RgValidatorForm ref="validator" class="validator">
          <FormBase
            title="Dados do Atendimento"
            class="form-service-data"
            :class="{ disable: disabledByModal }"
          >
            <Tooltip class="tooltip" startOpen :message="messageTooltip">
              <IconInfoHelper slot="icon" />
            </Tooltip>

            <div class="professional-data">
              <div class="first-container-input">
                <span>
                  Tipo de Atendimento:
                  <strong>
                    {{ appointmentSelected.tcu_novo_tipo_consulta }}
                  </strong>
                </span>
                <span>
                  Atendimento:
                  <strong>
                    {{ appointmentSelected.dataHoraAtendimento }}
                  </strong>
                </span>
                <span>
                  Paciente:
                  <strong> {{ appointmentSelected.pes_nome }} </strong>
                </span>
                <span>
                  CNS do Paciente:
                  <strong>
                    {{ formatCns(appointmentSelected.crs_numero) }}
                  </strong>
                </span>
              </div>

              <div class="separator"></div>

              <div class="second-container-input">
                <RgSelectOccupationsAttendantsByUnit
                  id="input-occupation"
                  v-model="form.occupationId"
                  class="input-occupation"
                  :unitHealthId="unitHealthId"
                  :rules="{ required: true }"
                />

                <RgSuggestEmployee
                  id="input-employee"
                  ref="employee"
                  v-model="form.professionalId"
                  class="input-employee"
                  label="CNS e Nome do Profissional"
                  :rules="{ required: true }"
                  :unitHealthId="unitHealthId"
                  :occupationId="Number(form.occupationId)"
                  searchOnly
                  returnAllBonds
                  @selected="selectedEmployee"
                />
              </div>
            </div>
          </FormBase>

          <FormBase
            title="Buscar e Importar Procedimentos"
            class="form-search-import-procedures"
            :class="{ disable: disabledByModal }"
          >
            <div class="search-import-procedure">
              <div class="content-search">
                <RgInput
                  id="input-search-procedure"
                  v-model="search.procedure"
                  label="Busca"
                  class="search-procedure"
                  placeholder="Digite o Código ou o Nome do Procedimento"
                />
                <div class="actions">
                  <RgCleanButton
                    :disabled="disabledByModal"
                    @click="clearSearch"
                  />
                  <RgSearchButton
                    ref="searchButton"
                    v-shortkey="['enter']"
                    :disabled="disabledByModal"
                    @submit="searchProcedure(search.procedure)"
                    @shortkey.native="searchProcedure(search.procedure)"
                  />
                  <RgAddButton
                    :disabled="
                      hasDataSusProcedureSelected ||
                      disableRegisterButton ||
                      disabledByModal
                    "
                    @click="registerProcedure"
                  />
                </div>
                <RgLessButton
                  class="less-btn"
                  :disabled="hasServiceProcedureSelected || disabledByModal"
                  @click="openModalConfirmDeletion"
                />
              </div>

              <div class="content-grid">
                <section
                  v-if="hasListProceduresDataSUS"
                  class="list-procedures-data-sus"
                >
                  <span class="legend"> Lista de Procedimentos DataSUS </span>
                  <SmartTable
                    ref="dataSusProceduresSmartTable"
                    name="dataSusProceduresTable"
                    :columns="DATASUS_PROCEDURES_COLUMNS"
                    :initial-columns="3"
                    :showPagination="false"
                    :removeBtnColumns="true"
                    :body="mutableCurrentProceduresDataSUS"
                    :total="mutableCurrentProceduresDataSUS.length"
                    toggleSelected
                    :disabled="disabledByModal"
                    @getLine="selectedDataSusProcedure"
                  />
                </section>

                <section v-else class="list-data-sus-empty">
                  <span class="legend"> Lista de Procedimentos DataSUS </span>

                  <div class="content-empty">
                    <IconEmpty class="icon" :background="'#6f8894'" />

                    <div class="message-empty">
                      <span class="text">
                        PARA EXIBIR RESULTADOS, OS CAMPOS "OCUPAÇÃO"
                      </span>
                      <span class="text">
                        E "CNS DO PROFISSIONAL" DEVEM SER PREENCHIDOS
                      </span>
                    </div>
                  </div>
                </section>

                <section
                  v-if="hasListAddedProceduresProfessional"
                  class="list-procedures-professional"
                >
                  <div class="legend">
                    Procedimentos Adicionados para o Atendimento
                  </div>

                  <SmartTable
                    ref="serviceProceduresSmartTable"
                    name="serviceProceduresTable"
                    :columns="SERVICE_PROCEDURES_COLUMNS"
                    :initial-columns="3"
                    :showPagination="false"
                    :removeBtnColumns="true"
                    :body="mutableAddedProceduresProfessionalList"
                    :total="mutableAddedProceduresProfessionalList.length"
                    toggleSelected
                    :disabled="disabledByModal"
                    @getLine="selectedServiceProcedure"
                  />
                </section>

                <section v-else class="list-procedures-professional-empty">
                  <div class="legend">
                    Procedimentos Adicionados para o Atendimento
                  </div>

                  <div class="content-empty">
                    <IconInfoHelper class="icon" :background="'#6f8894'" />

                    <div class="message-empty">
                      <span class="text">
                        PARA EXIBIR OS PROCEDIMENTOS ADICIONADOS,
                      </span>
                      <span class="text">
                        SELECIONE UMA "OCUPAÇÃO" E "PROFISSIONAL"
                      </span>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="billing-bpa-attendances-register-footer">
      <MediumButton
        title="Voltar"
        label="Voltar"
        :disabled="disabledByModal"
        @click="goBack()"
      />
    </div>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalConfirmDeletion"
      @research="searchAddedProceduresProfessional"
    />

    <ModalClassificationProcedure
      id="modal-confirm-deletion"
      :show="modalClassificationProcedure"
      :serviceInfo="appointmentSelected"
      :procedureInfo="dataSusProcedureSelected"
      @save="registerProcedure"
      @close="closeModalClassificationProcedure"
    />
  </Modulebox>
</template>
<script>
import {
  RgValidatorForm,
  RgAddButton,
  RgSearchButton,
  RgCleanButton,
  MediumButton,
  ModalConfirmDeletion,
  Tooltip,
  IconInfoHelper,
  RgInput,
  IconEmpty,
  RgLessButton,
} from "~tokio/primitive";

import { FormBase, Modulebox, SmartTable } from "~tokio/foundation";

import RgSuggestEmployee from "$billing/common/components/suggest/rg-suggest-employee/RgSuggestEmployee";
import RgSelectOccupationsAttendantsByUnit from "$billing/submodules/bpa/attendances/components/select/rg-select-occupations-attendants-by-unit/RgSelectOccupationsAttendantsByUnit";

import ModalClassificationProcedure from "$billing/submodules/bpa/attendances/components/modal/modal-classification-procedure/ModalClassificationProcedure";

export default {
  name: "BillingBpaAttendancesRegister",
  components: {
    RgSelectOccupationsAttendantsByUnit,
    Modulebox,
    FormBase,
    SmartTable,
    RgValidatorForm,
    RgAddButton,
    RgSearchButton,
    RgCleanButton,
    MediumButton,
    Tooltip,
    IconInfoHelper,
    ModalConfirmDeletion,
    ModalClassificationProcedure,
    RgInput,
    IconEmpty,
    RgSuggestEmployee,
    RgLessButton,
  },
  data() {
    return {
      cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
      unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      unitHealthName: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
      form: {
        occupationId: null,
        occupationName: null,
        professionalId: null,
        professionalName: null,
        employeeId: null,
        vin_id: null,
        bondId: null,
      },
      search: {
        procedure: null,
      },
      mutableProceduresDataSUS: [],
      mutableCurrentProceduresDataSUS: [],
      mutableAddedProceduresProfessionalList: [],
      appointmentSelected: {},
      dataSusProcedureSelected: {},
      procedureServiceSelected: {},
      modalConfirmDeletion: false,
      modalClassificationProcedure: false,
      disableRegisterButton: false,
    };
  },
  computed: {
    disabledByModal() {
      return this.modalConfirmDeletion || this.modalClassificationProcedure;
    },
    messageTooltip() {
      return `Informações da Unidade desse Atendimento: CNES UNIDADE: ${this.cnes} NOME DA UNIDADE: ${this.unitHealthName}`;
    },
    hasDataSusProcedureSelected() {
      return (
        this.dataSusProcedureSelected &&
        Object.keys(this.dataSusProcedureSelected).length < 1
      );
    },
    hasServiceProcedureSelected() {
      return (
        this.procedureServiceSelected &&
        Object.keys(this.procedureServiceSelected).length < 1
      );
    },
    hasListProceduresDataSUS() {
      return (
        this.mutableProceduresDataSUS &&
        this.mutableProceduresDataSUS.length > 0
      );
    },
    hasListAddedProceduresProfessional() {
      return (
        this.mutableAddedProceduresProfessionalList &&
        this.mutableAddedProceduresProfessionalList.length > 0
      );
    },
    hasOccupation() {
      return this.form.occupationId && this.form.occupationId > 0;
    },
    hasEmployee() {
      return this.form.professionalId && this.form.professionalId > 0;
    },
    propsModalConfirmDeletion() {
      const show = this.modalConfirmDeletion;
      const noReason = true;
      const title = "Excluir Procedimento de Consulta";
      const message =
        "O registro selecionado será removido da relação de procedimentos importados";
      const btnRemoveTitle = "Excluir";
      const bodyText = `${this.procedureServiceSelected.sus_pa_id_dv} - ${this.procedureServiceSelected.sus_pa_dc}`;
      const confirm = () =>
        this.removeProcedure(this.procedureServiceSelected.ppd_id);

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
  },
  watch: {
    "form.occupationId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.mutableProceduresDataSUS = [];
        this.mutableCurrentProceduresDataSUS = [];
        this.form.professionalId = null;
      }
    },
    "form.professionalId": async function (pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.mutableProceduresDataSUS = [];
        this.mutableCurrentProceduresDataSUS = [];
      }
    },
  },
  created() {
    this.DATASUS_PROCEDURES_COLUMNS = [
      { name: "Código", key: "sus_pa_id_dv", active: true, align: "left" },
      { name: "Procedimento", key: "sus_pa_dc", active: true, align: "left" },
    ];
    this.SERVICE_PROCEDURES_COLUMNS = [
      { name: "Código", key: "sus_pa_id_dv", active: true, align: "left" },
      { name: "Procedimento", key: "sus_pa_dc", active: true, align: "left" },
      { name: "CID-10", key: "ppd_cid10", active: true, align: "left" },
    ];
  },
  async mounted() {
    this.appointmentSelected = this.$store.getters[
      "Billing/Attendance/GET_APPOINTMENT_SELECT_LIST"
    ];

    if (
      this.appointmentSelected &&
      Object.keys(this.appointmentSelected).length > 0
    ) {
      this.loadData(this.appointmentSelected);
      await this.searchAddedProceduresProfessional();
    }
  },
  methods: {
    async searchDataSUSProcedures() {
      try {
        this.$loader.start();

        const variables = {
          uns_id: this.appointmentSelected.uns_id,
          set_id: this.appointmentSelected.set_id,
          con_id: this.appointmentSelected.con_id,
          pes_id: this.form.professionalId,
          vin_id: this.form.bondId,
          vin_id_funcionarios: this.form.bondId,
          nomeFuncionario: this.form.nomeFuncionario,
          ocp_id: this.form.occupationId,
          ocp_nome: this.form.occupationName,
        };

        const data = await this.$store.dispatch(
          "Billing/Attendance/GET_PROCEDURES",
          variables,
        );

        this.mutableProceduresDataSUS = data;
        this.mutableCurrentProceduresDataSUS = data;
      } catch (pErr) {
        this.$toaster.error(
          "Não foi possível carregar a lista de procedimentos DataSUS",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async searchAddedProceduresProfessional() {
      try {
        this.$loader.start();
        const variables = {
          ...this.appointmentSelected,
          limiteFim: undefined,
          limiteInicio: undefined,
        };
        const data = await this.$store.dispatch(
          "Billing/Attendance/GET_CONSULTATION_PROCEDURES",
          variables,
        );
        this.mutableAddedProceduresProfessionalList = data.rows;
      } catch (pErr) {
        this.$toaster.warning(
          "Não foi possível carregar os procedimentos adicionados para o profissional",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async registerProcedure(isSaveByModal = false, pDataByModal) {
      try {
        this.$loader.start();
        this.disableRegisterButton = true;

        if (!isSaveByModal) {
          const validatesRegisteredProcedure = await this.validatesRegisteredProcedure();

          if (validatesRegisteredProcedure) {
            this.$toaster.info(
              `Confira a relação de procedimentos importados.`,
              "O Atendimento já possui esse procedimento",
            );
            return;
          }

          const checkProcedureHasServiceCid = await this.checkProcedureHasServiceCid();

          if (checkProcedureHasServiceCid) {
            this.openModalClassificationProcedure();
            return false;
          }
        }

        const variables = {
          arrFormData: {
            ppd_id_consultas: this.appointmentSelected.con_id,
            pes_id: this.appointmentSelected.pes_id,
            fun_id: this.form.employeeId,
            ppd_id_funcionarios: this.form.employeeId,
            ppd_id_ocupacoes_cbo: this.form.occupationId,
            ppd_codigo_procedimento: this.dataSusProcedureSelected.sus_pa_id_dv,
            ppd_quantidade: pDataByModal ? pDataByModal.amount : 1,
            ppd_cid10: pDataByModal ? pDataByModal.cid10 : null,
            ppd_servico: pDataByModal ? pDataByModal.service : null,
            vin_id: this.form.vin_id || null,
            ppd_classificacao: pDataByModal
              ? pDataByModal.classification
              : null,
          },
        };

        await this.$store.dispatch(
          "Billing/Attendance/REGISTER_PROCEDURE",
          variables,
        );

        if (!isSaveByModal) {
          this.clearDataSusProcedureSelectedLine();
          this.clearServiceProcedureSelectedLine();
        }

        await this.searchAddedProceduresProfessional();

        this.$toaster.success(
          "Procedimentos de Atendimentos importados com sucesso",
        );
      } catch (pError) {
        const error = pError.toString();
        const formatError = error.replace("Error: ", "");

        switch (formatError) {
          case "CID10 obrigatório":
            this.$toaster.info(
              `“Cid10” é um campo obrigatório para adicionar o procedimento.`,
              "O Procedimento precisa ser Classificado",
            );
            break;
          case "Sexo não permitido para o procedimento":
            this.$toaster.info(
              `No campo “Sexo” o gênero escolhido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com o sexo",
            );
            break;
          case "Idade não permitida para o procedimento":
            this.$toaster.info(
              `No campo “Idade” o valor inserido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com a idade",
            );
            break;
          case "Procedimento não importado para o profissional":
            this.$toaster.info(
              `Verifique os campos “Ocupação” e “Nome do Profissonal”.`,
              "O Procedimento não é permitido para o profissonal",
            );
            break;
          case "Procedimento já adicionado":
            this.$toaster.info(
              `Confira a relação de procedimentos importados.`,
              "O Atendimento já possui esse procedimento",
            );
            break;
          case "Profissional sem CNS":
            this.$toaster.info(
              `Insira um número válido no campo “CNS” para adicionar um procedimento.`,
              "O Profissional deve ter vínculo com um CNS",
            );
            break;
          case "Procedimento não pode ser faturado na competência atual":
            this.$toaster.info(
              `Fature o procedimento na próxima competência válida.`,
              "O Procedimento não pode ser faturado",
            );
            break;
          case "Procedimento exige serviço e classificação":
            this.$toaster.info(
              `Insira informações válidas nos campos “Serviço” e “Classificação”.`,
              "O Procedimento precisa ser classificado",
            );
            break;
          case "Procedimento incompatível com a quantidade informada":
            this.$toaster.info(
              `Insira no campo “Quantidade” um valor compatível com o procedimento.`,
              "O Procedimento é incompatível com a quantidade",
            );
            break;
          default:
            this.$toaster.error(error);
            break;
        }
      } finally {
        this.disableRegisterButton = false;
        this.$loader.finish();
      }
    },
    async removeProcedure(pId) {
      try {
        this.$loader.start();

        const variables = {
          ppd_id: pId,
        };

        await this.$store.dispatch(
          "Billing/Attendance/REMOVE_PROCEDURE",
          variables,
        );

        this.$toaster.success("Procedimento excluído com sucesso");
      } catch (pErr) {
        this.$toaster.error("Erro ao excluir procedimento");
      } finally {
        this.clearDataSusProcedureSelectedLine();
        this.clearServiceProcedureSelectedLine();

        this.searchAddedProceduresProfessional();
        this.$loader.finish();
      }
    },
    async validatesRegisteredProcedure() {
      try {
        const variables = {
          codProcedimento: this.dataSusProcedureSelected.sus_pa_id_dv,
          idPacienteProcedimento: this.appointmentSelected.con_id,
        };

        const result = await this.$store.dispatch(
          "Billing/Attendance/VALIDATES_REGISTERED_PROCEDURE",
          variables,
        );

        return result;
      } catch (pErr) {
        this.$toaster.error(
          "Não foi possível verificar a duplicidade do procedimento",
        );
      }
    },
    async checkProcedureHasServiceCid() {
      try {
        const variables = {
          codProcedimento: this.dataSusProcedureSelected.sus_pa_id_dv,
          intUnidade: this.unitHealthId,
        };

        const result = await this.$store.dispatch(
          "Billing/Attendance/CHECK_PROCEDURE_HAS_SERVICE_CID",
          variables,
        );

        return result;
      } catch (pErr) {
        this.$toaster.error(
          "Não foi possível verificar se o procedimento possui CID ou Serviço",
        );
      }
    },
    async searchProcedure(pKeyword) {
      if (pKeyword && pKeyword.length > 0) {
        const originalArray = this.mutableProceduresDataSUS;
        this.mutableCurrentProceduresDataSUS = originalArray;

        const filteredArray = originalArray.filter((item) => {
          if (this.isNumeric(pKeyword)) {
            return item.sus_pa_id_dv.includes(pKeyword.toUpperCase());
          } else {
            return item.sus_pa_dc.includes(pKeyword.toUpperCase());
          }
        });

        this.mutableCurrentProceduresDataSUS =
          filteredArray.length > 0 ? filteredArray : originalArray;

        if (filteredArray.length < 1) {
          this.search.procedure = null;
          this.$toaster.warning("Procedimento não encontrado");
        }
      } else {
        this.mutableCurrentProceduresDataSUS = this.mutableProceduresDataSUS;
      }
      this.clearDataSusProcedureSelectedLine();
      this.clearServiceProcedureSelectedLine();
      this.$refs.searchButton.actionDone();
    },
    loadData(pAppointment) {
      this.form.occupationId = pAppointment.ocp_id;
      this.form.professionalId = pAppointment.idPessoa;

      this.$refs.employee.forceSelection({
        pes_id: pAppointment.idPessoa,
        pes_nome: pAppointment.nomeFuncionario,
        vin_id: pAppointment.vin_id,
        fun_id: pAppointment.fun_id,
        person: { cns: { crs_numero: pAppointment.fun_crs_numero_ab } },
      });
    },
    selectedDataSusProcedure(pProcedure) {
      if (pProcedure && Object.keys(pProcedure).length > 0) {
        this.dataSusProcedureSelected = pProcedure;
      } else {
        this.dataSusProcedureSelected = {};
      }
    },
    selectedServiceProcedure(pProcedure) {
      if (pProcedure && Object.keys(pProcedure).length > 0) {
        this.procedureServiceSelected = pProcedure;
      } else {
        this.procedureServiceSelected = {};
      }
    },
    async selectedEmployee(pEmployee) {
      const employee = pEmployee.source;

      if (pEmployee && employee && Object.keys(employee).length > 0) {
        this.form.bondId = employee.vin_id;
        this.form.professionalId = employee.pes_id;
        this.form.professionalName = employee.pes_nome;
        this.form.employeeId = employee.fun_id;
        this.form.vin_id = employee.vin_id;

        await this.searchDataSUSProcedures();
      } else {
        this.form.bondId = null;
        this.form.professionalId = null;
        this.form.professionalName = null;
        this.form.vin_id = null;
      }
    },
    openModalConfirmDeletion() {
      this.modalConfirmDeletion = true;
    },
    closeModalConfirmDeletion() {
      this.modalConfirmDeletion = false;
      this.clearDataSusProcedureSelectedLine();
      this.clearServiceProcedureSelectedLine();
    },
    openModalClassificationProcedure() {
      this.modalClassificationProcedure = true;
    },
    closeModalClassificationProcedure() {
      this.modalClassificationProcedure = false;
      this.clearDataSusProcedureSelectedLine();
      this.clearServiceProcedureSelectedLine();
    },
    formatCns(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },
    isNumeric(pString) {
      return /^\d+$/.test(pString);
    },
    clearDataSusProcedureSelectedLine() {
      if (this.$refs.dataSusProceduresSmartTable)
        this.$refs.dataSusProceduresSmartTable.cleanSelectRow();
      this.dataSusProcedureSelected = {};
    },
    clearServiceProcedureSelectedLine() {
      if (this.$refs.serviceProceduresSmartTable)
        this.$refs.serviceProceduresSmartTable.cleanSelectRow();
      this.procedureServiceSelected = {};
    },
    async clearSearch() {
      this.search.procedure = null;
      if (this.form.occupationId && this.form.professionalId)
        await this.searchDataSUSProcedures();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
