<template>
  <section class="exam-schedule-per-day-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="unith-health"
          v-model="form.selectedUnity"
          :rules="{ required: true }"
          permission="exame2.agendamentoPorDia"
          class="inputitem"
          disabled
          searchUnitById
          :unitHealthId="form.selectedUnity"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="sector"
          ref="RgSelectSector"
          v-model="form.selectedSector"
          :rules="{ required: true }"
          :disabled="disableSector"
          defaultText="Selecione"
          :unit-health="form.selectedUnity"
          :permission="PERMISSION_EXAM_SCHEDULE_PER_DAY_SHOWS_SMO_ID"
          class="inputitem"
          @change="clearDependenciesSector"
        />
      </div>

      <div class="selectinput">
        <RgSelectScaleType
          id="scale-type"
          v-model="form.selectedScale"
          :disabled="!hideSector"
          :unit-health-id="form.selectedUnity"
          class="inputitem"
          label="Tipos de Escala"
          default-text="Todas"
        />
      </div>

      <div class="selectinput">
        <RgSelectProceduresFilter
          id="procedures-filter"
          ref="comboProcedures"
          v-model="form.exe_codigo_procedimento"
          class="inputitem"
          label="Procedimento"
          :sector-id="Number(form.selectedSector)"
          :disabled="!form.selectedSector"
          default-text="Todos"
        />
      </div>

      <div class="selectinput">
        <RgInputDate
          id="date"
          ref="date"
          v-model="form.date"
          :rules="{ required: true }"
          data-id="data"
          label="Data"
          class="inputitem"
        />
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";

import {
  RgSelectUnithealth,
  RgSelectSector,
  RgInputDate,
} from "~tokio/primitive";

import {
  RgSelectScaleType,
  RgSelectProceduresFilter,
} from "$exam/submodules/schedule/component/";

export default {
  name: "ExamScheduleFilterPerDay",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgInputDate,
    RgSelectScaleType,
    RgSelectProceduresFilter,
  },

  data() {
    return {
      PERMISSION_EXAM_SCHEDULE_PER_DAY_SHOWS_SMO_ID: 245,
      form: {
        selectedUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        exe_codigo_procedimento: null,
        selectedScale: null,
        date: moment().format("DD/MM/YYYY"),
        selectedHealthPlan: null,
      },
    };
  },

  computed: {
    disableSector() {
      return this.form.selectedUnity <= 0;
    },
    hideSector() {
      return this.form.selectedUnity > 0;
    },
  },

  watch: {
    "form.date"(value) {
      this.setFormDate(value);
    },
  },

  mounted() {
    this.fillFilter();
  },

  methods: {
    clearDependenciesSector(pValue) {
      if (!pValue) {
        this.form.selectedScale = null;
      }
    },

    setFormDate(value) {
      this.$store.commit("Exam/Schedule/SET_FILTER_DATE", value);
    },

    getForm() {
      return this.form;
    },

    getVariables() {
      return {
        arrFormData: {
          uns_id: this.form.selectedUnity,
          set_id: this.form.selectedSector,
          ees_id: this.form.selectedScale,
          eha_data_exame: this.form.date,
          limiteFim: 1000000,
          limiteInicio: 0,
          stp_id: this.form.exe_codigo_procedimento,
        },
      };
    },

    async fillFilter() {
      try {
        const filter = this.$store.getters[
          "Exam/Schedule/GET_FILTER_EXAM_PER_DAY"
        ];

        if (filter) {
          this.$loader.start("Carregando dados da busca de exames");

          this.form.date = filter.date;
          this.form.selectedUnity = filter.selectedUnity;
          this.form.selectedSector = filter.selectedSector;
          this.form.selectedScale = filter.selectedScale;

          this.form.exe_codigo_procedimento = filter.exe_codigo_procedimento;

          return true;
        }

        this.form.selectedUnity = this.$store.getters[
          "Login/GET_UNIT_HEALTH_ID"
        ];
      } catch (Err) {
        console.error(Err);
        this.$toaster.warning("Erro ao carregar os dados da navegação");
        return false;
      } finally {
        this.$loader.finish();
      }
    },

    cleanFilter() {
      this.form = {
        selectedUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        exe_codigo_procedimento: null,
        selectedScale: null,
        date: moment().format("DD/MM/YYYY"),
        selectedHealthPlan: null,
      };
      this.$refs.RgSelectSector.cleanValidate();
    },
  },
};
</script>
