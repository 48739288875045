<template>
  <!-- //revisar linhas n usadas -->
  <Modulebox
    ref="modulebox"
    title="Saída de Produtos e Medicamentos"
    class="pharmacy-central-output"
  >
    <div class="pharmacy-central-output-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Farmácia de destino" class="register-form">
          <section class="pharmacy-central-output-purchase-data-form">
            <RgSelectUnithealthPharmacy
              id="unit-health "
              ref="unitHealth"
              v-model="form.unitHealth"
              :disabled="mutableProductList.length > 0 || disabledByModal"
              :class="{ disable: mutableProductList.length > 0 }"
              :rules="{ required: true }"
              @change="selectedDestinyUnitHealth"
            />
            <RgSelectPharmacy
              id="pharmacy "
              ref="pharmacy"
              v-model="form.pharmacy"
              :unitHealthId="form.unitHealth"
              viewPharmacyType
              :disabled="
                !form.unitHealth ||
                mutableProductList.length > 0 ||
                disabledByModal
              "
              :class="{
                disable: !form.unitHealth || mutableProductList.length > 0,
              }"
              :rules="{ required: true }"
              @change="selectedDestinyPharmacy"
            />
            <RgComboboxPharmacyTypeTransport
              id="type"
              ref="type"
              v-model="form.type"
              label="Tipo de Saída"
              :disabled="mutableProductList.length > 0 || disabledByModal"
              :class="{
                disable: mutableProductList.length > 0,
              }"
              :rules="{ required: true }"
              @change="selectedProductType"
            />
            <RgInput
              id="remittance "
              v-model="form.remittance"
              label="Guia de Remessa"
              placeholder="aaaaa00000"
              :maxlength="10"
              :disabled="true"
              :class="{ disable: true }"
              :rules="{ required: true }"
            />
            <RgInputDate
              id="purchase-data "
              ref="purchaseData"
              v-model="form.purchaseData"
              label="Data da Saída"
              :max-date="new Date()"
              :disabled="blockOutputDate === '1' || printed || disabledByModal"
              :class="{ disable: blockOutputDate === '1' || printed }"
              :rules="{ required: true }"
            />
          </section>
        </FormBase>

        <FormBase
          title="Medicamentos e ou Produtos"
          class="pharmacy-central-output-destination-pharmacy-form"
        >
          <div class="tooltips">
            <Tooltip class="tooltip-exit-type">
              <IconLightBulbHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>{{ messageTooltipExitType }}</span>
                </div>
              </div>
            </Tooltip>
            <Tooltip class="tooltip-print">
              <IconExclamationHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Ao Imprimir a Guia de remessa, a Saída do Produto ou
                    Medicamento é registrada automaticamente. O registro salvo
                    pode ser visualizado na tela de busca de movimentações</span
                  >
                </div>
              </div>
            </Tooltip>
          </div>
          <section
            class="pharmacy-central-output-origin-form"
            :class="{ 'form-collumns': !isBatch }"
          >
            <RgSelectUnithealthPharmacy
              id=" origin-unit-health"
              ref="originUnitHealth"
              v-model="form.originUnitHealth"
              :disabled="
                !isBatch || mutableProductList.length > 0 || disabledByModal
              "
              :class="{
                disable: !isBatch || mutableProductList.length > 0,
              }"
              :rules="{ required: true }"
              label="Unidade de Saúde (Origem)"
              @change="selectedOriginUnitHealth"
            />

            <RgSelectPharmacy
              id="origin-pharmacy "
              ref="originPharmacy"
              v-model="form.originPharmacy"
              :unitHealthId="form.originUnitHealth"
              :userId="USER_LOGIN_ID"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              byUser
              viewPharmacyType
              :disabled="
                !form.originUnitHealth ||
                !isBatch ||
                mutableProductList.length > 0 ||
                disabledByModal
              "
              :class="{
                disable:
                  !form.originUnitHealth ||
                  !isBatch ||
                  mutableProductList.length > 0,
              }"
              :rules="{ required: true }"
              label="Farmácia (Origem)"
              @change="selectedOriginPharmacy"
            />
          </section>
          <section
            class="pharmacy-central-output-products-form"
            :class="{ 'form-collumns': !isBatch }"
          >
            <RgSelectProduct
              id=" product"
              ref="product"
              v-model="form.product"
              :perClient="!isBatch"
              :pharmacyId="Number(form.originPharmacy)"
              :typeMovimentation="1"
              :rules="{ required: true }"
              :disabled="
                !form.originPharmacy || !isBatch || printed || disabledByModal
              "
              :class="{ disable: !form.originPharmacy || !isBatch || printed }"
              @change="selectedProduct"
            />
            <RgSelectTypePresentation
              id="presentation"
              ref="presentation"
              v-model="form.presentation"
              label="Apresentação"
              :disabled="true"
              :class="{ disable: true }"
              :rules="{ required: true }"
            />
            <RgSelectLotExpiration
              v-if="stockLotExpiration"
              id="batch"
              ref="batch"
              v-model="form.batch"
              :disabled="
                !form.product || !isBatch || printed || disabledByModal
              "
              :class="{ disable: !form.product || !isBatch || printed }"
              :pharmacyId="Number(form.originPharmacy)"
              :productId="Number(form.product)"
              :openOnlyTop="!isBatch"
              :blnSomenteDisponiveis="isBatch"
              :rules="{ required: true }"
              @change="selectedBatch"
            />
            <div v-else></div>
            <RgInput
              id=" inventory"
              ref="inventory"
              v-model="form.inventory"
              :rules="{ required: true }"
              label="Estoque"
              :disabled="true"
              readonly
              placeholder="000000000"
              :class="{ disable: true }"
              :maxlength="10"
            />
            <RgInputNumber
              id="amount-dismissed "
              ref="amountDismissed"
              v-model="form.amountDismissed"
              :rules="{ required: true, fn: validateAmountDismissed }"
              label="Quantidade Dispensada"
              placeholder="000000000"
              :readonly="printed"
              :disabled="disabledByModal"
              :class="{ disable: printed }"
              :maxlength="10"
            />

            <div v-if="isBatch" class="actions">
              <RgCleanButton
                id="clean-btn"
                :disabled="printed || disabledByModal"
                @click="openModalConfirmField"
              />
              <RgAddButton
                id="add-btn"
                title="Adicionar Produto/Medicamento"
                :disabled="printed || disabledByModal"
                @click="openModalConfirmOutput"
              />
            </div>
          </section>

          <div v-if="isBatch" class="pharmacy-central-output-table">
            <hr class="hr" />

            <SmartTable
              ref="smartTable"
              name="PharmacyProductType"
              :columns="COLUMNS"
              :body="mutableProductList"
              :total="mutableProductList.length"
              :showPagination="false"
              :initial-columns="6"
              toggleSelected
              @getLine="selectLine"
            >
              <div slot="top-buttons" class="top-buttons">
                <RgLessButton
                  id="less-btn"
                  title="Excluir"
                  small
                  :disabled="disabledByModal || !hasSelectedLine"
                  :class="{
                    disable: !hasSelectedLine,
                  }"
                  @click="openModalDeleteMovement"
                />
                <RgPrinterButton
                  id=" printer-btn"
                  label="Guia de Remessa"
                  title="Imprimir Guia de Remessa"
                  small
                  :disabled="mutableProductList.length === 0 || disabledByModal"
                  :class="{
                    disable: mutableProductList.length === 0,
                  }"
                  @click="printerOutput"
                />
              </div>
            </SmartTable>
          </div>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-central-output-footer">
      <MediumButton
        id="cancel-btn"
        :backgroundColor="isBatch ? '#53B1D8' : '#fb7e36'"
        label="Voltar"
        :disabled="disabledByModal"
        @click="cancel"
      />
      <MediumButton
        v-if="!isBatch"
        id="save-btn"
        label="Salvar"
        medium
        :disabled="disabledByModal"
        @click="saveOutput"
      />
    </div>

    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalConfirmOutput"
      subtitle="A operação não poderá ser desfeita. Deseja realizar o registro de Saída?"
      :message="
        this.product +
        ' - ' +
        this.lotExpirationDate +
        ' - ' +
        this.form.amountDismissed
      "
      title="Registrar Saída de Produtos"
      yes-label="Salvar"
      no-label="Cancelar"
      buttonsRight
      @getYes="saveOutput"
      @getOut="closeModalConfirmOutput"
      @close="closeModalConfirmOutput"
    />

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteMovement"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgCleanButton,
  RgAddButton,
  RgLessButton,
  MediumButton,
  RgInputNumber,
  Tooltip,
  IconExclamationHelper,
  IconLightBulbHelper,
  IconDanger,
  RgPrinterButton,
  ModalConfirmFieldClearing,
  ModalConfirmDefault,
  ModalConfirmDeletion,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";
import { mapGetters } from "vuex";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgComboboxPharmacyTypeTransport from "$pharmacy/common/combobox/rg-combobox-pharmacy-type-transport/RgComboboxPharmacyTypeTransport";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration.vue";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";

import DeliveryNoteHTML from "$pharmacy/submodules/central/html/DeliveryNoteHTML";

const FORM = {
  id: null,
  unitHealth: null,
  pharmacy: null,
  type: null,
  remittance: null,
  purchaseData: moment().format("DD/MM/YYYY"),
  originUnitHealth: null,
  originPharmacy: null,
  product: null,
  presentation: null,
  batch: null,
  lot: null,
  expirationDate: null,
  inventory: null,
  amountDismissed: null,
};

export default {
  name: "PharmacyCentralOutput",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectTypePresentation,
    RgSelectPharmacy,
    RgSelectProduct,
    RgComboboxPharmacyTypeTransport,
    RgSelectUnithealthPharmacy,
    RgInput,
    RgInputDate,
    RgInputNumber,
    RgCleanButton,
    RgAddButton,
    RgLessButton,
    MediumButton,
    SmartTable,
    Tooltip,
    IconExclamationHelper,
    IconLightBulbHelper,
    IconDanger,
    RgSelectLotExpiration,
    RgPrinterButton,
    ModalConfirmFieldClearing,
    ModalConfirmDefault,
    ModalConfirmDeletion,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      selectedLine: null,
      selectedIndex: null,
      outputType: null,
      code: null,
      product: null,
      presentationType: null,
      remittance: null,
      modalConfirmField: false,
      modalConfirmOutput: false,
      printed: false,
      originPharmacy: null,
      destinyPharmacy: null,
      batchExpiry: null,
      blockOutputDate: null,
      lotExpirationDate: null,
      lotMinLimitDateSelect: null,
      destinyUnitHealth: null,
      originUnitHealth: null,
      modalDeleteMovement: false,
    };
  },

  computed: {
    ...mapGetters({
      clientName: "Login/GET_CLIENT_NAME",
      userName: "Login/GET_USER_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
    }),
    messageTooltipExitType() {
      return this.isBatch
        ? 'Para realizar Saídas Individuais, basta selecionar um registro de entrada na tela de busca e apertar o botão “Registrar Saída"'
        : "Para realizar Saídas em lote, basta apertar o botão “Registrar Saída” na tela de busca de movimentações sem selecionar um registro de entrada";
    },
    isBatch() {
      return this.$route.name === "pharmacy.central.output.batch";
    },
    preferenceStockType() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.frp_tipo_estoque"
      ];
    },
    preferenceValidateProductExitDuplication() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.rbt_validacao_saida_produto"
      ];
    },
    stockLotExpiration() {
      return this.preferenceStockType === "2";
    },
    disabledByModal() {
      return this.modalConfirmField || this.modalConfirmOutput;
    },
    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },
    propsModalConfirmDeletion() {
      const confirm = () => this.deleteOutput();
      const msgSuccess = "Saída excluída com sucesso";
      const show = this.modalDeleteMovement;
      const noReason = true;
      const bodyText = `${this.selectedLine?.name} - ${this.selectedLine?.batch} - ${this.selectedLine?.amountDismissed}`;
      const title = `Excluir Registro de Saída`;
      const message = `O registro selecionado será removido das saídas realizadas na Farmácia`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
  },

  watch: {
    "form.unitHealth"(pValue) {
      if (!pValue && pValue === null && this.isBatch) {
        this.form.pharmacy = null;
        this.form.product = null;
      }
    },
    "form.pharmacy"(pValue) {
      if (!pValue && pValue === null && this.isBatch) {
        this.form.product = null;
      }
    },
    "form.originUnitHealth"(pValue) {
      if (!pValue && pValue === null && this.isBatch) {
        this.form.originPharmacy = null;
        this.form.product = null;
      }
    },
    "form.originPharmacy"(pValue, pPrev) {
      if (
        (!pValue && pValue === null && this.isBatch) ||
        (pValue !== pPrev && pPrev !== null)
      ) {
        this.form.product = null;
      }
    },

    "form.product"(pValue) {
      if (!pValue && pValue === null && this.isBatch) {
        this.form.amountDismissed = null;
        this.form.batch = null;
      }
    },
    "form.batch"(pValue) {
      if (!pValue && pValue === null) {
        this.form.inventory = null;
      } else {
        this.getInventory();
      }
    },
  },
  async mounted() {
    const data = this.$store.getters["Pharmacy/Central/GET_SELECTED_CENTRAL"];
    const filters = this.$store.getters["Pharmacy/Central/GET_FILTER_CENTRAL"];
    this.batchExpiry = this.$store.getters["Login/GET_PREFERENCES"][
      "tViewFarmacia.con_estado_combo_lote"
    ];
    this.blockOutputDate = this.$store.getters["Login/GET_PREFERENCES"][
      "tViewFarmacia.trs_bloquear_data_saida"
    ];

    if (!this.isBatch && data && filters) {
      const batch = data.lote.split("|");
      this.form.originUnitHealth = filters.unitHealth;
      this.form.originPharmacy = filters.pharmacy;
      this.form.product = data.mpd_id;
      this.form.batch = batch[0].trim();
    }

    await this.getRemittanceGuide();
  },
  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "name", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "presentation",
        align: "left",
        active: "true",
      },
      { name: "Lote-Validade", key: "batch", align: "left", active: "true" },
      {
        name: "Quantidade Dispensada",
        key: "amountDismissed",
        align: "left",
        active: "true",
      },
      {
        name: "Data da Saída",
        key: "purchaseData",
        align: "left",
        active: "true",
      },
      { name: "Tipo de Saída", key: "type", align: "left" },
      { name: "Unid. de Saúde Origem", key: "originUnitHealth", align: "left" },
      { name: "Farmácia Origem", key: "originPharmacy", align: "left" },
      {
        name: "Unid. de Saúde Destino",
        key: "destinyUnitHealth",
        align: "left",
      },
      { name: "Farmácia Destino", key: "destinyPharmacy", align: "left" },
    ];
    this.USER_LOGIN_ID = this.$store.getters["Login/GET_USER_ID"];
    this.CENTRAL_PHARMACY_TYPE = 1;
  },
  destroyed() {
    this.$store.commit("Pharmacy/Central/UNSELECT_PHARMACY_CENTRAL");
  },
  methods: {
    selectLine(pValue, pIndex) {
      this.selectedLine = pValue;
      this.selectedIndex = pIndex;
    },
    openModalDeleteMovement() {
      this.modalDeleteMovement = true;
    },
    closeModalDeleteMovement() {
      this.modalDeleteMovement = false;
      this.selectedLine = null;
      this.selectedIndex = null;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    async deleteOutput() {
      try {
        this.$loader.start();

        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_OUTPUT",
          Number(this.selectedLine.id),
        );

        this.removeProductTable(this.selectedLine.id);

        this.$toaster.success("Saída excluída com sucesso");
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a saída",
        );
      } finally {
        this.$loader.finish();
      }
    },
    selectedOriginUnitHealth(pValue) {
      if (pValue) {
        this.originUnitHealth = pValue.label;
      } else {
        this.originUnitHealth = null;
      }
    },
    selectedDestinyUnitHealth(pValue) {
      if (pValue) {
        this.destinyUnitHealth = pValue.label;
      } else {
        this.destinyUnitHealth = null;
      }
    },
    pushRouter() {
      const params = {
        remittance: this.form.remittance,
      };

      this.$router.push({
        name: "pharmacy.central",
        params,
      });
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (
          pData.productId === this.form.product &&
          pData.batch === this.lotExpirationDate
        )
          return true;
      });

      return filterDuplicate;
    },
    async openModalConfirmOutput() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }
      const existsDuplicate = this.verifyDuplicate();
      if (this.form.originPharmacy === this.form.pharmacy) {
        this.$toaster.error(
          "A Farmácia Destino não pode ser igual a Farmácia Origem.",
          "Não foi possível registrar a saída do produto",
        );
        return;
      }

      if (
        existsDuplicate?.length > 0 &&
        this.preferenceValidateProductExitDuplication === "0"
      ) {
        this.$toaster.info(
          "Escolha o mesmo produto com outro Lote - Validade para registrar a saída.",
          "O produto já faz parte desse registro de Saída",
        );
        return false;
      }

      this.modalConfirmOutput = true;
    },
    closeModalConfirmOutput() {
      this.modalConfirmOutput = false;
    },
    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },
    selectedProductType(pValue) {
      if (pValue) {
        this.outputType = pValue[0].text;
      } else {
        this.outputType = null;
      }
    },
    selectedBatch(pValue) {
      if (pValue) {
        const batch = pValue.label.split("|");
        this.form.lot = batch[0].trim();
        this.form.expirationDate = batch[1].trim();
        this.lotExpirationDate = pValue.label;
        this.lotMinLimitDateSelect = pValue.trs_data;
      } else {
        this.form.lot = null;
        this.form.expirationDate = null;
        this.lotExpirationDate = null;
        this.lotMinLimitDateSelect = null;
      }
    },
    async getInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdProduto: this.form.product,
          intIdFarmacia: this.form.originPharmacy,
          strLote: this.form.batch,
        };

        const data = await this.$store.dispatch(
          "Pharmacy/Central/GET_QUANTITY_INVENTORY",
          variables,
        );
        this.form.inventory = data[0].estocado;
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Erro ao carregar o estoque",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async getRemittanceGuide() {
      try {
        const result = await this.$store.dispatch(
          "Pharmacy/Central/GENERATE_REMITTANCE_GUIDE",
        );
        if (result && result.length > 0) {
          this.form.remittance = result.toUpperCase();
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao gerar remessa");
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    parseDataSave() {
      const variables = {
        trs_id_usuarios: this.USER_LOGIN_ID,
        trs_id_farmacias_destino: this.form.pharmacy,
        trs_id_tipos_transportes: this.form.type,
        trs_guia_remessa: this.form.remittance,
        trs_data: this.form.purchaseData,
        trs_id_farmacias_origem: this.form.originPharmacy,
        trs_id_municipios_produtos: this.form.product,
        trs_lote: this.form.lot,
        trs_validade_lote: this.form.expirationDate,
        trs_quantidade: this.form.amountDismissed.replace(/[.]/g, ""),
      };
      return variables;
    },
    async saveOutput() {
      try {
        const initialDate = moment(this.lotMinLimitDateSelect, "DD/MM/YYYY");
        const finalDate = moment(this.form.purchaseData, "DD/MM/YYYY");
        const initialDateIsAfter = initialDate.isAfter(finalDate);

        if (initialDateIsAfter) {
          this.$toaster.info(
            `Escolha uma data de saída após o dia ${this.lotMinLimitDateSelect}`,
            "A data de saída é anterior à data de entrada do produto",
          );
          return null;
        }

        this.$loader.start();
        const variables = this.parseDataSave();

        const result = await this.$store.dispatch(
          "Pharmacy/Central/REGISTER_OUTPUT",
          variables,
        );

        this.$toaster.success("Saída registrada com sucesso");

        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.originPharmacy,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (data !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto.",
            "O estoque mínimo foi alcançado",
          );
        }

        if (!this.isBatch) {
          this.pushRouter();
        } else {
          this.insertProductTable(variables, result.dados);
          this.cleanWithRemittanceGuide();
          this.modalConfirmOutput = false;
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error),
          "Erro ao registrar saída",
        );
      } finally {
        this.$loader.finish();
      }
    },
    insertProductTable(pData, pId) {
      const data = {
        id: pId,
        code: this.code,
        name: this.product,
        productId: this.form.product,
        presentation: this.presentationType,
        type: this.outputType,
        batch: this.lotExpirationDate,
        amountDismissed: pData.trs_quantidade.replace(/[.]/g, ""),
        inventory: 0,
        trs_id_farmacias_origem: pData.trs_id_farmacias_origem,
        trs_id_municipios_produtos: pData.trs_id_municipios_produtos,
        trs_lote: pData.trs_lote,
        purchaseData: pData.trs_data,
        originUnitHealth: this.originUnitHealth,
        originPharmacy: this.originPharmacy,
        destinyUnitHealth: this.destinyUnitHealth,
        destinyPharmacy: this.destinyPharmacy,
      };

      this.mutableProductList.push(data);
    },
    removeProductTable(pId) {
      const index = this.mutableProductList.findIndex(
        (item) => item.id === pId,
      );
      if (index !== -1) {
        this.mutableProductList.splice(index, 1);
      }
    },
    async printerOutput() {
      try {
        this.$loader.start();
        const variables = {
          strGuiaRemessa: this.form.remittance,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Central/PRINTER_OUTPUT",
          variables,
        );

        this.printed = true;
        const dataRequest = {};

        dataRequest.origem = this.originPharmacy;
        dataRequest.destino = this.destinyPharmacy;
        dataRequest.trs_data = this.form.purchaseData;
        dataRequest.trs_guia_remessa = this.form.remittance;
        dataRequest.usu_nome = this.userName;

        const html = DeliveryNoteHTML(
          dataRequest,
          result,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async selectedProduct(pProduct) {
      if (pProduct) {
        this.code = pProduct.mpd_codigo;
        this.product = pProduct.mpd_novo_principio_ativo;
        this.presentationType = pProduct.mtu_novo_nome;
        this.form.presentation = pProduct.mtu_id;
      } else {
        this.code = null;
        this.product = null;
        this.presentationType = null;
        this.form.presentation = null;
      }
    },
    selectedOriginPharmacy(pValue) {
      if (pValue) {
        this.originPharmacy = pValue.label.replace(
          /- SATÉLITE| - CENTRAL/gi,
          "",
        );
      } else {
        this.originPharmacy = null;
      }
    },
    selectedDestinyPharmacy(pValue) {
      if (pValue) {
        this.destinyPharmacy = pValue.label.replace(
          /- SATÉLITE| - CENTRAL/gi,
          "",
        );
      } else {
        this.destinyPharmacy = null;
      }
    },
    validateAmountDismissed(pValue, pErrors) {
      if (pValue && Number(pValue) < 1) {
        pErrors.push("O valor não pode ser menor do que 1");
        return false;
      }
      if (
        pValue &&
        Number(pValue.replace(/[.]/g, "")) >
          this.form.inventory.replace(/[.]/g, "")
      ) {
        pErrors.push(
          `O valor não pode ser maior do que ${this.form.inventory}.`,
        );
        return false;
      }

      return true;
    },
    cleanWithRemittanceGuide() {
      this.form.product = null;
      this.form.presentation = null;
      this.form.batch = null;
      this.form.lot = null;
      this.form.expirationDate = null;
      this.form.inventory = null;
      this.form.amountDismissed = null;
      this.cleanFieldsValidation();
    },
    cleanForm() {
      if (this.mutableProductList.length === 0) {
        this.form.product = null;
        this.form.presentation = null;
        this.form.batch = null;
        this.form.lot = null;
        this.form.expirationDate = null;
        this.form.inventory = null;
        this.form.amountDismissed = null;
        this.form.unitHealth = null;
        this.form.pharmacy = null;
        this.form.type = null;
        this.form.originUnitHealth = null;
        this.form.originPharmacy = null;
      } else {
        this.form.product = null;
        this.form.presentation = null;
        this.form.batch = null;
        this.form.lot = null;
        this.form.expirationDate = null;
        this.form.inventory = null;
        this.form.amountDismissed = null;
      }
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealth",
        "pharmacy",
        "type",
        "purchaseData",
        "originUnitHealth",
        "originPharmacy",
        "product",
        "presentation",
        "batch",
        "inventory",
        "amountDismissed",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cancel() {
      this.$router.push({ name: "pharmacy.central" });
    },
  },
};
</script>
