<template>
  <div class="hospitalization-search">
    <RgSearch
      ref="RgSearch"
      v-model="mutableHospitalizationList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :new-url="'/hospitalization/hospitalizations/search/details'"
      :item-height="78"
      :max-register="20"
      :disabled="this.disabledByModal"
      result-title="Lista de Internações"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="hospitalization-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-health"
              ref="unitHealth"
              v-model="form.selectUnity"
              permission="internacao.internacao"
              default-text="Todas"
              empty-default-text="Nenhuma unidade disponível"
              class="inputitem"
              searchUnitById
              :unitHealthId="form.selectUnity"
              disabled
            />
          </div>

          <div v-show="hideSector" class="selectinput">
            <RgSelectSector
              id="sector"
              ref="sector"
              v-model="form.selectedSector"
              :permission="SECTOR_PERMISSION"
              :unit-health="selectUnity"
              default-text="Todos"
              empty-default-text="Nenhum setor disponível"
              class="inputitem"
            />
          </div>

          <div v-show="hideLocale" class="selectinput">
            <RgSelectPlaces
              v-if="hideLocale"
              id="locale"
              v-model="form.selectedLocale"
              :sector="selectedSector"
              default-text="Todos"
              empty-default-text="Nenhum local disponível"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInput
              id="patient"
              v-model="form.patientNome"
              :class="{ disable: form.indigent }"
              :disabled="form.indigent"
              data-id="paciente"
              data-item="paciente"
              label="Paciente"
              placeholder="Digite o nome do paciente"
              class="inputitem"
              type="text"
            />
          </div>

          <div class="selectinput toggle">
            <span :class="{ active: form.indigent }" class="text"
              >Paciente não identificado</span
            >
            <RgToggleButton
              id="indigent"
              class="input-toggle"
              :valueSync="true"
              :value="form.indigent"
              @change="handleReceiveIndigent"
            />
          </div>

          <div class="selectinput">
            <div class="numbers">
              <RgInput
                id="record-number"
                v-model="form.recordNumber"
                data-id="prontuario"
                label="Prontuário"
                class="record"
                :rules="{ number: true }"
                placeholder="Digite o prontuário"
              />
              <RgInput
                id="hospitalization-number"
                v-model="form.hospitalizationNumber"
                data-id="hospitalization-number"
                label="Internação"
                placeholder="Digite o número"
                :rules="{ number: true }"
                class="hospitalization-number"
              />
            </div>
          </div>

          <div class="selectinput border-top">
            <RgRadioCustom
              id="out"
              ref="out"
              v-bind="propsRadioCustomOut"
              :refreshValue="form.outStatus"
              multSelect
              class="customradio out"
              label="Listar Internações"
              @input="onInputRadioCustomOut"
            />
          </div>

          <div
            v-show="noWayOut && !noWayOutAndwithOutput"
            class="selectinput border-top"
          >
            <RgRadioCustom
              id="situation"
              ref="situation"
              v-bind="propsRadioCustomBedSituation"
              multSelect
              class="customradio situation"
              label="Situação do Leito"
              @input="onInputRadioCustomBedSituation"
            />
          </div>

          <div class="selectinput border-top">
            <RgRadioCustom
              v-show="withOutput || noWayOutAndwithOutput || emptyValue"
              id="date-type"
              ref="type"
              v-bind="propsRadioCustom"
              class="customradio dateType"
              label="Tipo de Data"
              @input="onInputRadioCustom"
            />
          </div>

          <span
            v-show="noWayOut || withOutput || entryDateType || exitDateType"
            class="filter-title"
          >
            {{ periodDate }}
          </span>

          <div class="selectinput">
            <div v-show="noWayOut || entryDateType" class="status">
              <div class="date">
                <RgInputDate
                  id="initial-date-noway"
                  ref="entryInitialDateNoWayOut"
                  v-model="form.entryInitialDate"
                  :rules="{ fn: verifyInitialDate }"
                  data-id="data-inicio-entrada"
                  label="Data Inicial"
                  class="date"
                />

                <RgInputDate
                  id="final-date-noway"
                  ref="entryFinalDateNoWayOut"
                  v-model="form.entryFinalDate"
                  data-id="data-final-entrada"
                  label="Data Final"
                  class="date"
                />
              </div>
            </div>

            <div v-show="exitDateType" class="status">
              <div class="date">
                <RgInputDate
                  id="initial-date"
                  ref="entryInitialDateEntryDateType"
                  v-model="form.outInitialDate"
                  :rules="{ fn: verifyInitialDate }"
                  data-id="data-inicio-entrada"
                  label="Data Inicial"
                />
                <RgInputDate
                  id="final-date"
                  ref="entryFinalDateEntryDateType"
                  v-model="form.outFinalDate"
                  data-id="data-final-entrada"
                  label="Data Final"
                />
              </div>
            </div>
          </div>

          <div class="selectinput">
            <RgSuggestCbo
              id="cbo"
              ref="cbo"
              v-model="form.cboCode"
              :employeeId="form.int_id_funcionarios"
              label="Ocupação"
              class="inputitem"
              @selected="selectingCbo"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              id="employee"
              ref="responsible"
              v-model="form.responsibleEmployee"
              :sector-required="false"
              :ocuppation-id="form.int_id_ocupacoes_cbo"
              label="Profissional Solicitante"
              class="inputitem"
              @selected="selectingResponsibleEmployee"
            />
          </div>

          <div class="selectinput">
            <RgSuggestTypeBed
              id="selected-type-bed"
              ref="typeBed"
              v-model="form.selectedBedType"
              class="inputitem"
              @selected="selectingBedInfo"
            />
          </div>

          <div class="selectinput">
            <RgRadioCustom
              id="status"
              ref="status"
              v-bind="propsRadioCustomBedType"
              multSelect
              class="customradio"
              label="Listar por Leito"
              @input="onInputRadioCustomBedType"
            />
          </div>

          <div class="selectinput">
            <RgComboboxHospitalizationType
              id="hospitalization-type"
              v-model="form.int_id_internacoes_tipos_internacao"
              :default-text="'Todos'"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxHospitalizationServiceCharacter
              id="hospitalization-service-character"
              v-model="form.int_id_internacoes_caracteres"
              :default-text="'Todos'"
              class="inputitem"
            />
          </div>
        </div>
      </div>

      <!-- START RESULT -->
      <div class="hospitalization-search-result">
        <div
          v-for="(item, index) in mutableHospitalizationList"
          :key="index"
          class="itemcolor"
        >
          <div class="item unselected">
            <RgTypePatient
              :pac-id="isPatient(item)"
              :sex="isPatient(item) ? patientSex(item) : indigentSex(item)"
              class="type-patient"
            />

            <div class="slot">
              <div class="limittext">
                Número da Internação:
                <span :title="item.int_numero" class="textbolder">
                  {{ item.int_numero }}
                </span>
              </div>

              <div class="limittext">
                Paciente:
                <span :title="item.paciente" class="textbolder">
                  {{ item.paciente }}
                </span>
              </div>

              <div class="limittext">
                Prontuário Único:
                <span :title="item.pac_prontuario_unico" class="textbolder">
                  {{
                    item.pac_prontuario_unico
                      ? item.pac_prontuario_unico
                      : " Não Informado"
                  }}
                </span>
              </div>
            </div>

            <div class="slot">
              <div class="limittext">
                Unidade de Saúde:
                <span :title="item.uns_nome" class="textbolder">
                  {{ item.uns_nome }}
                </span>
              </div>

              <div class="limittext">
                Setor:
                <span :title="item.set_nome" class="textbolder">
                  {{ item.set_nome }}
                </span>
              </div>

              <div class="limittext">
                Local de Atendimento:
                <span :title="item.lca_nome" class="textbolder">
                  {{ item.lca_nome }}
                </span>
              </div>
            </div>

            <div class="slot">
              <div class="limittext">
                Leito:
                <span :title="item.inl_nome" class="textbolder">
                  {{ item.inl_nome }}
                </span>
              </div>

              <div class="limittext lineheight">
                Situação do Leito:
                <span :title="item.isl_nome" class="textbolder">
                  {{ item.isl_nome }}
                </span>
              </div>

              <div
                v-if="item.isl_id !== 2 && oculterData(item)"
                class="limittext lineheight"
              >
                Tempo de Internação:
                <span
                  :title="item.tempo_internado_saida || item.tempo_internado"
                  class="textbolder"
                >
                  {{ item.tempo_internado_saida || item.tempo_internado }}
                </span>
              </div>
            </div>

            <div class="slot-4">
              <RgDropdown
                id="options-printer"
                ref="dropDownPrinterOptions"
                :item="item"
                :item-id="item.int_id"
                :show-up="toShowUp(index)"
                :action-options="itemActionOptionsPrinter(item)"
                class="dropdown"
                small
              >
                <IconPrinter slot="icon" />
              </RgDropdown>

              <RgDropdown
                v-show="hasPepIntegration"
                id="options-pep"
                ref="dropDownPepOptions"
                small
                :class="{ 'disable unselect': enableDisablePep(item) }"
                :disabled="enableDisablePep(item)"
                :item="item"
                :item-id="item.int_id"
                :show-up="toShowUp(index)"
                :action-options="itemActionOptionsPep(item)"
                class="dropdown"
                :backgroundColor="'#ffffff'"
                notBackgroundWhenDisabled
              >
                <IconPep slot="icon" />
              </RgDropdown>

              <RgEditButton
                id="edit-hospitalization"
                :class="{ 'disable unselect': enableDisableEdit(item) }"
                :disabled="enableDisableEdit(item)"
                small
                class="edit"
                title="Editar Internação"
                @click="selectItem(item, index)"
              />

              <RgDropdown
                id="options"
                ref="dropDownHospitalizationOptions"
                :item="item"
                :item-id="item.int_id"
                :show-up="toShowUp(index)"
                :action-options="itemActionOptions(item)"
                class="dropdown"
                small
                rotateIcon
              >
                <IconEllipsis slot="icon" rotate />
              </RgDropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- END RESULT -->
    </RgSearch>

    <ManagerHospitalizationSearch
      ref="manager"
      @disabledByModalComponents="disabledByModalComponents"
      @reSearch="reSearch"
    />
  </div>
</template>

<script>
import HospitalizationOutStatusEnum from "../../component/radio/rg-radio-hospitalization-out/HospitalizationOutInfo";
import BED_STATUS from "../../../common/component/radio/rg-radio-bed-type/getBedType";
import { ToShowUp } from "$hospitalization/common/component/util/ToShowUp";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import RgSuggestCbo from "~tokio/primitive/suggest/rg-suggest-cbo/RgSuggestCbo";
import RgEditButton from "~tokio/primitive/button/rg-edit-button/RgEditButton";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient.vue";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  RgSelectSector,
  RgSelectUnithealth,
  RgSelectPlaces,
  RgInputDate,
  RgSuggestEmployee,
  IconPrinter,
  RgInput,
  RgDropdown,
  RgToggleButton,
  IconEllipsis,
  IconPep,
  RgRadioCustom,
} from "~tokio/primitive";

import {
  RgSuggestTypeBed,
  RgComboboxHospitalizationType,
  RgComboboxHospitalizationServiceCharacter,
} from "../../../common/component";

import { ManagerHospitalizationSearch } from "../../component";

import moment from "moment";

import { mapGetters } from "vuex";

const FORM_FILTER = {
  selectUnity: null,
  selectedSector: null,
  selectedLocale: null,
  patientNome: "",
  recordNumber: null,
  hospitalizationNumber: null,
  outStatus: [HospitalizationOutStatusEnum.SEM_SAIDA],
  bedSituation: null,
  dateType: null,
  entryInitialDate: null,
  entryFinalDate: null,
  outInitialDate: null, // Data inicial com saída
  outFinalDate: null, // Data final com saída
  cboCode: null,
  responsibleEmployee: null,
  int_id_ocupacoes_cbo: null,
  int_id_internacoes_tipos_internacao: null,
  int_id_internacoes_caracteres: null,
  bedStatus: [],
  int_id_funcionarios: null,
  itl_id: null, // Id do Tipo de Leito da Internação
  selectedBedType: null,
  indigent: false,
};
export default {
  name: "HospitalizationSearch",
  components: {
    RgSearch,
    RgSelectSector,
    RgSelectUnithealth,
    RgSelectPlaces,
    RgComboboxHospitalizationType,
    RgInputDate,
    RgInput,
    RgEditButton,
    RgComboboxHospitalizationServiceCharacter,
    RgSuggestTypeBed,
    RgSuggestCbo,
    RgSuggestEmployee,
    RgDropdown,
    IconPep,
    IconPrinter,
    IconEllipsis,
    ManagerHospitalizationSearch,
    RgToggleButton,
    RgTypePatient,
    RgRadioCustom,
  },
  props: {},
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      periodDate: "Período de Entrada",
      mutableHospitalizationList: [],
      showModal: false,
      selectedItem: {},
      showModalInfo: false,
      disabledByModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
      clientId: "Login/GET_USER_ID_CLIENT",
    }),
    propsRadioCustomBedSituation() {
      const list = [
        { title: "Reserva", id: 2 },
        { title: "Ocupado", color: "#fd6d6d", id: 3 },
      ];

      const value = this.form.bedSituation;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustom() {
      const list = [
        { title: " Data de Entrada", color: "#03CE63", id: 1 },
        { title: "Data de Saída", color: "#F96B70", id: 2 },
      ];

      const value = this.form.dateType;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomOut() {
      const list = [
        { title: " Sem Saída", id: 2 },
        { title: "Com Saída", id: 1 },
      ];
      const value = this.form.outStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomBedType() {
      const list = [
        { title: "Extra", id: 1, color: "#56b0c9" },
        { title: "Observação", id: 2, color: "#ffa56a" },
      ];

      const value = this.form.bedStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    selectUnity() {
      return this.form.selectUnity;
    },

    selectedSector() {
      return this.form.selectedSector;
    },

    hideSector() {
      return this.form.selectUnity > 0;
    },

    hideLocale() {
      return this.form.selectUnity > 0 && this.form.selectedSector > 0;
    },

    hasPepIntegration() {
      const ENABLED = "1";

      const preference = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewCadastroInternacoes.internacao_pep"
      ];

      return preference === ENABLED;
    },

    isThereHospitalization() {
      return (
        this.mutableHospitalizationList ||
        this.mutableHospitalizationList.length > 0
      );
    },

    noWayOut() {
      return (
        this.form.outStatus &&
        this.form.outStatus.length === 1 &&
        this.form.outStatus[0] === HospitalizationOutStatusEnum.SEM_SAIDA
      );
    },

    withOutput() {
      return (
        this.form.outStatus &&
        this.form.outStatus.length === 1 &&
        this.form.outStatus[0] === HospitalizationOutStatusEnum.COM_SAIDA
      );
    },

    noWayOutAndwithOutput() {
      return (
        this.form.outStatus &&
        (this.form.outStatus.length >= 2 || this.form.outStatus.length === 0)
      );
    },

    emptyValue() {
      return this.form.outStatus && this.form.outStatus.length === 0;
    },

    entryDateType() {
      return (
        this.form.dateType && this.form.dateType === this.DATE_TYPE.ENTRADA
      );
    },

    exitDateType() {
      return this.form.dateType && this.form.dateType === this.DATE_TYPE.SAIDA;
    },
  },
  watch: {
    "form.indigent"(pValue) {
      if (pValue) {
        this.form.patientNome = "";
      }
    },

    "form.selectUnity"(pValue, pPrev) {
      if (!pValue) {
        this.form.selectedSector = null;
        this.form.selectedLocale = null;
      }
    },

    "form.selectedSector"(pValue, pPrev) {
      if (!pValue) {
        this.form.selectedLocale = null;
      }
    },

    "form.responsibleEmployee"(pValue) {
      if (!pValue) {
        this.form.int_id_ocupacoes_cbo = null;
        this.form.int_id_funcionarios = null;
      }
    },

    "form.outStatus"(pValue) {
      if (this.noWayOut) {
        this.periodDate = "Período de Entrada";

        this.form.dateType = null;

        this.form.entryInitialDate = null;
        this.form.entryFinalDate = null;

        this.form.outInitialDate = null;
        this.form.outFinalDate = null;
      }

      if (this.noWayOutAndwithOutput || this.withOutput) {
        this.form.bedSituation = null;
        this.form.dateType = this.DATE_TYPE.ENTRADA;
      }

      if (this.emptyValue) {
        this.periodDate = "Período";

        this.form.entryInitialDate = moment()
          .subtract(30, "days")
          .format("DD/MM/YYYY");
        this.form.entryFinalDate = moment().format("DD/MM/YYYY");

        this.form.outInitialDate = "";
        this.form.outFinalDate = "";
      }
      this.clearDateValidate();
    },

    "form.dateType"(pValue) {
      if (this.entryDateType) {
        this.periodDate = "Período de Entrada";

        this.form.entryInitialDate = moment()
          .subtract(30, "days")
          .format("DD/MM/YYYY");
        this.form.entryFinalDate = moment().format("DD/MM/YYYY");

        this.form.outInitialDate = "";
        this.form.outFinalDate = "";
      }

      if (this.exitDateType) {
        this.periodDate = "Período de Saída";

        this.form.outInitialDate = moment()
          .subtract(30, "days")
          .format("DD/MM/YYYY");
        this.form.outFinalDate = moment().format("DD/MM/YYYY");

        this.form.entryInitialDate = "";
        this.form.entryFinalDate = "";
      }
      this.clearDateValidate();
    },
  },
  created() {
    this.SECTOR_PERMISSION = 302;
    this.DATE_TYPE = { ENTRADA: 1, SAIDA: 2 };
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  destroyed() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/hospitalizations/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_FILTER_HOSPITALIZATION",
      );
    }
  },
  mounted() {
    this.$store.commit(
      "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_INFO",
    );
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.fillFilter();
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.dateType = item.id;
      } else {
        this.form.dateType = null;
      }
    },
    onInputRadioCustomBedSituation(item) {
      if (item) {
        this.form.bedSituation = item;
      }
    },
    onInputRadioCustomOut(item) {
      if (item) {
        this.form.outStatus = item;
      } else {
        this.form.outStatus = [];
      }
    },
    onInputRadioCustomBedType(item) {
      if (item) {
        this.form.bedStatus = item;
      }
    },
    disabledByModalComponents(disabledByModal) {
      this.disabledByModal = disabledByModal;
      return this.disabledByModal;
    },

    async searchFilter(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Hospitalization/Hospitalizations/SEARCH_HOSPITALIZATION",
          { ...pData },
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    handleReceiveIndigent(pValue) {
      this.form.indigent = pValue;
    },

    generateFilter() {
      const variables = {
        uns_id: this.form.selectUnity ? this.form.selectUnity : null,
        set_id: this.form.selectedSector ? this.form.selectedSector : null,
        lca_id: this.form.selectedLocale ? this.form.selectedLocale : null,
        paciente: this.form.patientNome ? this.form.patientNome : "",
        pac_prontuario_unico: this.form.recordNumber
          ? Number(this.form.recordNumber)
          : null,
        int_numero: this.form.hospitalizationNumber
          ? parseInt(this.form.hospitalizationNumber)
          : null,
        out_status:
          this.form.outStatus && this.form.outStatus.length > 0
            ? this.form.outStatus
            : [1, 2],
        bed_situation:
          this.form.bedSituation && this.form.bedSituation.length > 0
            ? this.form.bedSituation
            : null,
        int_data_inicio: this.form.entryInitialDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.entryInitialDate)
          : null,
        int_data_final: this.form.entryFinalDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.entryFinalDate)
          : null,
        isa_data_inicio: this.form.outInitialDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.outInitialDate)
          : null,
        isa_data_final: this.form.outFinalDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.outFinalDate)
          : null,
        int_id_ocupacoes_cbo: this.form.int_id_ocupacoes_cbo
          ? this.form.int_id_ocupacoes_cbo
          : null,
        int_id_funcionarios: this.form.int_id_funcionarios
          ? this.form.int_id_funcionarios
          : null,
        int_id_internacoes_tipos_internacao: this.form
          .int_id_internacoes_tipos_internacao
          ? this.form.int_id_internacoes_tipos_internacao
          : null,
        int_id_internacoes_caracteres: this.form.int_id_internacoes_caracteres
          ? this.form.int_id_internacoes_caracteres
          : null,
        itl_id: this.form.itl_id ? this.form.itl_id : null,
        inl_observacao:
          this.form.bedStatus &&
          this.form.bedStatus.includes(BED_STATUS.OBSERVACAO)
            ? 1
            : 0,
        inl_extra:
          this.form.bedStatus && this.form.bedStatus.includes(BED_STATUS.EXTRAS)
            ? 1
            : 0,
        indigent: this.form.indigent,
        usu_id: this.userId,
        client_id: this.clientId,
      };

      this.$store.commit(
        "Hospitalization/Hospitalizations/SET_FILTER_HOSPITALIZATION",
        this.form,
      );

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Hospitalization/Hospitalizations/GET_FILTER_HOSPITALIZATION"
        ];

        await this.$refs.unitHealth.doSearch();

        if (existFilterData) {
          this.$loader.start("Carregando dados da busca");

          this.form.selectedSector = existFilterData.selectedSector;
          await this.$refs.sector.doSearch();

          this.form.selectedLocale = existFilterData.selectedLocale;
          this.form.patientNome = existFilterData.patientNome;
          this.form.indigent = existFilterData.indigent;
          this.form.recordNumber = existFilterData.recordNumber;
          this.form.hospitalizationNumber =
            existFilterData.hospitalizationNumber;
          this.form.outStatus = existFilterData.outStatus || [1, 2];
          this.form.entryInitialDate = existFilterData.entryInitialDate;
          this.form.entryFinalDate = existFilterData.entryFinalDate;
          this.form.dateType = existFilterData.dateType;
          this.form.outInitialDate = existFilterData.outInitialDate;
          this.form.outFinalDate = existFilterData.outFinalDate;
          this.form.bedSituation = existFilterData.bedSituation;
          this.form.cboCode = existFilterData.cboCode;

          if (existFilterData.cboCode) {
            const cboCodeAndName = existFilterData.cboCode.split(" - ");

            this.$refs.cbo.forceSelection({
              ocp_codigo: cboCodeAndName[0],
              ocp_nome: cboCodeAndName[1],
            });
          }
          this.form.int_id_ocupacoes_cbo = existFilterData.int_id_ocupacoes_cbo;

          this.form.responsibleEmployee = existFilterData.responsibleEmployee;
          this.$refs.responsible.forceSelection({
            pes_nome: existFilterData.responsibleEmployee,
            vin_id: existFilterData.int_id_funcionarios,
          });
          this.form.int_id_funcionarios = existFilterData.int_id_funcionarios;

          this.form.selectedBedType = existFilterData.selectedBedType;
          this.$refs.typeBed.forceSelection({
            itl_nome: existFilterData.selectedBedType,
            itl_id: existFilterData.itl_id,
          });

          this.form.int_id_internacoes_tipos_internacao =
            existFilterData.int_id_internacoes_tipos_internacao;
          this.form.int_id_internacoes_caracteres =
            existFilterData.int_id_internacoes_caracteres;
          this.form.itl_id = existFilterData.itl_id;
          this.form.bedStatus = existFilterData.bedStatus;

          await this.$refs.RgSearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da busca", Err);
      } finally {
        this.$loader.finish();
      }
    },

    reSearch() {
      this.$refs.RgSearch.performSearch(false, false);
    },

    selectItem(pItem, pIndexItem) {
      this.$router.push({
        name: "hospitalization.hospitalizations.edit-details",
        params: { form: pItem },
      });
    },

    selectingBedInfo(pValue) {
      const source = pValue && pValue.source;
      this.form.itl_id = source && source.itl_id ? source.itl_id : null;
    },

    selectingCbo(pValue) {
      const source = pValue && pValue.source;
      if (source) {
        this.form.int_id_ocupacoes_cbo = source.ocp_id ? source.ocp_id : null;
        this.form.cboCode = source.ocp_codigo ? source.ocp_codigo : "";
      } else {
        this.form.int_id_ocupacoes_cbo = null;
        this.form.cboCode = "";
      }
    },

    selectingResponsibleEmployee(pValue) {
      const source = pValue && pValue.source;
      const hasSuggestion = source && Object.keys(source).length > 0;

      if (hasSuggestion) {
        const data = source.employeeRelationship;

        this.form.int_id_funcionarios =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      }
    },

    toShowUp(pItemIndex) {
      return ToShowUp(pItemIndex, this.mutableHospitalizationList);
    },

    itemActionOptionsPrinter(pItem) {
      const isIndigent = !pItem.pac_id;

      return [
        {
          label: "Imprimir laudo para solicitação de autorização de internação",
          action: this.$refs.manager.printHospitalizationAuthorizationRequest,
          disable: false,
        },
        {
          label: "Imprimir ficha de internação do paciente",
          action: this.$refs.manager.printRegistrationHospitalizationPatient,
          disable: false,
        },
        {
          label: "Imprimir identificação do paciente",
          action: this.$refs.manager.printPatientIdentification,
          disable: isIndigent,
        },
      ];
    },

    hasPermissonToSendPep(pSectorId) {
      return this.$Permissions.sector.has(
        "internacao.internacaoPEP.utilizarInternacaoPep",
        pSectorId,
      );
    },

    itemActionOptionsPep(pItem) {
      if (pItem.isl_nome !== "RESERVA") {
        const hasPermission = this.hasPermissonToSendPep(pItem.set_id);
        const sentToPep = !(
          hasPermission && !pItem.int_id_enderecamento_pep > 0
        );
        const cancelSentToPep = !(
          hasPermission && pItem.int_id_enderecamento_pep > 0
        );

        return [
          {
            label: "Enviar p/ PEP",
            action: this.$refs.manager.sendHospitalizationToPep,
            disable: sentToPep,
          },
          {
            label: "Cancelar Envio p/ PEP",
            action: this.$refs.manager.cancelSendHospitalizationToPep,
            disable: cancelSentToPep,
          },
        ];
      } else {
        return [];
      }
    },

    itemActionOptions(pItem) {
      const hasExit = !!pItem.isa_id;
      const busyBed = pItem.isl_nome === "OCUPADO";
      const reservationBed = pItem.isl_nome === "RESERVA";
      const deleteLabel = reservationBed
        ? "Excluir reserva"
        : "Excluir internação";

      return [
        {
          label: "Detalhes",
          action: this.$refs.manager.openModalHospitalizationDetails,
          disable: false,
        },
        {
          label: "Transferência e Permuta",
          action: this.$refs.manager.openModalTransferExchange,
          disable: hasExit,
          visible: !reservationBed && !hasExit,
        },
        {
          label: "Cadastro de saída",
          action: this.$refs.manager.openModalHospitalizationExit,
          disable: hasExit || (!hasExit && !busyBed),
          visible: !reservationBed && !hasExit,
        },
        // {
        //   label: 'Histórico de transferência',
        //   action: this.$refs.manager.openModalHistoryTransfer,
        //   disable: false
        // },
        // {
        //   label: 'Histórico de ocupação da internação',
        //   action: this.$refs.manager.openModalOccupationHistory,
        //   disable: false
        // },
        // {
        //   label: 'Imagens do paciente na fila',
        //   action: true,
        //   disable: false
        // },
        {
          label: deleteLabel,
          action: this.$refs.manager.openModalHospitalizationReason,
          disable: hasExit,
          visible: !hasExit,
        },
        {
          label: "Excluir saída",
          action: this.$refs.manager.openModalRemoveHospitalizationExit,
          disable: !hasExit,
          visible: !reservationBed && hasExit,
        },
      ];
    },

    cleanForm() {
      this.periodDate = "Período de Entrada";
      this.mutableHospitalizationList = [];
      this.form.selectedBedType = null;
      if (this.$refs.out) {
        this.$refs.out.clearRadio();
      }
      if (this.$refs.situation) {
        this.$refs.situation.clearRadio();
      }
      if (this.$refs.status) {
        this.$refs.status.clearRadio();
      }
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },

    clearDateValidate() {
      if (this.$refs.entryInitialDateNoWayOut) {
        this.$refs.entryInitialDateNoWayOut.cleanValidate();
      }

      if (this.$refs.entryFinalDateNoWayOut) {
        this.$refs.entryFinalDateNoWayOut.cleanValidate();
      }

      if (this.$refs.entryInitialDateEntryDateType) {
        this.$refs.entryInitialDateEntryDateType.cleanValidate();
      }

      if (this.$refs.entryFinalDateEntryDateType) {
        this.$refs.entryFinalDateEntryDateType.cleanValidate();
      }

      if (this.$refs.outInitialDate) {
        this.$refs.outInitialDate.cleanValidate();
      }
      if (this.$refs.outFinalDate) {
        this.$refs.outFinalDate.cleanValidate();
      }
    },

    isPatient(item) {
      return item && item.pac_id ? item.pac_id : 0;
    },

    indigentSex(item) {
      return item && item.ini_id_sexos ? item.ini_id_sexos : null;
    },

    patientSex(item) {
      return item && item.pes_id_sexos ? item.pes_id_sexos : null;
    },

    verifyInitialDate(pValue, pErrors) {
      const initialDate =
        this.form.outInitialDate || this.form.entryInitialDate;
      const finalDate = this.form.outFinalDate || this.form.entryFinalDate;

      const formattedInitalDate = moment(initialDate, "DD/MM/YYYY");
      const formattedFinalDate = moment(finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = formattedInitalDate.isAfter(
        formattedFinalDate,
      );

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }

      return true;
    },

    oculterData(pValue) {
      return pValue.isl_id === 2 || pValue.isl_id === 3;
    },

    enableDisablePep(pValue) {
      return pValue.isl_nome === "RESERVA" || !!pValue.isa_id;
    },

    enableDisableEdit(pValue) {
      return !!pValue.isa_id;
    },

    afterSearchFilter() {
      if (this.$refs.dropDownHospitalizationOptions?.length > 0) {
        this.$refs.dropDownHospitalizationOptions.forEach((element) => {
          element.clickOutside();
        });
      }
      if (this.$refs.dropDownPepOptions?.length > 0) {
        this.$refs.dropDownPepOptions.forEach((element) => {
          element.clickOutside();
        });
      }

      if (this.$refs.dropDownPrinterOptions?.length > 0) {
        this.$refs.dropDownPrinterOptions.forEach((element) => {
          element.clickOutside();
        });
      }
    },
  },
};
</script>
