<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgSelect
          id="type-destiny"
          v-model="form.statusProduct"
          :value="form.statusProduct"
          :placeholder="`Selecione`"
          :options="listSelectStatusProduct"
          class="modulesItem"
          label="Situação do Produto"
          openOnlyBottom
          @change="selectedStatusProduct"
        />
        <RgRadioCustom
          id="groupResult"
          v-bind="propsRadioCustom"
          class="inputitem"
          label="Agrupar resultados"
          @input="onInputRadioCustom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import GET_GROUP_RESULTS from "$pharmacy/common/combobox/rg-combobox-pharmacy-group-result/GetGroupResults.js";
import { RgSelect, RgRadioCustom } from "~tokio/primitive";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  productTypeId: null,
  productTypeName: null,
  statusProduct: 0,
  statusProductName: null,
  groupResult: 1,
  groupResultItem: null,
  columnsToPrint: [],
};
const PHARMACY_PREFERENCE_TYPE = {
  STOCK: 1,
  STOCK_LOT: 2,
};

export default {
  name: "PharmacySatelliteStockProductReport",
  components: {
    RgSelectProductType,
    RgSelect,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      pharmacyId: null,
      productId: null,
    };
  },

  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
    propsRadioCustom() {
      const list = [
        { title: "Por Produtos", id: 1 },
        { title: "Por Lote", id: 2 },
      ];

      const value = this.form.groupResult;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    listSelectStatusProduct() {
      return [
        { label: "Disponíveis", value: 1 },
        { label: "Esgotados", value: 2 },
        { label: "Limite Máximo Alcançado", value: 3 },
        { label: "Limite Mínimo Alcançado", value: 4 },
      ];
    },
    groupResult() {
      return this.form.groupResultItem
        ? this.form.groupResultItem
        : GET_GROUP_RESULTS.PRODUCT;
    },
  },

  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
    globalFilters: {
      handler(pValue) {
        this.pharmacyId = pValue.pharmacy;
        this.productId = pValue.product;
      },
      deep: true,
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Estoque Inicial", key: "ULTIMO_ESTOQUE", align: "right" },
      { name: "Acréscimo", key: "ACRESCIMO", align: "right" },
      { name: "Decréscimo", key: "DECRESCIMO", align: "right" },
      { name: "Diferença", key: "ESTOQUE", align: "right" },
      { name: "Estoque Final", key: "ESTOQUE_TOTAL", align: "right" },
      { name: "Estoque Atual", key: "ESTOQUE_ATUAL", align: "right" },
      { name: "Estoque Máximo", key: "ESTOQUE_MAXIMO", align: "right" },
      { name: "Estoque Mínimo", key: "ESTOQUE_MINIMO", align: "right" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      // { name: "Valor Em Estoque", key: "VALOR_EM_ESTOQUE", align: "right" },
    ]);
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.groupResultItem = item;
        this.form.groupResult = item.id;
      } else {
        this.form.groupResultItem = null;
        this.form.groupResult = null;
      }
    },
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          unitHealthId: this.globalFilters.unitHealth,
          unitHealthName: this.globalFilters.unitHealthName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          pharmacyId: Number(this.globalFilters.pharmacy),
          pharmacyName: this.globalFilters.pharmacyName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          groupResult: this.groupResult.id,
          groupResultName: this.groupResult.title,
          statusProduct: this.form.statusProduct,
          statusProductName: this.form.statusProductName,
          pharmacyPreferenceType: PHARMACY_PREFERENCE_TYPE.STOCK_LOT,
          initialDate: this.globalFilters.initialDate,
          finalDate:
            this.globalFilters.finalDate || moment().format("DD/MM/YYYY"),
          columnsToPrint: this.columnsToPrint,
          userId: this.userId,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_NEW_STOCK_PRODUCT",
          variables,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectedProductType(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;
      if (hasSuggestion) {
        this.form.productTypeName = pType.label;
      } else {
        this.form.productTypeName = "";
      }
    },
    selectedStatusProduct(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;
      if (hasSuggestion) {
        this.form.statusProductName = pType.label;
      } else {
        this.form.statusProductName = "";
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
