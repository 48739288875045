<style src="./ServicesRatings.scss" lang="scss" scoped></style>

<template>
  <div class="services-ratings">
    <RgSearch
      ref="rgsearch"
      v-model="mutableServiceRatings"
      :disabled="disabledByModal"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      result-title="Importar Serviços e Classificações para Unidade de Saúde"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-empty-message="false"
      :infiniteLimit="true"
      :disabledShorkey="modalDeleteService"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="services-ratings-filter">
        <div class="grid">
          <div class="selectinput">
            <br />
            <RgSelectUnithealth
              id="select-unity"
              v-model="form.uns_id"
              :rules="{ required: true }"
              :class="{ disable: true }"
              disabled
              searchUnitById
              :unitHealthId="form.uns_id"
              permission="faturamento.faturar"
              class="inputitem"
            />
          </div>
          <div class="selectinput">
            <RgSelectUnithealth
              id="select-unity"
              v-model="form.uns_id"
              :rules="{ required: true }"
              addCnesLabel
              disabled
              searchUnitById
              :unitHealthId="form.uns_id"
              :class="{ disable: true }"
              :label="'CNES da Unidade de Saúde'"
              permission="faturamento.faturar"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSelectService
              v-model="form.srv"
              label="Serviço"
              placeholder="Digite o Número ou Nome do Serviço"
            />
          </div>
        </div>
      </div>

      <div class="services-tooltip">
        <Tooltip
          startOpen
          class="tooltip"
          message="Selecione um dos serviços exibidos abaixo no resultado de busca, para exibir as Classificações adicionadas."
        >
          <IconLightBulbHelper slot="icon" hollow />
        </Tooltip>
      </div>

      <div class="services-ratings-search">
        <div class="content-grid">
          <legend
            class="legend"
            :class="{ 'margin-legend': mutableServiceRatings.length > 0 }"
          >
            Serviços
          </legend>
          <div class="table-one">
            <div v-if="mutableServiceRatings.length > 0">
              <SmartTable
                ref="smartTableEmployee"
                name="ServicesRatingsEmployee"
                :columns="COLUMN_TABLE_ONE"
                :body="mutableServiceRatings"
                :total="mutableServiceRatings.length"
                :initial-columns="4"
                :dynamic-height="160"
                :show-pagination="false"
                :disabled="disabledByModal"
                remove-btn-columns
                index-column="co_classificacao"
                toggleSelected
                @getLine="selectServiceData"
              >
              </SmartTable>
            </div>
            <div v-else class="no-results-empty">
              <IconEmpty />
              <span class="message">
                NÃO FORAM ENCONTRADOS RESULTADOS PARA ESSA BUSCA
              </span>
            </div>

            <div slot="bottom-buttons" class="bottom-buttons">
              <RgAddButton
                small
                title="Adicionar Serviços e Classificações"
                :disabled="disabledByModal"
                @click="addNewService"
              />
            </div>
          </div>
          <br />
          <div class="separator" />
          <br />

          <legend
            class="legend"
            :class="{
              'margin-legend': mutableClassifications.length > 0,
            }"
          >
            Classificações do Serviço Selecionado
          </legend>
          <div class="table-two">
            <div v-if="mutableClassifications.length > 0">
              <SmartTable
                ref="smartTableSchedule"
                name="ServicesRatings"
                :columns="COLUMN_TABLE_TWO"
                :body="mutableClassifications"
                :total="Number(mutableClassifications.length)"
                :show-pagination="false"
                :dynamic-height="130"
                toggleSelected
                remove-btn-columns
                :disabled="disabledByModal"
                @getLine="getServiceRow"
              />
            </div>
            <div v-else class="no-results-helper">
              <IconInfoHelper class="icon" :background="'#6f8894'" />
              <span class="message">
                PARA EXIBIR AS CLASSIFICAÇÕES, ADICIONE NOVOS SERVIÇOS PARA A
                UNIDADE DE SAÚDE SELECIONADA
              </span>
            </div>
            <div slot="bottom-buttons" class="bottom-buttons">
              <RgEditButton
                small
                title="Editar Serviço"
                :disabled="classificationData === null || disabledByModal"
                @click="addNewService(true)"
              />
              <RgLessButton
                title="Remover Classificação"
                :disabled="classificationData === null || disabledByModal"
                @click="openModalDelete"
              />
            </div>
          </div>
        </div>
      </div>
    </RgSearch>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalDelete"
    />
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  IconEmpty,
  RgLessButton,
  RgEditButton,
  IconLightBulbHelper,
  IconInfoHelper,
  Tooltip,
  ModalConfirmDeletion,
  RgAddButton,
} from "~tokio/primitive";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectService from "$billing/submodules/bpa/services-ratings/components/select/rg-select-service/RgSelectService";

export default {
  name: "ServicesRatings",
  components: {
    RgSearch,
    RgSelectUnithealth,
    SmartTable,
    IconEmpty,
    Tooltip,
    IconLightBulbHelper,
    IconInfoHelper,
    RgLessButton,
    RgSelectService,
    RgEditButton,
    ModalConfirmDeletion,
    RgAddButton,
  },
  data() {
    return {
      mutableServiceRatings: [],
      classificationData: null,
      mutableClassifications: {
        data: [],
        total: 0,
      },
      form: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        srv: null,
      },
      totalAgenda: 0,
      modalDeleteService: false,
      selectedService: null,
    };
  },

  computed: {
    disabledByModal() {
      return this.modalDeleteService;
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteService;
      const confirm = () => this.removeClassification();
      const title = `Excluir Classificação do Serviço`;
      const message = `A categoria selecionada será removida da lista de Classificações adicionadas para o Serviço.`;
      const msgSuccess = "Classificação removida com sucesso";
      const noReason = true;

      const bodyText = `${this.classificationData?.no_servico} - ${this.classificationData?.no_classificacao}`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
  },

  watch: {},

  mounted() {},

  beforeCreate() {
    this.COLUMN_TABLE_ONE = [
      { name: "Código", key: "co_servico", align: "right" },
      { name: "Serviço", key: "no_servico", align: "left" },
      { name: "CNES da Unidade", key: "uns_cnes", align: "right" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
    ];

    this.COLUMN_TABLE_TWO = [
      { name: "Código", key: "co_classificacao", align: "right" },
      { name: "Classificação", key: "no_classificacao", align: "left" },
      { name: "CNES da Unidade", key: "uns_cnes", align: "right" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
    ];
  },

  created() {},

  destroyed() {},
  methods: {
    closeModalDelete() {
      this.modalDeleteService = false;
    },
    openModalDelete() {
      this.modalDeleteService = true;
    },
    addNewService(isEdit = false) {
      this.$router.push({
        name: "billing.bpa.registerServicesRatings",
        params: {
          srv: this.form.srv || this.selectedService,
          uns_id: this.form.uns_id,
          isEdit: isEdit,
        },
      });
    },

    async removeClassification() {
      try {
        this.$loader.start();

        const classificationCode = [];
        this.mutableClassifications.forEach((item) => {
          let insert = true;
          if (
            item.co_classificacao === this.classificationData.co_classificacao
          ) {
            insert = false;
          }
          if (insert) {
            classificationCode.push({
              fcu_co_classificacao: item.co_classificacao,
            });
          }
        });

        const variables = {
          arrClassificacao: classificationCode,
          fcu_co_classificacao: 0,
          fcu_co_servico: this.selectedService,
          fcu_id: 0,
          fcu_id_unidades_saude: this.form.uns_id,
        };
        await this.$store.dispatch(
          "Billing/ServicesRatings/REGISTER_CLASSIFICATION",
          variables,
        );
        await this.selectServiceData(
          { co_servico: this.selectedService },
          true,
        );
        this.classificationData = [];

        if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
        this.$toaster.success("Classificação removida com sucesso");
      } catch (e) {
        this.$toaster.error("Não foi possível remover a classificação");
      } finally {
        this.$loader.finish();
      }
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    async selectServiceData(pValue, pIndex, reSearch = false) {
      if (pValue) {
        if (!reSearch) this.$loader.start();
        const variables = {
          limiteFim: 18,
          limiteInicio: 0,
          srv: Number(pValue.co_servico),
          uns_id: Number(this.$store.getters["Login/GET_UNIT_HEALTH_ID"]),
        };
        this.selectedService = pValue.co_servico;

        const data = await this.$store.dispatch(
          "Billing/ServicesRatings/GET_CLASSIFICATIONS_BY_SERVICE",
          variables,
        );
        this.mutableClassifications = data.data;
        if (this.$refs.smartTableSchedule) {
          this.$refs.smartTableSchedule.cleanSelectRow();
        }
        if (!reSearch) this.$loader.finish();
      } else {
        this.selectedService = null;
        this.mutableClassifications = [];
        this.$refs.smartTableSchedule.cleanSelectRow();
      }
    },

    getServiceRow(pValue) {
      if (pValue) {
        this.classificationData = pValue;
      } else {
        this.classificationData = null;
      }
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          uns_id: this.form.uns_id,
          srv: Number(this.form.srv),
          limiteFim: 1000000,
          limiteInicio: 0,
        },
      };

      return variables;
    },

    async searchFilter(pData) {
      this.$loader.start();
      const data = await this.$store.dispatch(
        "Billing/ServicesRatings/SEARCH_CLASSIFICATIONS",
        pData.arrFiltro,
      );
      if (!this.form.uns_id) {
        data.data.forEach((item) => {
          item.uns_nome = null;
          item.uns_cnes = null;
        });
      }
      this.$loader.finish();
      this.mutableServiceRatings = data.data;
      return data;
    },

    afterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableSchedule) {
        this.$refs.smartTableSchedule.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableClassifications = {
        data: [],
        total: 0,
      };

      if (submitFromButton && this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.setScrollTopZero();
      }
    },

    cleanForm() {
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      this.form.srv = null;
      this.classificationData = null;
      this.mutableClassifications = {
        data: [],
        total: 0,
      };
      this.mutableServiceRatings = [];
    },
  },
};
</script>
