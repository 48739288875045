<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListPermitedUnithealth from "./action/ListPermitedUnithealth";
import ListUnithealth from "./action/ListUnithealth";
import ListUnithealthWithCity from "./action/ListUnithealthWithCity";

export default {
  name: "RgSelectUnithealth",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    permission: {
      type: String,
      default: "",
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    addCnesLabel: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: "",
    },
    userId: {
      type: Number,
      default: 0,
    },
    searchUnitById: {
      type: Boolean,
      default: false,
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    filterAll: Boolean,
    withCity: Boolean,
  },
  data() {
    return {
      units: [],
    };
  },
  watch: {
    units: function (val) {
      this.updateOptions();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.units;
    },
    async doSearch() {
      let data;

      console.log("searchUnitById", this.searchUnitById);
      console.log("unitHealthId", this.unitHealthId);

      if (this.searchUnitById && this.unitHealthId > 0) {
        data = await ListUnithealth({ uns_id: this.unitHealthId });
      } else if (this.filterAll) {
        data = await ListUnithealth();
      } else if (this.withCity) {
        data = await ListUnithealthWithCity();
      } else {
        data = await ListPermitedUnithealth(this.permission);
      }

      this.units = data.map((item) => {
        return {
          value: item.uns_id,
          label: this.addCnesLabel ? item.uns_cnes : item.uns_nome,
          uns_id: item.uns_id,
          ...item,
        };
      });
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
