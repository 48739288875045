<template>
  <div v-show="show" class="modal-classification-procedure">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="modal-classification-procedure-header">
        <div class="title">{{ title }}</div>
      </section>

      <section
        v-if="show"
        slot="body"
        class="modal-classification-procedure-body"
      >
        <RgValidatorForm ref="validator">
          <FormBase title="Dados do Atendimento">
            <section class="grid-procedures-informations">
              <div class="service">
                <span> Atendimento: </span>
                <span>
                  {{
                    formatDateHour(
                      modalData.attendanceDate,
                      modalData.attendanceHour,
                    )
                  }}
                </span>
              </div>
              <div class="patient">
                <span> Paciente: </span>
                <span>
                  {{ getValue(modalData, "pes_nome") }}
                </span>
              </div>
              <div class="employee">
                <span> Nome do Profissional: </span>
                <span>
                  {{ getValue(modalData, "nomeFuncionario") }}
                </span>
              </div>
              <div class="cns">
                <span> CNS do Paciente: </span>
                <span>
                  {{ getValue(modalData, "crs_numero") }}
                </span>
              </div>
              <div class="occupation">
                <span> Ocupação: </span>
                <span> {{ getValue(modalData, "ocp_nome") }} </span>
              </div>
            </section>

            <hr class="separator" />

            <section class="grid-procedures-form">
              <div class="row-1">
                <RgSelectCid10
                  ref="cid10"
                  v-model="form.cid10"
                  class="cid10"
                  default-text="000000"
                  :rules="{ required: isCidRequired }"
                  :procedureCode="procedureCode"
                  :disabled="disabledCid10"
                  :class="{ disable: disabledCid10 }"
                  @required="requiredCid"
                  @disable="disableCid10"
                />
                <RgSelectService
                  ref="service"
                  v-model="form.service"
                  :rules="{ required: isServiceRequired }"
                  :unitHealthId="unitHealthId ? Number(unitHealthId) : null"
                  :procedureCode="procedureCode ? String(procedureCode) : null"
                  :disabled="disabledService"
                  :class="{ disable: disabledService }"
                  @required="requiredService"
                  @disable="disableService"
                />
              </div>

              <div class="row-2">
                <RgSelectClassification
                  ref="classification"
                  v-model="form.classification"
                  :rules="{ required: isClassificationRequired }"
                  :unitHealthId="unitHealthId ? Number(unitHealthId) : null"
                  :serviceCode="form.service ? form.service : null"
                  :procedureCode="procedureCode ? procedureCode : null"
                  :disabled="!form.service || disabledClassification"
                  :class="{ disable: !form.service || disabledClassification }"
                  openOnlyTop
                  @required="requiredClassification"
                  @disable="disableClassification"
                />

                <RgInput
                  v-model="form.amount"
                  label="Quantidade"
                  placeholder="0000"
                  :maxlength="4"
                  class="quantity"
                  :rules="{ required: true, fn: validateAmount }"
                />

                <RgCleanButton class="clean-button" @click="clearForm" />
              </div>
            </section>
          </FormBase>
        </RgValidatorForm>
      </section>

      <section slot="footer" class="modal-classification-procedure-footer">
        <RgCancelButton
          id="cancel-btn"
          label="Cancelar"
          medium
          @click="close"
        />
        <MediumButton
          id="save-btn"
          label="Salvar"
          medium
          :disabled="disableSaveButton"
          @click="save"
        />
      </section>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgValidatorForm,
  RgBaseModal,
  MediumButton,
  RgCleanButton,
  RgCancelButton,
  RgInput,
} from "~tokio/primitive";

import { FormBase } from "~tokio/foundation";

import RgSelectCid10 from "$billing/submodules/bpa/pep-pending-procedures/components/select/rg-select-cid10/RgSelectCid10";
import RgSelectService from "$billing/common/components/select/rg-select-service/RgSelectService";
import RgSelectClassification from "$billing/common/components/select/rg-select-classification/RgSelectClassification";

export default {
  name: "ModalResolvePendingPEPProcedures",
  components: {
    RgBaseModal,
    FormBase,
    RgValidatorForm,
    RgInput,
    RgSelectCid10,
    RgSelectService,
    RgSelectClassification,
    MediumButton,
    RgCleanButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    serviceInfo: {
      type: Object,
      default: () => {},
    },
    procedureInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        cid10: null,
        service: null,
        classification: null,
        amount: 1,
      },
      modalData: {},
      attendanceDate: null,
      attendanceHour: null,
      disableSaveButton: false,
      isCidRequired: false,
      isServiceRequired: false,
      isClassificationRequired: false,
      disabledCid10: true,
      disabledService: true,
      disabledClassification: true,
    };
  },
  computed: {
    title() {
      return this.isEditing
        ? "Editar o Procedimento do Atendimento"
        : "Classificar o Procedimento do Atendimento";
    },
    unitHealthId() {
      return this.serviceInfo && this.serviceInfo.uns_id
        ? this.serviceInfo.uns_id
        : null;
    },
    procedureCode() {
      return this.procedureInfo && this.procedureInfo.sus_pa_id_dv
        ? this.procedureInfo.sus_pa_id_dv
        : null;
    },
    hasService() {
      return this.form.service && this.form.service > 0;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        const attendanceDate =
          this.serviceInfo.hag_data_consulta ||
          this.serviceInfo.dataHoraAtendimento;
        const attendanceHour = this.serviceInfo.con_hora || null;

        this.modalData = {
          ...this.serviceInfo,
          ...this.procedureInfo,
          attendanceDate,
          attendanceHour,
        };

        if (this.isEditing) {
          this.loadData();
        }
      }
    },
  },
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async save() {
      if (this.isServiceRequired && !this.form.service) {
        this.$toaster.info(
          'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
          "O Serviço não foi importado para a Unidade",
        );
        return;
      }

      if (this.isClassificationRequired && !this.form.classification) {
        this.$toaster.info(
          'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
          "A Classificação não foi importada para a Unidade",
        );
        return;
      }

      const validateForm = await this.isFormValid();

      if (!validateForm) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      if (this.isEditing) {
        await this.saveProcedureEditing();
      } else {
        this.saveProcedure();
      }
    },
    async saveProcedureEditing() {
      try {
        this.$loader.start();
        this.disableSaveButton = true;

        const variables = {
          arrFormData: {
            pes_id: this.serviceInfo.idPessoa,
            fun_id: this.serviceInfo.fun_id,
            vin_id: this.serviceInfo.vin_id || null,
            ppd_id_consultas: this.serviceInfo.con_id,
            ppd_id_funcionarios: this.serviceInfo.fun_id,
            ppd_id: this.procedureInfo.ppd_id,
            ppd_id_ocupacoes_cbo: this.procedureInfo.tum_id_ocupacoes_cbo,
            ppd_codigo_procedimento: this.procedureInfo.sus_pa_id_dv,
            ppd_cid10: this.form.cid10 || null,
            ppd_servico: this.form.service || null,
            ppd_classificacao: this.form.classification || null,
            ppd_quantidade: this.form.amount || 1,
          },
        };

        await this.$store.dispatch(
          "Billing/Attendance/EDIT_PROCEDURE",
          variables,
        );

        this.$emit("reSearch");
        this.$toaster.success("Procedimento editado com sucesso");
      } catch (pError) {
        const error = pError.toString();
        const formatError = error.replace("Error: ", "");

        switch (formatError) {
          case "CID10 obrigatório":
            this.$toaster.info(
              `“Cid10” é um campo obrigatório para adicionar o procedimento.`,
              "O Procedimento precisa ser Classificado",
            );
            break;
          case "Sexo não permitido para o procedimento":
            this.$toaster.info(
              `No campo “Sexo” o gênero escolhido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com o sexo",
            );
            break;
          case "Idade não permitida para o procedimento":
            this.$toaster.info(
              `No campo “Idade” o valor inserido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com a idade",
            );
            break;
          case "Procedimento não importado para o profissional":
            this.$toaster.info(
              `Verifique os campos “Ocupação” e “Nome do Profissonal”.`,
              "O Procedimento não é permitido para o profissonal",
            );
            break;
          case "Procedimento já adicionado":
            this.$toaster.info(
              `Confira a relação de procedimentos importados.`,
              "O Atendimento já possui esse procedimento",
            );
            break;
          case "Profissional sem CNS":
            this.$toaster.info(
              `Insira um número válido no campo “CNS” para adicionar um procedimento.`,
              "O Profissional deve ter vínculo com um CNS",
            );
            break;
          case "Procedimento não pode ser faturado na competência atual":
            this.$toaster.info(
              `Fature o procedimento na próxima competência válida.`,
              "O Procedimento não pode ser faturado",
            );
            break;
          case "Procedimento exige serviço e classificação":
            this.$toaster.info(
              `Insira informações válidas nos campos “Serviço” e “Classificação”.`,
              "O Procedimento precisa ser classificado",
            );
            break;
          case "Procedimento incompatível com a quantidade informada":
            this.$toaster.info(
              `Insira no campo “Quantidade” um valor compatível com o procedimento.`,
              "O Procedimento é incompatível com a quantidade",
            );
            break;
          default:
            this.$toaster.error(error);
            break;
        }
      } finally {
        this.close();
        this.$loader.finish();
      }
    },
    async saveProcedure() {
      this.$emit("save", true, this.form);
      this.close();
    },
    loadData() {
      this.$loader.start();
      this.form.cid10 = this.procedureInfo.ppd_cid10;
      this.form.service = this.procedureInfo.ppd_servico;
      this.form.classification = this.procedureInfo.ppd_classificacao;
      this.form.amount = this.procedureInfo.ppd_quantidade;
      this.$loader.finish();
    },
    formatDateHour(pDate, pHour) {
      const isValidFormat = this.$utils.date.IsValid(pDate, "DD/MM/YYYY", true);

      const date = pDate
        ? isValidFormat
          ? pDate
          : this.$utils.date.BrazilianDateFormat(pDate)
        : "Não informado";

      const hour = pHour;

      return date && hour ? date + " - " + hour : date;
    },
    getValue(pItem, pKey) {
      if (
        this.serviceInfo &&
        Object.keys(this.serviceInfo).length > 0 &&
        this.procedureInfo &&
        Object.keys(this.procedureInfo).length > 0
      ) {
        return pItem[pKey] || "Não informado";
      }
    },
    validateAmount(pValue, pErrors) {
      const amount = Number(pValue);
      if (amount < 1) {
        pErrors.push("A quantidade deve ser maior que zero");
        return false;
      }
      return true;
    },
    requiredCid(pRequired) {
      if (pRequired) {
        this.isCidRequired = true;
      } else {
        this.isCidRequired = false;
      }
    },
    requiredService(pRequired) {
      if (pRequired) {
        this.isServiceRequired = true;
      } else {
        this.isServiceRequired = false;
      }
    },
    requiredClassification(pRequired) {
      if (pRequired) {
        this.isClassificationRequired = true;
      } else {
        this.isClassificationRequired = false;
      }
    },
    disableService(pDisabled) {
      if (pDisabled) {
        this.disabledService = true;
      } else {
        this.disabledService = false;
      }
    },
    disableClassification(pDisabled) {
      if (pDisabled) {
        this.disabledClassification = true;
      } else {
        this.disabledClassification = false;
      }
    },
    disableCid10(pValue) {
      if (pValue) {
        this.disabledCid10 = false;
      } else {
        this.disabledCid10 = true;
      }
    },
    clearForm() {
      this.form = {
        cid10: null,
        service: null,
        classification: null,
        amount: 1,
      };
      this.disableSaveButton = false;
    },
    close() {
      if (this.$refs.cid10) this.$refs.cid10.cleanValidate();
      if (this.$refs.service) this.$refs.service.cleanValidate();
      if (this.$refs.classification) this.$refs.classification.cleanValidate();

      this.isServiceRequired = false;
      this.isClassificationRequired = false;
      this.disabledCid10 = true;
      this.disabledService = true;
      this.disabledClassification = true;
      this.modalData = {};

      this.clearForm();
      this.$emit("close");
    },
  },
};
</script>
