<template>
  <div class="appointment-effectiveness">
    <RgSearch
      ref="rgsearch"
      v-model="mutableappointmentPerDay"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-empty-message="false"
      :showTitle="false"
      :infiniteLimit="true"
      :disabledShorkey="modalConfirmSchedulingDeletion"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="appointment-effectiveness-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="select-unity"
              v-model="form.selectUnity"
              :rules="{ required: true }"
              permission="consulta.agendamento"
              searchUnitById
              :unitHealthId="form.selectUnity"
              class="inputitem"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgSelectSector
              id="selected-sector"
              v-model="form.selectedSector"
              :rules="{ required: true }"
              :disabled="!hideSector"
              :unit-health="form.selectUnity"
              :permission="PERMISSION_APPOINTMENT_SCHEDULE_PER_DAY_SHOWS"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInputDate
              id="date"
              ref="date"
              v-model="form.date"
              :rules="{ required: true }"
              data-id="data"
              pop-up="auto-start"
              label="Data"
              class="inputitem"
            />
          </div>
        </div>
      </div>

      <div class="appointment-effectiveness-search">
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableEmployee"
              name="AppointmentEffectivenessProfessional"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableappointmentPerDay"
              :total="Number(totalAgenda)"
              :mult-select="false"
              :initial-columns="7"
              :show-pagination="false"
              :colorLine="actionColorLine"
              index-column="eag_id"
              @getLine="selectEmployeeData"
            >
              <div
                v-if="mutableappointmentPerDay.length > 0"
                slot="legends"
                class="legends"
              >
                <div class="circle --grey" />
                <span class="legend">Sem vagas disponíveis</span>
                <div class="circle --red" />
                <span class="legend">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button"></div>

          <div class="separator" />

          <legend class="legend">Consultas</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableSchedule"
              name="AppointmentEffectivenessAppointment"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableAppointmentSchedule.data"
              :total="Number(mutableAppointmentSchedule.total)"
              :show-pagination="false"
              :mult-select="true"
              :has-check="true"
              @getMultLines="getAppointmentsRows"
            />
          </div>

          <div class="painel-button">
            <SmallButton
              id="effectuate"
              title="Efetivar"
              class="buttons-top"
              :backgroundColor="'#1e88a9'"
              :disabled="!appointmentsSelected || disableEffectiveness"
              @click="effectuate"
            >
              <IconEffectiveSchedule
                slot="icon"
                :disabled="!appointmentsSelected || disableEffectiveness"
              />
            </SmallButton>

            <SmallButton
              id="not-effectuate"
              title="Não efetivar"
              class="buttons-top"
              :backgroundColor="'#f96b70'"
              :disabled="!appointmentsSelected || disableNotEffectiveness"
              @click="notEffectuate"
            >
              <IconNotEffectiveSchedule
                slot="icon"
                :disabled="!appointmentsSelected || disableNotEffectiveness"
              />
            </SmallButton>

            <SmallButton
              id="effectuate-open"
              title="Manter em aberto"
              class="buttons-top"
              :backgroundColor="'#fb7e36'"
              :disabled="!appointmentsSelected || disableOpened"
              @click="effectuateOpen"
            >
              <IconOpenSchedule
                slot="icon"
                :disabled="!appointmentsSelected || disableOpened"
              />
            </SmallButton>
          </div>
        </div>
      </div>
    </RgSearch>

    <RgConfirmModal
      id="modal-confirm"
      :show="showConfirmModal"
      title="Atenção"
      :message="msgModalConfirm"
      @close="handleModal(0)"
      @confirmed="handleModal(1)"
      @denied="handleModal(2)"
    />

    <ModalEffectivenessNotDone
      id="modal-effecriveness-not-done"
      ref="modalEffectivenessNotDone"
      :show="showModalEffectivenessNotDone"
      :effectiveness-not-done="effectivenessNotDones"
      @close="actionCloseModalEffectivenessNotDone"
    />

    <ModalReasonsNotEffective
      id="reasons-not-effective"
      ref="modalReasonsNotEffective"
      :show="showModalReasonsNotEffective"
      @close="actionCloseModalReasonsNotEffective"
      @saveReasoNotEffective="afterActionSaveReasonNotEffective"
    />
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgInputDate,
  RgConfirmModal,
  IconOpenSchedule,
  IconNotEffectiveSchedule,
  IconEffectiveSchedule,
  SmallButton,
} from "~tokio/primitive";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import { AlertError } from "~tokio/primitive/notification";
import moment from "moment";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import ModalEffectivenessNotDone from "$appointment/submodules/schedule/component/modal/modal-effectiveness-not-done/ModalEffectivenessNotDone";
import ModalReasonsNotEffective from "$appointment/submodules/schedule/component/modal/modal-reasons-not-effective/ModalReasonsNotEffective";

export default {
  name: "AppointmentEffectiveness",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealth,
    RgSelectSector,
    RgInputDate,
    RgConfirmModal,
    ModalEffectivenessNotDone,
    ModalReasonsNotEffective,
    IconOpenSchedule,
    IconNotEffectiveSchedule,
    IconEffectiveSchedule,
    SmallButton,
  },
  data() {
    return {
      mutableappointmentPerDay: [],
      employeeData: null,
      appointmentsData: null,
      mutableAppointmentSchedule: {
        data: [],
        total: 0,
      },
      form: {
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        date: moment().format("DD/MM/YYYY"),
      },
      totalAgenda: 0,
      filId: null,
      modalConfirmSchedulingDeletion: false,
      payloadAppointmentSchedule: {},
      payloadEffectiveness: {},
      showConfirmModal: false,
      msgModalConfirm: null,
      typeActionConfirmModal: null,
      effectiveness: {
        OPENED: 1,
        EFFECTIVE: 2,
        NOT_EFFECTIVE: 3,
      },
      effectivenessNotDones: [],
      showModalEffectivenessNotDone: false,
      showModalReasonsNotEffective: false,
    };
  },

  computed: {
    actionColorLine() {
      return {
        get: (item) => {
          const isEnabledEmployee =
            parseInt(item.funcionario_inabilitado) === 1;

          const unattended = parseInt(item.hag_atendimento) === 0;

          const withoutUnits = parseInt(item.eag_quantidade_restantes) === 0;

          if (isEnabledEmployee || unattended) {
            return GetColorRowSmartTable("red");
          }

          if (withoutUnits) {
            return GetColorRowSmartTable("grey");
          }
        },
      };
    },
    hideSector() {
      return this.form.selectUnity > 0;
    },
    appointmentsSelected() {
      return this.appointmentsData?.length > 0;
    },
    selectedEmployee() {
      return this.$store.getters[
        "Appointment/Schedule/GET_SELECTED_EFFECTIVENESS_EMPLOYEE"
      ];
    },
    hasPermissionEffectiveness() {
      return !!this.$Permissions.sector.has(
        "consulta.efetivacaoConsultas.efetivar",
        this.employeeData.set_id,
      );
    },
    hasPermissionEffectivenessTechnicalScale() {
      return !!this.$Permissions.sector.has(
        "consulta.efetivacaoConsultas.efetivarConsultasReservaTecnica",
        this.employeeData.set_id,
      );
    },
    disableEffectiveness() {
      return this.disableEffectivenessButton();
    },
    disableNotEffectiveness() {
      return this.disableNotEffectivenessButton();
    },
    disableOpened() {
      return this.disableOpenedButton();
    },
  },

  mounted() {
    this.fillFilter();
  },

  beforeCreate() {
    this.COLUMN_TABLE_ONE = [
      { name: "Profissional", key: "pes_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Início", key: "eag_inicio_atendimentos" },
      { name: "Qtd. de Horários", key: "eag_quantidade_consultas" },
      { name: "Qtd. Ocupada", key: "eag_quantidade_marcadas" },
      { name: "Qtd. Livre", key: "eag_quantidade_restantes" },
      { name: "Tipo de Escala", key: "type" },
    ];

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "con_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Situação", key: "efc_nome" },
    ];
  },

  created() {
    this.PERMISSION_APPOINTMENT_SCHEDULE_PER_DAY_SHOWS = 20;

    this.STATUS = {
      OPENED: 1,
      EFFECTIVE: 2,
      NOT_EFFECTIVE: 3,
    };
  },

  destroyed() {
    const existThisRouteScheduleEffectiveness = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/effectiveness",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteScheduleEffectiveness) {
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_EFFECTIVENESS",
      );

      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
    }
  },
  methods: {
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Appointment/Schedule/GET_FILTER_APPOINTMENT_EFFECTIVENESS"
        ];

        if (existFilterData) {
          this.form.date = existFilterData.date;
          this.form.selectUnity = existFilterData.selectUnity;
          this.form.selectedSector = existFilterData.selectedSector;
          await this.$refs.rgsearch.performSearch();
          if (this.selectedEmployee) {
            this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
            this.selectEmployeeData(this.selectedEmployee, true);
          }
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      } finally {
        this.$loader.finish();
      }
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    selectEmployeeData(pValue, reSearch = false) {
      if (!reSearch) {
        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_EFFECTIVENESS_DATA",
          pValue,
        );
        this.employeeData = pValue;
      } else {
        const index = this.mutableappointmentPerDay.findIndex((row) => {
          return row.eag_id === pValue.eag_id;
        });

        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_EFFECTIVENESS_DATA",
          this.mutableappointmentPerDay[index],
        );

        this.employeeData = this.mutableappointmentPerDay[index];
      }

      this.payloadAppointmentSchedule = this.generateFilterAppointmentSchedule(
        this.employeeData.hag_id,
      );

      this.searchFilterAppointmentSchedule();
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_APPOINTMENT_EFFECTIVENESS_DATA",
      );
      this.appointmentsData = null;
      this.filId = null;
      this.$refs.smartTableSchedule.cleanMultSelectedRow();
    },

    getAppointmentsRows(pValue) {
      this.$store.commit(
        "Appointment/Schedule/SELECT_APPOINTMENT_EFFECTIVENESS_DATA",
        pValue,
      );

      this.appointmentsData = pValue;
      this.filId = Number(pValue.fil_id) || null;
    },

    generateFilterAppointmentSchedule(idHistorySchedule) {
      const variables = {
        arrFiltro: {
          intIdAgendamento: 0,
          intIdHistoricoAgendamento: Number(idHistorySchedule),
          intIdUnidade: this.form.selectUnity,
          strDataConsulta: this.form.date,
          limiteFim: 1000000,
          limiteInicio: 0,
        },
      };
      return variables;
    },
    async searchFilterAppointmentSchedule() {
      try {
        this.mutableAppointmentSchedule = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadAppointmentSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      }
    },
    generateFilter() {
      const variables = {
        arrFiltro: {
          blnAtendComConsultasMarcadas: false,
          blnHagAtendimento: false,
          intIdSetor: this.form.selectedSector,
          intIdUnidadeSaude: this.form.selectUnity,
          limiteFim: 1000000,
          limiteInicio: 0,
          strData: this.form.date,
        },
      };

      this.$store.commit(
        "Appointment/Schedule/SET_FILTER_APPOINTMENT_EFFECTIVENESS",
        this.form,
      );

      return variables;
    },
    searchFilter(pData) {
      this.$loader.start();

      const data = this.$store.dispatch(
        "Appointment/Schedule/SEARCH_EMPLOYEE_AGENDA",
        pData,
      );

      this.$loader.finish();
      return data;
    },
    afterSearchFilter(submitFromButton) {
      this.$refs.smartTableSchedule.cleanMultSelectedRow();
      this.$refs.smartTableEmployee.cleanSelectRow();

      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };

      this.employeeData = null;

      if (submitFromButton) {
        this.$refs.smartTableEmployee.setScrollTopZero();
      }
    },

    cleanForm() {
      this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.selectedSector = null;
      this.form.date = moment().format("DD/MM/YYYY");

      this.employeeData = null;
      this.filId = null;
      this.appointmentsData = null;

      this.mutableAppointmentSchedule.data = [];
      this.mutableAppointmentSchedule.total = 0;
      this.mutableappointmentPerDay = [];
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_APPOINTMENT_EFFECTIVENESS_DATA",
      );
      this.$refs.smartTableSchedule.cleanSelectRow();
      this.$refs.smartTableEmployee.cleanSelectRow();
      this.$refs.smartTableSchedule.unselectAllList();
      this.$refs.smartTableEmployee.unselectAllList();
    },

    clearSelectionSchedule() {
      this.appointmentsData = null;
      this.$refs.smartTableSchedule.cleanMultSelectedRow();
    },

    async effectuate() {
      try {
        this.$loader.start();

        const hasStandardProcedures = await this.$store.dispatch(
          "Appointment/Schedule/CHECK_PROFESSIONAL_HAS_STANDARD_PROCEDURES",
          { hag_id: this.employeeData.hag_id },
        );

        this.payloadEffectiveness = {
          arrIdConsultas: this.appointmentsData.map((ele) => ele.con_id),
          intIdEscalaAgendamento: Number(this.employeeData.eag_id),
          intIdTipoEfetivacao: this.STATUS.EFFECTIVE,
          blnPermissaoEfetivacao: this.hasPermissionEffectiveness,
          blnPermissaoEfetivacaoReservaTecnica: this
            .hasPermissionEffectivenessTechnicalScale,
          intIdMotivoNaoEfetivacao: null,
        };

        const isHasStandardProcedures =
          hasStandardProcedures?.dados && hasStandardProcedures.dados > 0;

        if (isHasStandardProcedures) {
          this.$loader.finish(0);
          this.typeActionConfirmModal = this.STATUS.EFFECTIVE;
          this.showConfirmModal = true;
          this.msgModalConfirm =
            "Esse profissional possui procedimentos padrão, <br> ao confirmar esses procedimentos serão faturados automaticamente.<br><br> Deseja continuar?";
        } else {
          await this.editEffectuate();
        }
      } catch (pError) {
        this.$toaster.warning(pError, "Erro ao efetivar consultas");
      }
    },

    notEffectuate() {
      this.showModalReasonsNotEffective = true;
    },

    effectuateOpen() {
      try {
        this.payloadEffectiveness = {
          arrIdConsultas: this.appointmentsData.map((ele) => ele.con_id),
          intIdEscalaAgendamento: Number(this.employeeData.eag_id),
          intIdTipoEfetivacao: this.STATUS.OPENED,
          blnPermissaoEfetivacao: this.hasPermissionEffectiveness,
          blnPermissaoEfetivacaoReservaTecnica: this
            .hasPermissionEffectivenessTechnicalScale,
          intIdMotivoNaoEfetivacao: null,
        };

        this.editEffectuate();
      } catch (pError) {
        this.$toaster.warning(pError, "Erro ao efetivar consultas");
      }
    },

    async handleModal(option) {
      const isOptionCloseOrDenied = option === 0 || option === 2;
      const isOptionConfirmed = option === 1;

      if (isOptionCloseOrDenied) {
        this.showConfirmModal = false;
      }

      if (isOptionConfirmed) {
        const isEffective =
          this.typeActionConfirmModal === this.STATUS.EFFECTIVE;

        if (isEffective) {
          await this.editEffectuate();
        } else {
          this.showConfirmModal = false;
          this.showModalEffectivenessNotDone = true;
        }
      }
    },
    async editEffectuate() {
      try {
        const validateDate = this.validateFutureDate();

        if (validateDate) {
          AlertError(
            "Não é possível alterar o status de efetivação de uma consulta futura.",
          );
          return false;
        }

        this.showConfirmModal = false;

        this.$loader.start();

        const response = await this.$store.dispatch(
          "Appointment/Schedule/EDIT_EFFECTIVENESS_APPOINTMENT_NEW_SEARCH",
          this.payloadEffectiveness,
        );

        this.$loader.finish(0);

        const isDataReturn =
          response.dados?.length > 0 && Array.isArray(response.dados);

        if (!isDataReturn) {
          if (response.dados === "DisplayException") {
            this.$toaster.warning(response.trace, "Atenção");
          } else {
            this.$toaster.success(
              "Consulta(s) alterada(s) com sucesso.",
              "Sucesso",
            );
          }
        } else {
          this.showModalEffectivenessNotDone = true;
          this.effectivenessNotDones = response.dados;
        }

        this.searchFilterAppointmentSchedule();
        this.$store.commit(
          "Appointment/Schedule/UNSELECT_APPOINTMENT_EFFECTIVENESS_DATA",
        );
        this.appointmentsData = null;
        this.$refs.smartTableSchedule.unselectAllList();
      } catch (pError) {
        const error = pError.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao editar situação consulta(s)",
          );
        } else {
          this.$toaster.warning(pError, "Erro ao editar situação consulta(s)");
        }
      } finally {
        this.$loader.finish(0);
      }
    },
    async afterActionSaveReasonNotEffective(pReasonNotEffectiveTypeId) {
      this.payloadEffectiveness = {
        arrIdConsultas: this.appointmentsData.map((ele) => ele.con_id),
        intIdEscalaAgendamento: Number(this.employeeData.eag_id),
        intIdTipoEfetivacao: this.STATUS.NOT_EFFECTIVE,
        blnPermissaoEfetivacao: this.hasPermissionEffectiveness,
        blnPermissaoEfetivacaoReservaTecnica: this
          .hasPermissionEffectivenessTechnicalScale,
        intIdMotivoNaoEfetivacao: pReasonNotEffectiveTypeId,
      };

      await this.editEffectuate();

      this.showModalReasonsNotEffective = false;
    },
    disableEffectivenessButton() {
      if (this.appointmentsData) {
        const validate = this.appointmentsData.some((item) => {
          return item.efc_nome === "EFETIVADA";
        });
        return validate;
      }
    },
    disableNotEffectivenessButton() {
      if (this.appointmentsData) {
        const validate = this.appointmentsData.some((item) => {
          return item.efc_nome === "NÃO EFETIVADA";
        });
        return validate;
      }
    },
    disableOpenedButton() {
      if (this.appointmentsData) {
        const validate = this.appointmentsData.some((item) => {
          return item.efc_nome === "EM ABERTO";
        });
        return validate;
      }
    },

    validateFutureDate() {
      const today = moment(new Date()).format("YYYY-MM-DD");

      return this.appointmentsData.every((item) => {
        const scheduleDate = moment(
          item.hag_data_consulta,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD");

        const verify = moment(scheduleDate).isAfter(today);
        return verify;
      });
    },

    actionCloseModalEffectivenessNotDone() {
      this.showModalEffectivenessNotDone = false;
    },
    actionCloseModalReasonsNotEffective() {
      this.showModalReasonsNotEffective = false;
    },
  },
};
</script>
